import { useCallback } from 'react'
import { useMasterChefContract } from './useContract'
import { useActiveWeb3React } from './useWeb3'
import config from '../config/config.json'

export const usePoolInfo = (poolInfo: number): (() => Promise<any>) => {
  const { chainId } = useActiveWeb3React()
  const networkId = chainId ?? Number(process.env.REACT_APP_CHAIN_ID)

  const masterChefContract = useMasterChefContract(config.contracts[networkId].MasterChef)

  const callback = useCallback(async (): Promise<any> => {
    let result = {}
    try {
      let _poolInfo = {}

      if (masterChefContract) {
        _poolInfo = await masterChefContract.methods.poolInfo(poolInfo).call()
      }
      result = _poolInfo
    } catch (error: any) {
      console.error(error)
    }
    return result
  }, [])

  return callback
}
