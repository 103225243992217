import { useEffect, useState } from 'react'
import { useActiveWeb3React, useMasterChefContract, useHPLPerYear, usePoolInfo } from '../../../hooks'
import config from '../../../config/config.json'
import { fromWei } from '../../../utils'

interface IMyAPYProps {
  poolId: number
  account: any
}

function MyAPY(props: IMyAPYProps): JSX.Element {
  const { poolId, account } = props
  const [apy, setAPY] = useState<any>(0)

  const { chainId } = useActiveWeb3React()
  const networkId = chainId ?? Number(process.env.REACT_APP_CHAIN_ID)
  const pools = config.pools[networkId]
  // @ts-ignore
  const pool = pools.find(p => p.id === poolId)

  const masterChefContract = useMasterChefContract(config.contracts[networkId].MasterChef)
  const hplPerYearCallback = useHPLPerYear(pool.id)
  const poolInfoCallback = usePoolInfo(pool.id)

  useEffect(() => {
    const fetchData = async () => {
      try {
        const _hplPerYear = await hplPerYearCallback()
        const poolInfo = await poolInfoCallback()
        const { totalWeight } = poolInfo
        if (masterChefContract) {
          const userInfoCallback = await masterChefContract.methods.getUserInfo(pool.id, account).call()
          const { stakeWeight, stakeAmount } = userInfoCallback
          const _apy =
            (Number(fromWei(stakeWeight)) * _hplPerYear * 100) /
            (Number(fromWei(totalWeight)) * Number(fromWei(stakeAmount)))
          if (_apy) {
            setAPY(_apy.toFixed(3))
          } else {
            setAPY(0)
          }
        }
      } catch (error: any) {
        console.error(error)
      }
    }

    fetchData()

    const interval = setInterval(() => {
      fetchData()
    }, 1000 * 20)

    return () => clearInterval(interval)
  }, [chainId])

  return <>{apy} %</>
}

export default MyAPY
