import { useEffect, useState } from 'react'
import { Row, Col, Tab, Tooltip, OverlayTrigger } from 'react-bootstrap'
import { useParams } from 'react-router-dom'
import { FiExternalLink, FiInfo } from 'react-icons/fi'
import { Zoom, toast } from 'react-toastify'
import ToastMessage from '../../../components/ToastMessage'
import Button from '../../../components/Button'
import StakeTab from './StakeTab'
import UnstakeTab from './UnstakeTab'
import Value from './Value'
import WithdrawRow from './WithdrawRow'
import config from '../../../config/config.json'
import LockInfo from '../../../types/LockInfo'
import { fromWei, toWei } from '../../../utils'
import {
  useActiveWeb3React,
  useMasterChefContract,
  ApprovalState,
  useApproveCallback,
  useTokenContract,
  usePendingRewards,
} from '../../../hooks'
import {
  StyledStakeRow,
  StakeCol,
  StakeWrapperFlex,
  StyledTabs,
  StakeButtons,
  WithdrawHeading,
  WithdrawAmount,
  WithdrawAction,
  WithdrawDiv,
  Icons,
  Icon,
  StakeTitleText,
  StakeTitle,
} from '../Styled'

function StakeLPCards(): JSX.Element {
  // @ts-ignore
  const { slug } = useParams()

  const [isLoading, setLoading] = useState(false)
  const [isClaiming, setClaiming] = useState(false)
  const [needApprove, setNeedApprove] = useState(true)
  const [pendingRewards, setPendingRewards] = useState<any>({
    hpl: '0',
    hpw: '0',
  })
  const [lockInfo, setLockInfo] = useState<LockInfo[]>([])
  const [userInfo, setUserInfo] = useState({
    stakeAmount: '0',
    deposits: [],
  })

  const { account, chainId } = useActiveWeb3React()
  const networkId = chainId ?? Number(process.env.REACT_APP_CHAIN_ID)
  const pools = config.pools[networkId]
  const pool = pools.find(p => p.slug === slug)

  const masterChefContract = useMasterChefContract(config.contracts[networkId].MasterChef)
  const tokenContract = useTokenContract(pool?.lpAddress)
  const [approval, approveCallback] = useApproveCallback(pool?.lpAddress, config.contracts[networkId].MasterChef)
  const pendingRewardsCallback = usePendingRewards(pool.id)

  const fetchData = async () => {
    if (account) {
      let _userInfo: any = {}
      if (masterChefContract) {
        _userInfo = await masterChefContract.methods.getUserInfo(pool.id, account).call()
      }
      setUserInfo(_userInfo)
    }
  }

  const fetchPendingRewards = async () => {
    try {
      const _pendingRewards = await pendingRewardsCallback()
      const _rewardsHPL = fromWei(_pendingRewards._hpl).toFixed(3)
      const _rewardsHPW = fromWei(_pendingRewards._hpw).toFixed(3)

      setPendingRewards({
        hpl: _rewardsHPL,
        hpw: _rewardsHPW,
      })
    } catch (error: any) {
      console.error(error)
    }
  }

  const fetchLockInfo = async () => {
    try {
      if (account && masterChefContract) {
        const _lockInfoLength = await masterChefContract.methods.getLockInfoLength(account).call()
        const _lockInfo = await masterChefContract.methods.getLockInfo(account).call()
        const lockInfoArray: LockInfo[] = []

        for (let i = 0; i < Number(_lockInfoLength); i++) {
          const currentPool = config.pools[networkId].find(p => p.lpAddress === _lockInfo['tokens'][i].toLowerCase())
          const info = {
            index: i,
            isWithdrawn: _lockInfo['isWithdrawns'][i] as boolean,
            token: _lockInfo['tokens'][i],
            tokenSymbol: currentPool ? currentPool.symbol : '',
            unlockableAt: Number(_lockInfo['unlockableAts'][i]),
            amount: fromWei(_lockInfo['amounts'][i]).toNumber(),
          } as LockInfo

          if (!info.isWithdrawn) {
            lockInfoArray.push(info)
          }
        }

        setLockInfo(lockInfoArray.reverse())
      }
    } catch (error: any) {
      console.error(error)
    }
  }

  useEffect(() => {
    fetchData()
    fetchPendingRewards()
    fetchLockInfo()

    const interval = setInterval(() => {
      fetchPendingRewards()
    }, 1000 * 10)

    return () => clearInterval(interval)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account, chainId])

  const onRefresh = async () => {
    await fetchData()
    await fetchPendingRewards()
    await fetchLockInfo()
  }

  const onApproveLPToken = async () => {
    try {
      setLoading(true)
      const receipt = await approveCallback()

      if (receipt && tokenContract) {
        toast.success(<ToastMessage color="success" bodyText="Now you can stake your tokens" />, {
          toastId: 'onApprove',
          position: 'bottom-right',
          autoClose: 5000,
          hideProgressBar: true,
          transition: Zoom,
        })

        const _allowance = await tokenContract.methods.allowance(account, config.contracts[networkId].MasterChef).call()

        if (toWei(_allowance).gt(toWei(0))) {
          setNeedApprove(false)
        }
      }
    } catch (error: any) {
      // we only care if the error is something _other_ than the user rejected the tx
      if (error?.code !== 4001) {
        toast.error(<ToastMessage color="error" bodyText="Could not approve. Please try again." />, {
          toastId: 'onApprove',
          position: 'bottom-right',
          autoClose: 5000,
          hideProgressBar: true,
          transition: Zoom,
        })
      }
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  const onRevoke = async () => {
    if (tokenContract && account) {
      await tokenContract.methods.approve(config.contracts[networkId].MasterChef, 0).send({ from: account })

      setNeedApprove(true)
    }
  }

  const onClaimRewards = async () => {
    try {
      setClaiming(true)
      let receipt: any = {}

      if (masterChefContract) {
        receipt = await masterChefContract.methods.deposit(pool.id, 0, 1209600).send({ from: account })
      }

      if (receipt) {
        await onRefresh()

        toast.success(
          <ToastMessage
            color="success"
            bodyText="Success"
            link={`${config.explorerURL[networkId]}/tx/${receipt.transactionHash}`}
            linkText="View Transaction"
          />,
          {
            toastId: 'onClaimRewards',
            position: 'bottom-right',
            autoClose: 5000,
            hideProgressBar: true,
            transition: Zoom,
          },
        )
      }
    } catch (error: any) {
      // we only care if the error is something _other_ than the user rejected the tx
      if (error?.code !== 4001) {
        toast.error(<ToastMessage color="error" bodyText="Could not claim rewards. Please try again." />, {
          toastId: 'onClaimRewards',
          position: 'bottom-right',
          autoClose: 5000,
          hideProgressBar: true,
          transition: Zoom,
        })
      }
      console.error(error)
    } finally {
      setClaiming(false)
    }
  }

  return (
    <>
      {pool && (
        <>
          {process.env.NODE_ENV !== 'production' && <Button onClick={onRevoke}>Revoke</Button>}
          <StyledStakeRow className="pt-4 justify-content-center">
            <StakeCol md={6} xl={4}>
              <StakeWrapperFlex>
                <StakeTitle style={{ marginBottom: '20px' }}>
                  <Icons>
                    <Icon src={pool.icon} alt="" style={{ width: 36 }} />
                    {pool.icon2 && <Icon src={pool.icon2} alt="" style={{ width: 28 }} />}
                  </Icons>
                  <StakeTitleText>{pool.name}</StakeTitleText>
                </StakeTitle>
                <StyledTabs defaultActiveKey="stake" transition={false}>
                  <Tab eventKey="stake" title="Stake">
                    <StakeTab
                      poolId={pool.id}
                      icon={pool.icon}
                      icon2={pool.icon2}
                      onRefresh={onRefresh}
                      onApprove={onApproveLPToken}
                      approval={!(needApprove && approval !== ApprovalState.APPROVED)}
                      approveLoading={isLoading}
                    />
                  </Tab>
                  <Tab eventKey="unstake" title="Unstake">
                    <UnstakeTab
                      poolId={pool.id}
                      userInfo={userInfo}
                      onRefresh={onRefresh}
                      onApprove={onApproveLPToken}
                      approval={!(needApprove && approval !== ApprovalState.APPROVED)}
                      icon={pool.icon}
                      icon2={pool.icon2}
                      approveLoading={isLoading}
                    />
                  </Tab>
                </StyledTabs>
              </StakeWrapperFlex>
            </StakeCol>
            <StakeCol md={6} xl={4}>
              <StakeWrapperFlex>
                <StakeTitle style={{ marginBottom: '32px' }}>
                  <StakeTitleText>Rewards</StakeTitleText>
                </StakeTitle>
                <StyledTabs defaultActiveKey="pending" transition={false}>
                  <Tab eventKey="pending" title="Pending Claim">
                    <div className="h-100 d-flex flex-column justify-content-between">
                      <div className="mt-5">
                        {pendingRewards.hpl == 0 && pendingRewards.hpw == 0 && (
                          <div className="pt-5 pb-5">
                            <p style={{ fontSize: '18px', fontWeight: '500' }}>Looks like you have no rewards</p>
                          </div>
                        )}
                        {pendingRewards.hpl > 0 && <Value value={pendingRewards.hpl} tokenType="hpl" />}
                        {pendingRewards.hpw > 0 && (
                          <>
                            <p className="text-center" style={{ marginBottom: '0', fontSize: '24px' }}>
                              +
                            </p>
                            <Value value={pendingRewards.hpw} tokenType="hpw" />
                          </>
                        )}
                        {pendingRewards.hpl > 0 && (
                          <p style={{ marginTop: '15px', fontSize: '18px', fontWeight: '500' }}>Earned</p>
                        )}
                      </div>
                      <StakeButtons>
                        <Button
                          color="secondary"
                          disabled={pendingRewards <= 0 || isClaiming}
                          loading={isClaiming}
                          onClick={onClaimRewards}
                        >
                          Claim Rewards
                        </Button>
                      </StakeButtons>
                    </div>
                  </Tab>
                  <Tab eventKey="requests" title="Withdrawal">
                    <WithdrawHeading>
                      <WithdrawAmount>Amount</WithdrawAmount>
                      <WithdrawAction>
                        <OverlayTrigger
                          placement="left"
                          overlay={<Tooltip id="claim-action">Claim is available when countdown completes</Tooltip>}
                        >
                          <span>
                            <FiInfo />
                          </span>
                        </OverlayTrigger>
                      </WithdrawAction>
                    </WithdrawHeading>
                    {lockInfo.length > 0 ? (
                      <WithdrawDiv>
                        {lockInfo.map((info, index) => {
                          return <WithdrawRow key={index} lockInfo={info} onRefresh={onRefresh} />
                        })}
                      </WithdrawDiv>
                    ) : (
                      <div className="pt-5 pb-5">
                        <p style={{ fontSize: '18px', fontWeight: '500' }}>Looks like you have no rewards</p>
                      </div>
                    )}
                  </Tab>
                </StyledTabs>
              </StakeWrapperFlex>
            </StakeCol>
          </StyledStakeRow>
          {pool.addLiquidityLink !== '' && (
            <Row className="text-center">
              <Col>
                <a href={pool.addLiquidityLink} target="_blank" rel="nofollow">
                  Add Liquidity <FiExternalLink />
                </a>
              </Col>
            </Row>
          )}
        </>
      )}
    </>
  )
}

export default StakeLPCards
