import styled from 'styled-components/macro'
import { Col } from 'react-bootstrap'

export const FaucetCol = styled(Col)`
  background: #110f22;
  padding: 4rem 1.5rem;
  border-radius: 10px;
  border: 1px solid #000;

  @media (min-width: 992px) {
    padding: 3.5rem 2.5rem 6rem;
  }
  h5 {
    margin-bottom: 1.5rem;
    @media (min-width: 992px) {
      margin-bottom: 3rem;
    }
  }
`
export const ButtonWrap = styled.div`
  display: flex;
  flex-direction: column;
  flex-flow: column nowrap;
  align-items: center;
  button {
    margin: 20px 15px;
  }
  @media (min-width: 768px) {
    flex-direction: row;
    flex-flow: row nowrap;
    justify-content: center;
  }
`
export const ConnectWalletCol = styled(Col)`
  padding: 9rem 0;
  text-align: center;
`
