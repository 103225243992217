import { useEffect, useState } from 'react'
import { StakeFooterDotted } from '../Styled'
import { usePoolInfo } from '../../../hooks'
import { formatNumber, isInt } from '../../../utils'

interface IAllocationRateProps {
  poolId: number
  hplPerBlock: number
  totalAllocPoint: number
}

function DailyRewards(props: IAllocationRateProps): JSX.Element {
  const { poolId: id, hplPerBlock, totalAllocPoint } = props

  const [dailyRewards, setDailyRewards] = useState(0)

  const poolInfoCallback = usePoolInfo(id)
  const totalHPWPerDay = (hplPerBlock * 24 * 60 * 60) / 3

  useEffect(() => {
    const fetchData = async () => {
      try {
        const _poolInfo = await poolInfoCallback()

        if (_poolInfo.allocPoint) {
          const _dailyRewards = (_poolInfo.allocPoint * totalHPWPerDay) / totalAllocPoint
          if (isInt(_dailyRewards)) {
            setDailyRewards(Number(_dailyRewards))
          } else {
            setDailyRewards(Number(_dailyRewards.toFixed(1)))
          }
        }
      } catch (error: any) {
        console.error(error)
      }
    }

    fetchData()
  }, [totalAllocPoint])
  return (
    <>
      <div>Daily Rewards</div>
      <StakeFooterDotted />
      <div>{formatNumber(dailyRewards)} HPL</div>
    </>
  )
}

export default DailyRewards
