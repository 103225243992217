import styled from 'styled-components/macro'
import { FormControl, FormSelect } from 'react-bootstrap'

export const BoxSaleWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex-flow: column nowrap;
  align-items: center;
  margin-bottom: 30px;
  padding: 20px;
  border-radius: 15px;
  color: #fff;
  background: linear-gradient(
    180deg,
    rgba(125, 60, 0, 0.15) 3.12%,
    rgba(73, 22, 0, 0.8) 57.81%,
    rgba(0, 0, 0, 0.9) 100%
  );
  border: 1px solid #beb8b4;
  box-sizing: border-box;
  box-shadow: 0 6px 0 #471712, inset 0 4px 0 rgba(255, 220, 95, 0.7);
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: 'Rubik', sans-serif;
  }
`

export const BoxSaleImg = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #191d21;
  margin-bottom: 25px;
  padding: 20px;
  border-radius: 10px;
`

export const BoxSaleContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 0 0 20px 0;
  .btn + .btn {
    margin-top: 15px;
  }
`

export const BoxName = styled.h4`
  display: flex;
  align-items: center;
  color: #ff6961;
  font-size: 24px;
  font-weight: 700;
  text-transform: capitalize;
`

export const PackQtyWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 5px;
  color: #888;
  text-align: right;
  font-size: 15px;
  font-weight: 400;
`

export const PackQty = styled(FormControl)`
  margin-bottom: 15px;
  box-shadow: none !important;
  border: 1px solid rgba(255, 255, 255, 0.3);
  background-color: #110f22;
  width: 100%;
  color: #fff;

  &:focus,
  &:active {
    border-color: #fff;
    background-color: #110f22;
    color: #fff;
  }

  &:placeholder-shown + p {
    display: none;
  }
`

export const TotalPrice = styled.div`
  display: flex;
`

export const TokenPaymentSelect = styled(FormSelect)`
  padding: 1px 20px 6px 6px;
  width: auto;
  background-color: transparent;
  color: #fff;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23ffffff' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
  background-position: right 10px;
  border: none;
  &:focus,
  &:active {
    outline: none;
    box-shadow: none;
  }
`

export const TotalBought = styled.p`
  margin-top: 15px;
  color: #888;
  text-align: right;
  font-size: 15px;
  font-weight: 400;
`
