import {
  StakeLink,
  WithdrawAction,
  WithdrawAmount,
  StyledWithdrawRow,
  WithdrawCountdown,
  WithdrawDiv,
  WithdrawHeading,
} from '../Styled'
import { formatNumber, fromWei } from '../../../utils'
import Countdown, { zeroPad } from 'react-countdown'
import UnstakeRow from './UnstakeRow'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { FiInfo } from 'react-icons/fi'

interface IUnstakeModalProps {
  poolId: number
  userInfo: any
  icon?: string
  icon2?: string
  approval: boolean
  approveLoading: boolean
  onRefresh: () => void
  onApprove: () => void
}

function UnstakeTab(props: IUnstakeModalProps): JSX.Element {
  const { poolId, userInfo, onRefresh } = props

  return (
    <>
      {userInfo.deposits.length > 0 ? (
        <div className="mb-3">
          <WithdrawHeading>
            <WithdrawAmount>Amount</WithdrawAmount>
            <WithdrawAction>
              <OverlayTrigger
                placement="left"
                overlay={<Tooltip id="unstake-action">Unstake is available when countdown completes</Tooltip>}
              >
                <span>
                  <FiInfo />
                </span>
              </OverlayTrigger>
            </WithdrawAction>
          </WithdrawHeading>
          <WithdrawDiv>
            {userInfo.deposits.map((info: any, index) => {
              return (
                <StyledWithdrawRow key={index}>
                  <WithdrawAmount>{formatNumber(fromWei(info.tokenAmount).toFixed(3))}</WithdrawAmount>
                  <WithdrawAction>
                    <Countdown
                      date={info.lockedUntil * 1000}
                      zeroPadTime={2}
                      renderer={props2 =>
                        props2.completed ? (
                          <>
                            <UnstakeRow index={index} info={info} poolId={poolId} onRefresh={onRefresh} />
                          </>
                        ) : (
                          <WithdrawCountdown>
                            {zeroPad(props2.days)}d:{zeroPad(props2.hours)}h:{zeroPad(props2.minutes)}m:
                            {zeroPad(props2.seconds)}
                          </WithdrawCountdown>
                        )
                      }
                    />
                  </WithdrawAction>
                </StyledWithdrawRow>
              )
            })}
          </WithdrawDiv>
        </div>
      ) : (
        <div className="pt-5 pb-5">
          <p style={{ fontSize: '18px', fontWeight: '500' }}>Looks like you have no stake</p>
          <p style={{ fontSize: '14px' }}>Select a pool, stake, and your rewards will appear here.</p>
          <StakeLink to={`/stake/`}>Select Pool</StakeLink>
        </div>
      )}
    </>
  )
}

export default UnstakeTab
