import { useState } from 'react'
import { Col, Container, Row, Tab } from 'react-bootstrap'
import CountUp from 'react-countup'
import Button from 'components/Button'
import Main from 'components/Main'
import SectionTitle from 'components/SectionTitle'
import StyledTab from 'components/Tab'
import BuyTicketsModal from './components/BuyTicketsModal/BuyTicketsModal'
import NextRoundCard from './components/NextRoundCard'
import RoundHistory from './components/RoundHistory'
import { TotalPrize } from './Styled'

function Lottery(): JSX.Element {
  const [showBuyTicketsModal, setShowBuyTicketsModal] = useState(false)

  // const onRefresh = () => {}
  return (
    <Main>
      <Container>
        <Row className="justify-content-center">
          <SectionTitle>The HappyLand Lottery</SectionTitle>
        </Row>
        <Row className="mb-5 text-center justify-content-center">
          <Col>
            <TotalPrize>
              <CountUp start={1000} end={100000} prefix="$ " duration={1} separator="," />
            </TotalPrize>
            <h2 className="mb-4">in prizes!</h2>
            <Button onClick={() => setShowBuyTicketsModal(true)}>Buy tickets</Button>
            <BuyTicketsModal show={showBuyTicketsModal} onHide={() => setShowBuyTicketsModal(false)} />
          </Col>
        </Row>
        <Row className="pt-5 text-center justify-content-center">
          <Col lg={8}>
            <SectionTitle>Get your tickets now!</SectionTitle>
            <NextRoundCard openBuyTicketsModal={() => setShowBuyTicketsModal(true)} />
          </Col>
        </Row>
        <Row className="pt-5 text-center justify-content-center">
          <Col lg={8}>
            <SectionTitle>Finished Rounds</SectionTitle>
            <StyledTab defaultActiveKey="all">
              <Tab eventKey="all" title="All History">
                <RoundHistory />
              </Tab>
              <Tab eventKey="your" title="Your History"></Tab>
            </StyledTab>
          </Col>
        </Row>
      </Container>
    </Main>
  )
}

export default Lottery
