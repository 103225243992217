import { useEffect, useState } from 'react'
import { Container, Row, Col, Tab } from 'react-bootstrap'
import { Zoom, toast } from 'react-toastify'
import ToastMessage from '../../components/ToastMessage'
import LandListView from '../../components/NftItem/LandListView'
import Main from '../../components/Main'
import StyledTab from '../../components/Tab'
import Button from '../../components/Button'
import ConnectModal from '../../components/AccountButton/ConnectModal'
import Pagination from '../../components/Pagination'
import Loading from '../../components/Loading'
import Land from '../../types/Land'
import Item from '../../types/Item'
import { useActiveWeb3React, useNFTsApi, NFTApprovalState, useApproveNFTCallback, useNFTContract } from '../../hooks'
import config from '../../config/config.json'
import InventoryImg from '../../assets/images/inventory.png'
import SectionTitle from '../../components/SectionTitle'
import { AccountCol } from '../Dashboard/Styled'

function Inventory(): JSX.Element {
  const [showConnectModal, setShowConnectModal] = useState(false)

  const [filteredLands, setFilteredLands] = useState<Land[]>([])
  const [paginatedLands, setPaginatedLands] = useState<Land[]>([])

  const [filteredItems, setFilteredItems] = useState<Item[]>([])
  const [paginatedItems, setPaginatedItems] = useState<Item[]>([])

  const [isLoading, setLoading] = useState(false)
  const [isApproving, setApproving] = useState(false)
  const [needApprove, setNeedApprove] = useState(true)

  const { account, chainId } = useActiveWeb3React()
  const networkId = chainId ?? Number(process.env.REACT_APP_CHAIN_ID)
  const ntfsCallback = useNFTsApi(account)
  const nftContract = useNFTContract(config.contracts[networkId].LandNFT)
  const [approval, approveCallback] = useApproveNFTCallback(
    config.contracts[networkId].LandNFT,
    config.contracts[networkId].Market,
  )

  const fetchNFTs = async () => {
    try {
      setLoading(true)
      const data = await ntfsCallback()

      const { lands: _lands, items: _items } = data

      if (_lands && _items) {
        const sortedLands = _lands.sort((a, b) => b.tokenId - a.tokenId)
        const sortedItems = _items.sort((a, b) => b.tokenId - a.tokenId)
        setFilteredLands(sortedLands)
        setPaginatedLands(sortedLands)
        setFilteredItems(sortedItems)
        setPaginatedItems(sortedItems)
      } else {
        setFilteredLands([])
        setPaginatedLands([])
        setFilteredItems([])
        setPaginatedItems([])
      }
    } catch (error: any) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchNFTs()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account])

  const onRefresh = async () => {
    try {
      setLoading(true)
      await fetchNFTs()
    } catch (error: any) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  const onApprove = async () => {
    try {
      setApproving(true)
      const receipt = await approveCallback()

      if (receipt && nftContract) {
        toast.success(<ToastMessage color="success" bodyText="Now you can use your lands." />, {
          toastId: 'onApprove',
          position: 'bottom-right',
          autoClose: 5000,
          hideProgressBar: true,
          transition: Zoom,
        })

        const _isApprovedForAll = await nftContract.methods
          .isApprovedForAll(account, config.contracts[networkId].LandNFT)
          .call()

        if (_isApprovedForAll) {
          setNeedApprove(false)
        }
        await onRefresh()
        window.location.reload()
      }
    } catch (error: any) {
      // we only lande if the error is something _other_ than the user rejected the tx
      if (error?.code !== 4001) {
        toast.error(<ToastMessage color="error" bodyText="Could not approve. Please try again." />, {
          toastId: 'onApprove',
          position: 'bottom-right',
          autoClose: 5000,
          hideProgressBar: true,
          transition: Zoom,
        })
      }
      console.error(error)
    } finally {
      setApproving(false)
      await onRefresh()
    }
  }

  const onRevoke = async () => {
    if (account && nftContract) {
      await nftContract.methods.setApprovalForAll(config.contracts[networkId].Market, false).send({ from: account })
      setNeedApprove(true)
    }
  }

  const onLandsPageChange = _lands => {
    setPaginatedLands(_lands)
  }

  const onItemsPageChange = _items => {
    setPaginatedItems(_items)
  }

  return (
    <>
      <Main>
        <Container>
          <Row className="pb-5 justify-content-center">
            <SectionTitle subTitle="Inventory" bgImage={InventoryImg} />
            {account ? (
              <>
                {isLoading ? (
                  <Loading />
                ) : (
                  <>
                    <StyledTab defaultActiveKey="land">
                      <Tab eventKey="land" title="Land">
                        {paginatedLands.length > 0 ? (
                          <>
                            <Row>
                              {paginatedLands.map(land => {
                                return (
                                  <Col key={land.tokenId} md={6} lg={6} xl={4} xxl={3}>
                                    <LandListView
                                      land={land}
                                      layout="grid"
                                      page="inventory"
                                      isApproving={isApproving}
                                      needApprove={needApprove && approval !== NFTApprovalState.APPROVED}
                                      onApprove={onApprove}
                                      onRefresh={onRefresh}
                                    />
                                  </Col>
                                )
                              })}
                            </Row>
                            <Pagination items={filteredLands} onPageChange={onLandsPageChange} pageSize={12} />
                          </>
                        ) : (
                          <p className="text-center">No lands found.</p>
                        )}
                      </Tab>
                      <Tab eventKey="items" title="Items">
                        {paginatedItems.length > 0 ? (
                          <>
                            <Row>
                              {paginatedItems.map(item => {
                                return (
                                  <Col key={item.tokenId} xl={6}>
                                    <p>NFT #</p>
                                  </Col>
                                )
                              })}
                            </Row>
                            <Pagination items={filteredItems} onPageChange={onItemsPageChange} pageSize={12} />
                          </>
                        ) : (
                          <p className="text-center">No items found.</p>
                        )}
                      </Tab>
                    </StyledTab>
                  </>
                )}
              </>
            ) : (
              <Row className="justify-content-center">
                <AccountCol xs={11} md={8} className="text-center">
                  <h5 className="mb-3">Looks like your wallet is not connected.</h5>
                  <p className="mb-5">Connect your wallet to continue</p>
                  <Button onClick={() => setShowConnectModal(true)}>Connect Wallet</Button>
                  <ConnectModal show={showConnectModal} onHide={() => setShowConnectModal(false)} />
                </AccountCol>
              </Row>
            )}
            {process.env.NODE_ENV !== 'production' && <Button onClick={onRevoke}>Revoke</Button>}
          </Row>
        </Container>
      </Main>
    </>
  )
}

export default Inventory
