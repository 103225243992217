import Modal from '../../../components/Modal'
import { Col, Row } from 'react-bootstrap'
import { PlotInfo, PlotEmpty } from '../Styled'

interface IDepositNFTsModalProps {
  plotEU: any
  plotASIA: any
  show: boolean
  onHide: () => void
}

function PlotInfoModal(props: IDepositNFTsModalProps): JSX.Element {
  const { plotEU, plotASIA, show, onHide } = props

  return (
    <Modal size="lg" show={show} onHide={onHide} title="Plot Info">
      <Row className="mt-4">
        <Col md={6}>
          <h6 className="mb-4">EU Server</h6>
          {plotEU.length > 0 ? (
            <PlotInfo>
              <li>
                Type Plot <span>Plant Counts</span>
              </li>
              {plotEU.map(plot => {
                return (
                  <li>
                    {plot?.typeRarity}: <span>{plot?.plantCount}</span>
                  </li>
                )
              })}
            </PlotInfo>
          ) : (
            <PlotEmpty>
              <p className="text-center">No plots</p>
            </PlotEmpty>
          )}
        </Col>
        <Col md={6}>
          <h6 className="mb-4">Asia Server</h6>
          {plotASIA.length > 0 ? (
            <PlotInfo>
              <li>
                Type Plot <span>Plant Counts</span>
              </li>
              {plotASIA.map(plot => {
                return (
                  <li>
                    {plot?.typeRarity}: <span>{plot?.plantCount}</span>
                  </li>
                )
              })}
            </PlotInfo>
          ) : (
            <PlotEmpty>
              <p className="text-center">No plots</p>
            </PlotEmpty>
          )}
        </Col>
      </Row>
    </Modal>
  )
}

export default PlotInfoModal
