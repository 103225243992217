import { useEffect, useState } from 'react'
import { useActiveWeb3React, useTokenBalance, useHPLPrice, useTVL, useHPWPrice } from '../../../hooks'
import { StakeFooterDotted, StakeFooterItem } from '../Styled'
import config from '../../../config/config.json'
import { formatNumber } from '../../../utils'

interface ITVLProps {
  poolId: number
  tokenName: string
}

function TVL(props: ITVLProps): JSX.Element {
  const { poolId, tokenName } = props

  const [tvl, setTVL] = useState(0)
  const [totalToken, setTotalToken] = useState(0)

  const { chainId } = useActiveWeb3React()
  const networkId = chainId ?? Number(process.env.REACT_APP_CHAIN_ID)
  const pools = config.pools[networkId]
  // @ts-ignore
  const pool = pools.find(p => p.id === poolId)

  const tokenBalanceCallback = useTokenBalance(pool?.lpAddress, config.contracts[networkId].MasterChef)
  const hplPriceCallback = useHPLPrice()
  const hpwPriceCallback = useHPWPrice()
  const tvlCallback = useTVL(poolId)

  useEffect(() => {
    const fetchData = async () => {
      try {
        const _tokenBalance = await tokenBalanceCallback()
        const _hplTokenPrice = await hplPriceCallback()
        const _hpwTokenPrice = await hpwPriceCallback()
        setTotalToken(_tokenBalance)
        if (poolId === 0) {
          setTVL(_tokenBalance * _hplTokenPrice)
        } else if (poolId === 1) {
          setTVL(_tokenBalance * _hpwTokenPrice)
        } else {
          const _tvl = await tvlCallback()
          setTVL(_tvl)
        }
      } catch (error: any) {
        console.error(error)
      }
    }

    fetchData()

    const interval = setInterval(() => {
      fetchData()
    }, 1000 * 20)

    return () => clearInterval(interval)
  }, [chainId])

  return (
    <>
      <StakeFooterItem>
        <div>TVL</div>
        <StakeFooterDotted />
        <div>{formatNumber(tvl.toFixed(3))} USD</div>
      </StakeFooterItem>
      <StakeFooterItem>
        <div>Total Tokens Lock</div>
        <StakeFooterDotted />
        <div>
          {formatNumber(totalToken.toFixed(3))} {tokenName}
        </div>
      </StakeFooterItem>
    </>
  )
}

export default TVL
