import {
  RoundHistoryWrapper,
  RoundHistoryHeader,
  RoundDate,
  RoundIDInput,
  RoundButton,
  RoundHistoryBody,
  WinningNumber,
} from '../../Styled'
import { BiChevronLeft, BiChevronRight, BiArrowToRight } from 'react-icons/bi'

function RoundHistory(): JSX.Element {
  return (
    <RoundHistoryWrapper>
      <RoundHistoryHeader>
        <div className="d-flex align-items-center justify-content-between">
          <div className="d-flex">
            <h5 className="mb-0">Round</h5>
            <RoundIDInput type="number" />
          </div>
          <div className="d-flex">
            <RoundButton>
              <BiChevronLeft size={30} />
            </RoundButton>
            <RoundButton disabled={true}>
              <BiChevronRight size={30} />
            </RoundButton>
            <RoundButton disabled={true}>
              <BiArrowToRight size={30} />
            </RoundButton>
          </div>
        </div>
        <RoundDate>Draw: Mar 01, 2022, 7:00 AM</RoundDate>
      </RoundHistoryHeader>
      <RoundHistoryBody>
        <h4>Winning Number</h4>
        <div>
          <WinningNumber>4</WinningNumber>
          <WinningNumber>5</WinningNumber>
          <WinningNumber>6</WinningNumber>
          <WinningNumber>7</WinningNumber>
          <WinningNumber>8</WinningNumber>
          <WinningNumber>9</WinningNumber>
        </div>
      </RoundHistoryBody>
    </RoundHistoryWrapper>
  )
}

export default RoundHistory
