import { useEffect, useState } from 'react'
import { Container, NavDropdown, Offcanvas } from 'react-bootstrap'
import { Link, useLocation } from 'react-router-dom'
import LogoPNG from '../../assets/images/logo.png'
import AccountButton from '../AccountButton'
import config from '../../config/config.json'
import { formatNumber } from '../../utils'
import { useTokenBalance, useActiveWeb3React } from '../../hooks'
import {
  HeaderMain,
  HeaderWrapper,
  HeaderTopLogin,
  LogoWrapper,
  MainMenu,
  NavBarWrap,
  Logo,
  MenuLink,
  MenuA,
  MobileMenuA,
  BalanceWrap,
  BalanceInner,
  MobileMenu,
  MobileMenuLink,
  MenuToggle,
  LotteryButton,
  MenuDropdown,
} from './Styled'
import { FiMenu } from 'react-icons/fi'

function Header(): JSX.Element {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
  const location = useLocation()
  const { pathname } = location

  const [hplBalance, setHPLBalance] = useState(0)

  const { account, chainId } = useActiveWeb3React()
  const networkId = chainId ?? Number(process.env.REACT_APP_CHAIN_ID)
  const tokenBalanceCallback = useTokenBalance(config.contracts[networkId].HPL, account)

  const fetchData = async () => {
    const _tokenBalance = await tokenBalanceCallback()
    setHPLBalance(_tokenBalance)
  }

  const handleMobileMenuOpen = () => {
    setMobileMenuOpen(true)
  }

  const handleMobileMenuClose = () => {
    setMobileMenuOpen(false)
  }

  useEffect(() => {
    fetchData()

    const interval = setInterval(() => {
      fetchData()
    }, 1000 * 10)

    return () => clearInterval(interval)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tokenBalanceCallback])

  return (
    <HeaderMain>
      <Container>
        <HeaderWrapper>
          <MenuToggle onClick={handleMobileMenuOpen}>
            <FiMenu size={24} />
          </MenuToggle>
          <LogoWrapper>
            <Link to="/">
              <Logo src={LogoPNG} alt="Happy Land" />
            </Link>
          </LogoWrapper>
          <MainMenu>
            <NavBarWrap className="d-none d-lg-flex flex-grow-1">
              <ul className="main-menu list-unstyled">
                {networkId === 56 && (
                  <li>
                    <MenuLink to="/happybox" className={`${pathname.includes('/happybox') && 'active'}`}>
                      HappyBox
                    </MenuLink>
                  </li>
                )}
                <li>
                  <MenuLink to="/marketplace" className={`${pathname === '/marketplace' && 'active'}`}>
                    Marketplace
                  </MenuLink>
                </li>
                <li>
                  <MenuLink to="/inventory" className={`${pathname === '/inventory' && 'active'}`}>
                    Inventory
                  </MenuLink>
                </li>
                <li>
                  <MenuLink to="/stake" className={`${pathname === '/stake' && 'active'}`}>
                    Stake
                  </MenuLink>
                </li>
                <li>
                  <MenuLink to="/play" className={`${pathname.includes('/play') && 'active'}`}>
                    Play
                  </MenuLink>
                </li>
                {networkId === 97 && account === '0x4dcfc32c29da93fca65a7f6eb57b5253217846cf' && (
                  <li>
                    <MenuLink to="/expand" className={`${pathname.includes('/expand') && 'active'}`}>
                      Expand
                    </MenuLink>
                  </li>
                )}
                <li>
                  <MenuDropdown title="More">
                    {(networkId === 97 || networkId === 56766) && (
                      <NavDropdown.Item>
                        <MenuLink to="/faucet" className={`${pathname.includes('/faucet') && 'active'}`}>
                          Faucet
                        </MenuLink>
                      </NavDropdown.Item>
                    )}
                    <NavDropdown.Item>
                      <MenuA href="https://docs.happyland.finance/" target="_blank" rel="nofollow">
                        Docs
                      </MenuA>
                    </NavDropdown.Item>
                  </MenuDropdown>
                </li>
              </ul>
            </NavBarWrap>
          </MainMenu>
          <HeaderTopLogin>
            <AccountButton />
          </HeaderTopLogin>
          {account ? (
            <BalanceWrap>
              <BalanceInner>
                <div>
                  <span style={{ color: '#FFE843' }}>Balance: </span>
                  {formatNumber(hplBalance.toFixed(3))} HPL
                </div>
              </BalanceInner>
            </BalanceWrap>
          ) : null}
        </HeaderWrapper>
        <Offcanvas show={mobileMenuOpen} onHide={handleMobileMenuClose}>
          <Offcanvas.Header closeButton closeVariant="white" />
          <Offcanvas.Body>
            <MobileMenu>
              {networkId === 56 && (
                <li>
                  <MobileMenuLink
                    to="/happybox"
                    onClick={handleMobileMenuClose}
                    className={`${pathname === '/happybox' && 'active'}`}
                  >
                    HappyBox
                  </MobileMenuLink>
                </li>
              )}
              <li>
                <MobileMenuLink
                  to="/marketplace"
                  onClick={handleMobileMenuClose}
                  className={`${pathname === '/marketplace' && 'active'}`}
                >
                  Marketplace
                </MobileMenuLink>
              </li>
              <li>
                <MobileMenuLink
                  to="/inventory"
                  onClick={handleMobileMenuClose}
                  className={`${pathname === '/inventory' && 'active'}`}
                >
                  Inventory
                </MobileMenuLink>
              </li>
              <li>
                <MobileMenuLink
                  to="/stake"
                  onClick={handleMobileMenuClose}
                  className={`${pathname === '/stake' && 'active'}`}
                >
                  Stake
                </MobileMenuLink>
              </li>
              <li>
                <MobileMenuLink
                  to="/play"
                  onClick={handleMobileMenuClose}
                  className={`${pathname.includes('/play') && 'active'}`}
                >
                  Play
                </MobileMenuLink>
              </li>
              {(networkId === 97 || networkId === 56766) && (
                <li>
                  <MobileMenuLink
                    to="/faucet"
                    onClick={handleMobileMenuClose}
                    className={`${pathname.includes('/faucet') && 'active'}`}
                  >
                    Faucet
                  </MobileMenuLink>
                </li>
              )}
              {networkId === 97 && account === '0x4dcfc32c29da93fca65a7f6eb57b5253217846cf' && (
                <li>
                  <MobileMenuLink
                    to="/expand"
                    onClick={handleMobileMenuClose}
                    className={`${pathname.includes('/expand') && 'active'}`}
                  >
                    Expand
                  </MobileMenuLink>
                </li>
              )}
              <li>
                <MobileMenuA
                  href="https://docs.happyland.finance/"
                  onClick={handleMobileMenuClose}
                  target="_blank"
                  rel="nofollow"
                >
                  Docs
                </MobileMenuA>
              </li>
            </MobileMenu>
          </Offcanvas.Body>
        </Offcanvas>
      </Container>
      {pathname !== '/lottery' && account === '0x4dcfc32c29da93fca65a7f6eb57b5253217846cf' && (
        <LotteryButton>
          <Link to="/lottery">Lottery</Link>
        </LotteryButton>
      )}
    </HeaderMain>
  )
}

export default Header
