import { ThemeProvider } from 'styled-components/macro'
import styled from 'styled-components/macro'
import Inventory from './pages/Inventory'
import Stake from './pages/Stake'
import SingleStake from './pages/Stake/SingleStake'
import HappyBoxSale from './pages/HappyBoxSale'
import Marketplace from './pages/Marketplace'
import PlayToEarn from './pages/PlayToEarn'
import Dashboard from './pages/Dashboard'
import Faucet from './pages/Faucet'
import Lottery from './pages/Lottery'
import Expand from './pages/Expand'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import { useActiveWeb3React } from './hooks'
import Web3ReactManager from './components/Web3ReactManager'
import PreLoader from './components/PreLoader'
import Header from './components/Header'
import theme from './theme'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'react-toastify/dist/ReactToastify.css'
import './assets/css/toastify.css'
import './assets/css/style.css'
import RentSingle from './pages/Marketplace/rent'
import BuySingle from './pages/Marketplace/buy'

const Main = styled.section`
  position: relative;
`

function App(): JSX.Element {
  const { account, chainId } = useActiveWeb3React()
  const networkId = chainId ?? 56
  return (
    <Web3ReactManager>
      <ThemeProvider theme={theme}>
        <Router>
          <Header />
          <PreLoader />
          <Main>
            <Switch>
              <Route exact path="/">
                <Dashboard />
              </Route>
              <Route exact path="/dashboard">
                <Dashboard />
              </Route>
              {networkId === 56 && (
                <Route exact path="/happybox">
                  <HappyBoxSale />
                </Route>
              )}
              <Route exact path="/marketplace">
                <Marketplace />
              </Route>
              <Route exact path="/buy/:slug">
                <BuySingle />
              </Route>
              <Route exact path="/rent/:slug">
                <RentSingle />
              </Route>
              <Route exact path="/inventory">
                <Inventory />
              </Route>
              <Route exact path="/stake">
                <Stake />
              </Route>
              <Route exact path="/stake/:slug">
                <SingleStake />
              </Route>
              <Route exact path="/play">
                <PlayToEarn />
              </Route>
              {networkId === 97 && account === '0x4dcfc32c29da93fca65a7f6eb57b5253217846cf' && (
                <Route exact path="/lottery">
                  <Lottery />
                </Route>
              )}
              {networkId === 97 && account === '0x4dcfc32c29da93fca65a7f6eb57b5253217846cf' && (
                <Route exact path="/expand">
                  <Expand />
                </Route>
              )}
              {(networkId === 97 || networkId === 56766) && (
                <Route exact path="/faucet">
                  <Faucet />
                </Route>
              )}
            </Switch>
          </Main>
        </Router>
      </ThemeProvider>
    </Web3ReactManager>
  )
}

export default App
