import { useEffect, useState } from 'react'
import axios, { AxiosError } from 'axios'
import { toast, Zoom } from 'react-toastify'
import Countdown, { zeroPad } from 'react-countdown'
import ToastMessage from '../../components/ToastMessage'
import { Container, Row, Col, Tooltip, OverlayTrigger } from 'react-bootstrap'
import useWindowSize from 'react-use/lib/useWindowSize'
import Confetti from 'react-confetti'
import Main from '../../components/Main'
import {
  ApprovalState,
  useTokenContract,
  useApproveCallback,
  useActiveWeb3React,
  useLandSaleContract,
} from '../../hooks'
import { getLandName, toWei } from '../../utils'
import config from '../../config/config.json'
import { AccountCol } from '../Dashboard/Styled'
import {
  BoxName,
  BoxSaleContent,
  BoxSaleImg,
  BoxSaleWrapper,
  PackQtyWrapper,
  PackQty,
  TokenPaymentSelect,
  TotalPrice,
  TotalBought,
} from './Styled'
import SectionTitle from '../../components/SectionTitle'
import ConnectModal from '../../components/AccountButton/ConnectModal'
import Loading from '../../components/Loading'
import BoxTitle from '../../assets/images/happybox.png'
import OpenBoxTitle from '../../assets/images/openbox.png'
import { FiInfo } from 'react-icons/fi'
import Button from '../../components/Button'
import Modal from '../../components/Modal'

function HappyBoxSale(): JSX.Element {
  // @ts-ignore
  const imagesDir = require.context(`../../assets/images/`, true)
  const [showConnectModal, setShowConnectModal] = useState(false)
  const [showOpenBoxModal, setShowOpenBoxModal] = useState(false)
  const [isLoading, setLoading] = useState(false)
  const [isLoaded, setLoaded] = useState(false)
  const [isOpenBox, setOpenBox] = useState(false)
  const [maxBox, setMaxBox] = useState(0)
  const [bought, setBought] = useState(0)
  const [boxOpened, setBoxOpened] = useState(0)
  const [numberBox, setNumberBox] = useState(0)
  const [boxAvailable, setBoxAvailable] = useState(0)
  const [isApprovingHPL, setApprovingHPL] = useState(false)
  const [isApprovingBUSD, setApprovingBUSD] = useState(false)
  const [needApproveHPL, setNeedApproveHPL] = useState(true)
  const [needApproveBUSD, setNeedApproveBUSD] = useState(true)
  const [runConfetti, setRunConfetti] = useState(false)
  const [landName, setLandName] = useState('highland')
  const [landID, setLandID] = useState('1')
  const [quantity, setQuantity] = useState(1)
  const [boxPrice, setBoxPrice] = useState(240)
  const [totalPrice, setTotalPrice] = useState(240)
  const { width, height } = useWindowSize()

  const { account, chainId } = useActiveWeb3React()
  const networkId = chainId ?? Number(process.env.REACT_APP_CHAIN_ID)
  const apiURL = config.api[networkId]
  const [paymentToken, setPaymentToken] = useState(config.payments[networkId].BUSD)
  const landSaleContract = useLandSaleContract(config.contracts[networkId].Factory)

  const hplContract = useTokenContract(config.payments[networkId].HPL)
  const busdContract = useTokenContract(config.payments[networkId].BUSD)

  const [approvalHPL, approveHPLCallback] = useApproveCallback(
    config.payments[networkId].HPL,
    config.contracts[networkId].Factory,
  )
  const [approvalBUSD, approveCallbackBUSD] = useApproveCallback(
    config.payments[networkId].BUSD,
    config.contracts[networkId].Factory,
  )

  const showBoxSale = false
  const showOpen = true

  const onApproveHPL = async () => {
    try {
      setApprovingHPL(true)
      const receipt = await approveHPLCallback()

      if (receipt && hplContract) {
        toast.success(<ToastMessage color="success" bodyText="Approve HPL successfully" />, {
          toastId: 'onApproveHPL',
          position: 'bottom-right',
          autoClose: 5000,
          hideProgressBar: true,
          transition: Zoom,
        })

        const _allowance = await hplContract.methods.allowance(account, config.contracts[networkId].Factory).call()

        if (toWei(_allowance).gt(toWei(0))) {
          setNeedApproveHPL(false)
          window.location.reload()
        }
      }
    } catch (error: any) {
      // we only care if the error is something _other_ than the user rejected the tx
      if (error?.code !== 4001) {
        toast.error(
          <ToastMessage color="error" headerText="Error!" bodyText="Could not approve HPL. Please try again." />,
          {
            toastId: 'onApproveHPL',
            position: 'bottom-right',
            autoClose: 5000,
            hideProgressBar: true,
            transition: Zoom,
          },
        )
      }
      console.error(error)
    } finally {
      setApprovingHPL(false)
    }
  }

  const onApproveBUSD = async () => {
    try {
      setApprovingBUSD(true)
      const receipt = await approveCallbackBUSD()

      if (receipt && busdContract) {
        toast.success(<ToastMessage color="success" bodyText="Approve BUSD successfully" />, {
          toastId: 'onApproveBUSD',
          position: 'bottom-right',
          autoClose: 5000,
          hideProgressBar: true,
          transition: Zoom,
        })

        const _allowance = await busdContract.methods.allowance(account, config.contracts[networkId].Factory).call()

        if (toWei(_allowance).gt(toWei(0))) {
          setNeedApproveBUSD(false)
          window.location.reload()
        }
      }
    } catch (error: any) {
      // we only care if the error is something _other_ than the user rejected the tx
      if (error?.code !== 4001) {
        toast.error(
          <ToastMessage color="error" headerText="Error!" bodyText="Could not approve BUSD. Please try again." />,
          {
            toastId: 'onApproveBUSD',
            position: 'bottom-right',
            autoClose: 5000,
            hideProgressBar: true,
            transition: Zoom,
          },
        )
      }
      console.error(error)
    } finally {
      setApprovingBUSD(false)
    }
  }

  const checkWhitelist = async () => {
    try {
      if (account) {
        const response = await axios.get(`${apiURL}/boxes/boxCanBuy/${account}`)
        if (landSaleContract) {
          const boxBought = await landSaleContract.methods.buyerBoxNumber(account).call()
          const boxOpen = await landSaleContract.methods.buyerBoxOpen(account).call()
          setBought(parseInt(boxBought))
          setBoxOpened(parseInt(boxOpen))
          setNumberBox(parseInt(boxBought) - parseInt(boxOpen))
          setLoaded(true)
          if (response.status === 200 && response.data) {
            const { boxNumber } = response.data
            if (boxNumber > 0) {
              setMaxBox(boxNumber)
              setBoxAvailable(boxNumber - parseInt(boxBought))
            }
          }
        }
      }
    } catch (error: any) {
      console.error(error)
    }
  }

  const onHideOpenBox = () => {
    setShowOpenBoxModal(false)
    setRunConfetti(false)
  }

  useEffect(() => {
    checkWhitelist()
  }, [account, networkId])

  const onPaymentTokenChange = e => {
    const { value } = e.currentTarget
    setPaymentToken(value)
    if (value === config.payments[networkId].HPL) {
      setBoxPrice(857)
      setTotalPrice(857 * quantity)
    } else {
      setBoxPrice(240)
      setTotalPrice(240 * quantity)
    }
  }

  const onQtyChange = e => {
    const { value } = e.currentTarget
    let _quantity = Number(value)

    if (_quantity < 0) {
      _quantity = 1
    } else if (_quantity > boxAvailable) {
      _quantity = boxAvailable
    }

    setQuantity(_quantity)
    setTotalPrice(_quantity * boxPrice)
  }

  const onRefresh = async () => {
    checkWhitelist()
    setQuantity(1)
  }

  const onBuyBox = async () => {
    try {
      setLoading(true)

      if (quantity <= 0 || quantity > boxAvailable) {
        const error = new Error(
          `The number of boxes should be greater than 0 and less than or equal to ${boxAvailable}.`,
        )
        error.name = 'Quantity Error'
        throw error
      }

      if (account || landSaleContract) {
        const response = await axios.post(`${apiURL}/boxes/requestBuyBox`, {
          buyer: account,
          tokenPayment: paymentToken,
          boxNumber: quantity,
          maxBoxNumber: maxBox,
        })

        if (response.status === 200 && response.data) {
          const { tokenPayment, tokenAmount, boxNumber, maxBoxNumber, expiryTime, r, s, v } = response.data

          // @ts-ignore
          const receipt = await landSaleContract.methods
            .buyBox(tokenPayment, tokenAmount, boxNumber, maxBoxNumber, expiryTime, r, s, v)
            .send({
              from: account,
            })

          if (receipt) {
            await onRefresh()

            toast.success(
              <ToastMessage
                color="success"
                bodyText="Success"
                link={`${config.explorerURL[networkId]}/tx/${receipt.transactionHash}`}
                linkText="View Transaction"
              />,
              {
                toastId: 'onBuyBox',
                position: 'bottom-right',
                autoClose: 5000,
                hideProgressBar: true,
                transition: Zoom,
              },
            )
          }
        }
      }
    } catch (error: any) {
      if (axios.isAxiosError(error)) {
        const _e = error as AxiosError
        const msg = _e.response?.data.errors
        toast.error(
          <ToastMessage
            color="error"
            headerText="Error"
            bodyText={msg ? msg.toUpperCase() : 'Could not buy this box.'}
          />,
          {
            toastId: 'onBuyBox',
            position: 'bottom-right',
            autoClose: 5000,
            hideProgressBar: true,
            transition: Zoom,
          },
        )
      } else {
        // we only care if the error is something _other_ than the user rejected the tx
        if (error?.code !== 4001) {
          if (error.name === 'QuantityError') {
            toast.error(<ToastMessage color="error" bodyText={error.message} />, {
              toastId: 'onBuyBox',
              position: 'bottom-right',
              autoClose: 5000,
              hideProgressBar: true,
              transition: Zoom,
            })
          } else {
            toast.error(<ToastMessage color="error" bodyText="Could not buy this box." />, {
              toastId: 'onBuyBox',
              position: 'bottom-right',
              autoClose: 5000,
              hideProgressBar: true,
              transition: Zoom,
            })
          }
        }
      }
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  const onOpenBox = async () => {
    try {
      setOpenBox(true)

      if (account && landSaleContract) {
        let response = await axios.post(`${apiURL}/boxes/requestOpenBox`, {
          buyer: account,
        })

        if (response.status === 200 && response.data) {
          let { key, commitment, expiryTime, r, s, v } = response.data
          let useKey = await landSaleContract.methods.useKeys(key).call()

          while (useKey === true) {
            response = await axios.post(`${apiURL}/boxes/requestOpenBox`, {
              buyer: account,
              key,
            })
            if (response.status === 400) return
            useKey = await landSaleContract.methods.useKeys(response.data.key).call()
            // eslint-disable-next-line prefer-destructuring
            key = response.data.key
            // eslint-disable-next-line prefer-destructuring
            commitment = response.data.commitment
            // eslint-disable-next-line prefer-destructuring
            expiryTime = response.data.expiryTime
            // eslint-disable-next-line prefer-destructuring
            r = response.data.r
            // eslint-disable-next-line prefer-destructuring
            s = response.data.s
            // eslint-disable-next-line prefer-destructuring
            v = response.data.v
          }

          if (useKey === false) {
            const receipt = await landSaleContract.methods.openBox(key, commitment, expiryTime, r, s, v).send({
              from: account,
            })

            if (receipt) {
              await onRefresh()

              const lastTokenIdCallback = await landSaleContract.methods.buyerLastTokenId(account).call()

              if (lastTokenIdCallback) {
                // eslint-disable-next-line @typescript-eslint/no-shadow
                const { commitment, tokenId } = lastTokenIdCallback
                const name = getLandName(commitment)
                if (name) {
                  setLandName(name)
                }
                setRunConfetti(true)
                setLandID(tokenId)
                setShowOpenBoxModal(true)
              }

              // toast.success(
              //   <ToastMessage
              //     color="success"
              //     bodyText={`Congratulation! You've got a ${land} #${tokenId}.`}
              //     link={`${config.explorerURL[networkId]}/tx/${receipt.transactionHash}`}
              //     linkText="View Transaction"
              //   />,
              //   {
              //     toastId: 'onOpenBox',
              //     position: 'bottom-right',
              //     autoClose: 5000,
              //     hideProgressBar: true,
              //     transition: Zoom,
              //   },
              // )
            }
          }
        }
      }
    } catch (error: any) {
      if (axios.isAxiosError(error)) {
        const _e = error as AxiosError
        const msg = _e.response?.data.errors
        toast.error(
          <ToastMessage
            color="error"
            headerText="Error"
            bodyText={msg ? msg.toUpperCase() : 'Could not open this box.'}
          />,
          {
            toastId: 'onOpenBox',
            position: 'bottom-right',
            autoClose: 5000,
            hideProgressBar: true,
            transition: Zoom,
          },
        )
      } else {
        // we only care if the error is something _other_ than the user rejected the tx
        if (error?.code !== 4001) {
          if (error.name === 'QuantityError') {
            toast.error(<ToastMessage color="error" bodyText={error.message} />, {
              toastId: 'onOpenBox',
              position: 'bottom-right',
              autoClose: 5000,
              hideProgressBar: true,
              transition: Zoom,
            })
          } else {
            toast.error(<ToastMessage color="error" bodyText="Could not open this box." />, {
              toastId: 'onOpenBox',
              position: 'bottom-right',
              autoClose: 5000,
              hideProgressBar: true,
              transition: Zoom,
            })
          }
        }
      }
      console.error(error)
    } finally {
      setOpenBox(false)
    }
  }

  // const onRevoke = async () => {
  //   if (hplContract && busdContract && account) {
  //     await hplContract.methods.approve(config.contracts[networkId].Factory, 0).send({ from: account })
  //     await busdContract.methods.approve(config.contracts[networkId].Factory, 0).send({ from: account })
  //   }
  // }

  return (
    <>
      <Confetti
        width={width}
        height={height}
        run={runConfetti}
        recycle={false}
        numberOfPieces={2000}
        style={{ zIndex: '9999' }}
      />
      <Main>
        <Container>
          {account ? (
            <>
              {showBoxSale && (
                <Row className="justify-content-center">
                  <SectionTitle subTitle="Box Sale" bgImage={BoxTitle} />
                  <Col xs={12} md={6} lg={4}>
                    <BoxSaleWrapper>
                      <BoxSaleImg>
                        <img src={imagesDir(`./lands/happybox.png`).default} alt="HappyBox" />
                      </BoxSaleImg>
                      <BoxSaleContent>
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            width: '100%',
                            marginBottom: '10px',
                          }}
                        >
                          <BoxName>
                            Happy Box
                            <OverlayTrigger
                              placement="right"
                              overlay={
                                <Tooltip id="unstake-action">
                                  <div
                                    style={{
                                      textAlign: 'left',
                                      paddingLeft: '5px',
                                      paddingTop: '10px',
                                      paddingBottom: '10px',
                                    }}
                                  >
                                    <b>Chance of getting different types of land</b>
                                    <ul style={{ listStyle: 'none' }}>
                                      <li>- Highland - 50%</li>
                                      <li>- Valley - 20%</li>
                                      <li>- Woodland - 15%</li>
                                      <li>- Meadow - 10%</li>
                                      <li>- Euphoria - 5%</li>
                                    </ul>
                                  </div>
                                </Tooltip>
                              }
                            >
                              <span style={{ fontSize: '14px', marginLeft: '5px', color: '#fff' }}>
                                <FiInfo />
                              </span>
                            </OverlayTrigger>
                          </BoxName>
                          <TotalPrice>
                            <div>{totalPrice}</div>
                            <TokenPaymentSelect
                              aria-label="Token Payment"
                              value={paymentToken}
                              onChange={e => onPaymentTokenChange(e)}
                            >
                              <option value={config.payments[networkId].BUSD}>BUSD</option>
                              <option value={config.payments[networkId].HPL}>HPL</option>
                            </TokenPaymentSelect>
                          </TotalPrice>
                        </div>
                        {boxAvailable > 1 && (
                          <>
                            <PackQtyWrapper>
                              <span>Quantity</span>
                              <span>
                                Buyable Boxes: <span style={{ color: '#fff' }}>{boxAvailable}</span>
                              </span>
                            </PackQtyWrapper>
                            <PackQty
                              type="number"
                              min={1}
                              max={boxAvailable}
                              placeholder="1"
                              value={quantity}
                              onChange={e => onQtyChange(e)}
                            />
                          </>
                        )}
                        {needApproveHPL &&
                          approvalHPL !== ApprovalState.APPROVED &&
                          paymentToken === config.payments[networkId].HPL && (
                            <Button disabled={isApprovingHPL} loading={isApprovingHPL} onClick={onApproveHPL}>
                              <span>Approve HPL</span>
                            </Button>
                          )}
                        {needApproveBUSD &&
                          approvalBUSD !== ApprovalState.APPROVED &&
                          paymentToken === config.payments[networkId].BUSD && (
                            <Button disabled={isApprovingBUSD} loading={isApprovingBUSD} onClick={onApproveBUSD}>
                              <span>Approve BUSD</span>
                            </Button>
                          )}
                        {paymentToken === config.payments[networkId].BUSD && approvalBUSD === ApprovalState.APPROVED && (
                          <Button disabled={isLoading} loading={isLoading} onClick={onBuyBox}>
                            Buy HappyBox
                          </Button>
                        )}
                        {paymentToken === config.payments[networkId].HPL && approvalHPL === ApprovalState.APPROVED && (
                          <Button disabled={isLoading} loading={isLoading} onClick={onBuyBox}>
                            Buy HappyBox
                          </Button>
                        )}
                        {bought > 0 && (
                          <TotalBought>
                            You've bought {bought} {bought > 1 ? 'boxes' : 'box'}
                          </TotalBought>
                        )}
                      </BoxSaleContent>
                    </BoxSaleWrapper>
                    {/*{process.env.NODE_ENV !== 'production' && <Button onClick={onRevoke}>Revoke</Button>}*/}
                  </Col>
                </Row>
              )}
              {showOpen && (
                <Row className="justify-content-center">
                  <SectionTitle subTitle="Open Box" bgImage={OpenBoxTitle} />
                  <Countdown
                    date={1643360400 * 1000}
                    zeroPadTime={2}
                    renderer={props2 =>
                      props2.completed ? (
                        <>
                          {isLoaded ? (
                            <>
                              <h4 className="text-center mb-5">
                                Total bought {bought} {bought > 1 ? 'boxes' : 'box'}.{' '}
                                {boxOpened > 0 ? `You've opened ${boxOpened}` : ''}
                              </h4>
                              {[...Array(numberBox)].map(index => (
                                <Col md={6} lg={3} key={index}>
                                  <BoxSaleWrapper>
                                    <BoxSaleImg>
                                      <img src={imagesDir(`./lands/happybox.png`).default} alt="HappyBox" />
                                    </BoxSaleImg>
                                    <BoxSaleContent>
                                      <BoxName style={{ marginBottom: '20px' }}>
                                        Happy Box
                                        <OverlayTrigger
                                          placement="right"
                                          overlay={
                                            <Tooltip id="unstake-action">
                                              <div
                                                style={{
                                                  textAlign: 'left',
                                                  paddingLeft: '5px',
                                                  paddingTop: '10px',
                                                  paddingBottom: '10px',
                                                }}
                                              >
                                                <b>Chance of getting different types of land</b>
                                                <ul style={{ listStyle: 'none' }}>
                                                  <li>- Highland - 50%</li>
                                                  <li>- Valley - 20%</li>
                                                  <li>- Woodland - 15%</li>
                                                  <li>- Meadow - 10%</li>
                                                  <li>- Euphoria - 5%</li>
                                                </ul>
                                              </div>
                                            </Tooltip>
                                          }
                                        >
                                          <span style={{ fontSize: '14px', marginLeft: '5px', color: '#fff' }}>
                                            <FiInfo />
                                          </span>
                                        </OverlayTrigger>
                                      </BoxName>
                                      <Button loading={isOpenBox} disabled={isOpenBox} onClick={onOpenBox}>
                                        Open Box
                                      </Button>
                                    </BoxSaleContent>
                                  </BoxSaleWrapper>
                                </Col>
                              ))}
                              <Modal
                                show={showOpenBoxModal}
                                title={''}
                                onHide={onHideOpenBox}
                                backdrop="static"
                                className="openbox-modal"
                              >
                                <div className="text-center">
                                  <img src={imagesDir(`./lands/${landName}.png`).default} alt={landName} />
                                  <h4 className="mt-5">
                                    Congratulation!!! <br />
                                    {`You have got a ${landName} #${landID}`}
                                  </h4>
                                </div>
                              </Modal>
                            </>
                          ) : (
                            <Loading />
                          )}
                        </>
                      ) : (
                        <Col md={6}>
                          <h5 className="text-center" style={{ lineHeight: '1.6' }}>
                            HappyBox sale on our marketplace is over. <br />
                            You can open the box and claim your Land NFT in <br />
                            {zeroPad(props2.hours)}h:{zeroPad(props2.minutes)}m:{zeroPad(props2.seconds)}s
                          </h5>
                        </Col>
                      )
                    }
                  />
                </Row>
              )}
            </>
          ) : (
            <Row className="justify-content-center">
              <SectionTitle subTitle="Box Sale" bgImage={BoxTitle} />
              <AccountCol xs={11} md={8} className="text-center">
                <h5 className="mb-3">Looks like your wallet is not connected.</h5>
                <p className="mb-5">Connect your wallet to see available HappyBox</p>
                <Button onClick={() => setShowConnectModal(true)}>Connect Wallet</Button>
                <ConnectModal show={showConnectModal} onHide={() => setShowConnectModal(false)} />
              </AccountCol>
            </Row>
          )}
        </Container>
      </Main>
    </>
  )
}

export default HappyBoxSale
