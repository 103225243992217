import { useRef, useEffect } from 'react'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function useIsMounted() {
  const isMounted = useRef(false)

  // @ts-ignore
  useEffect(() => {
    isMounted.current = true
    return () => (isMounted.current = false)
  }, [])

  return isMounted
}
