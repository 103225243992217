import { useCallback } from 'react'
import axios from 'axios'
import { useActiveWeb3React } from './useWeb3'
import config from '../config/config.json'

export const useNFTsInGameApi = (account: string | null | undefined): (() => Promise<any>) => {
  const { chainId } = useActiveWeb3React()
  const networkId = chainId ?? Number(process.env.REACT_APP_CHAIN_ID)
  const apiURL = config.api[networkId]

  const callback = useCallback(async (): Promise<any> => {
    try {
      if (!account) {
        return { lands: [], items: [], hplDeposited: 0, hpwDeposited: 0 }
      }
      const response = await axios.get(`${apiURL}/users/playerInfo/${account}`)

      if (response.status === 200 && response.data) {
        const {
          username,
          land,
          items,
          totalHPLDeposit,
          totalHPWDeposit,
          totalHPLSpent,
          totalHPWSpent,
          totalHPLWithdraw,
          totalHPWWithdraw,
          totalHPLReward,
          totalHPLRewardClaimed,
          totalHPWReward,
          totalHPWRewardClaimed,
        } = response.data
        return {
          username,
          land,
          items,
          totalHPLDeposit,
          totalHPWDeposit,
          totalHPLSpent,
          totalHPWSpent,
          totalHPLWithdraw,
          totalHPWWithdraw,
          totalHPLReward,
          totalHPLRewardClaimed,
          totalHPWReward,
          totalHPWRewardClaimed,
        }
      }
    } catch (error: any) {
      console.error(error)
      return {}
    }
  }, [account])

  return callback
}

export const useNFTsInGame = (
  account: string | null | undefined,
  typeAPI: 'ingame' | 'outgame',
): (() => Promise<any>) => {
  const { chainId } = useActiveWeb3React()
  const networkId = chainId ?? Number(process.env.REACT_APP_CHAIN_ID)
  const apiURL = config.api[networkId]

  const callback = useCallback(async (): Promise<any> => {
    try {
      if (!account) {
        return { lands: [], items: [] }
      }
      const response = await axios.get(`${apiURL}/tokens/${typeAPI}/${account}`)

      if (response.status === 200 && response.data) {
        const { tokens } = response.data
        const lands = tokens.filter(token => token.tokenType === 'land')
        const items = tokens.filter(token => token.tokenType === 'item')
        return { lands, items }
      }
    } catch (error: any) {
      console.error(error)
      return {}
    }
  }, [account])

  return callback
}
