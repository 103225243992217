import { useEffect, useRef, useState } from 'react'
import { ApprovalState, useApproveCallback, useActiveWeb3React, useTokenBalance } from '../../../../hooks'
import { Buttons, BuyTicketsInput, ShortcutButtonsWrapper, StyledInputGroup } from '../../Styled'
import Modal from 'components/Modal'
import config from 'config/config.json'
import Button from 'components/Button'
import EditNumbersModal from './EditNumbersModal'
import { useTicketsReducer } from './useTicketsReducer'

interface IBuyTicketsModal {
  show: boolean
  onHide: () => void
}

enum BuyingStage {
  BUY = 'Buy',
  EDIT = 'Edit',
}

function BuyTicketsModal(props: IBuyTicketsModal): JSX.Element {
  const { show, onHide } = props

  const [buyingStage, setBuyingStage] = useState<BuyingStage>(BuyingStage.BUY)
  const [ticketsToBuy, setTicketsToBuy] = useState('0')
  const [userNotEnoughToken, setUserNotEnoughToken] = useState(false)
  const [maxPossibleTicketPurchase, setMaxPossibleTicketPurchase] = useState(100) // TODO: useState(0)
  const [maxTicketPurchaseExceeded, setMaxTicketPurchaseExceeded] = useState(false)

  const [hplBalance, setHPLBalance] = useState(0)
  const [needApproveHPL, setNeedApproveHPL] = useState(true)

  const { account, chainId } = useActiveWeb3React()
  const networkId = chainId ?? Number(process.env.REACT_APP_CHAIN_ID)
  const hplBalanceCallback = useTokenBalance(config.contracts[networkId].HPL, account)

  const ref = useRef()
  const componentMounted = useRef(true)

  const disableBuying = !ticketsToBuy || parseInt(ticketsToBuy) <= 0

  const [updateTicket, randomize, tickets, allComplete, getTicketsForPurchase] = useTicketsReducer(
    parseInt(ticketsToBuy),
    [],
  )

  useEffect(() => {
    const fetchData = async () => {
      const _hplBalance = await hplBalanceCallback()

      if (componentMounted.current) {
        setHPLBalance(_hplBalance)
      }
    }

    fetchData()

    if (ref.current) {
      // @ts-ignore
      ref.current.focus()
    }

    return () => {
      componentMounted.current = false
    }
  }, [show, hplBalanceCallback])

  const getNumTicketsByPercentage = (percentage: number): number => {
    const percentageOfMaxTickets = maxPossibleTicketPurchase > 0 ? (maxPossibleTicketPurchase / 100) * percentage : 0
    return Math.floor(percentageOfMaxTickets)
  }

  const tenPercentOfBalance = getNumTicketsByPercentage(10)
  const twentyFivePercentOfBalance = getNumTicketsByPercentage(25)
  const fiftyPercentOfBalance = getNumTicketsByPercentage(50)
  const oneHundredPercentOfBalance = getNumTicketsByPercentage(100)

  const onTicketsAmountChange = (e: any) => {
    const { value } = e.currentTarget
    setTicketsToBuy(value)
  }

  const onFocus = (e: any) => {
    e.target.select()
  }

  const handleNumberButtonClick = (number: number) => {
    setTicketsToBuy(number.toFixed())
    setUserNotEnoughToken(false)
    setMaxTicketPurchaseExceeded(false)
  }

  const onConfirm = () => {
    const ticketsForPurchase = getTicketsForPurchase()
    console.log(ticketsForPurchase)
  }

  if (buyingStage === BuyingStage.EDIT) {
    return (
      <EditNumbersModal
        show={show}
        tickets={tickets}
        allComplete={allComplete}
        updateTicket={updateTicket}
        onRandomize={randomize}
        onConfirm={onConfirm}
        onDismiss={() => setBuyingStage(BuyingStage.BUY)}
      />
    )
  }

  return (
    <Modal show={show} onHide={onHide} title="Buy tickets">
      <div className="d-flex justify-content-between">
        <p>Buy Tickets</p>
        <span className="text-muted">HPL Balance: {hplBalance}</span>
      </div>
      <StyledInputGroup>
        <BuyTicketsInput
          ref={ref}
          type="number"
          min="1"
          max="100"
          value={ticketsToBuy}
          onChange={onTicketsAmountChange}
          onFocus={onFocus}
        />
        <span>tickets</span>
      </StyledInputGroup>
      {account && (
        <ShortcutButtonsWrapper isVisible={oneHundredPercentOfBalance >= 1} className="mt-3">
          {tenPercentOfBalance >= 1 && (
            <Button color="secondary" onClick={() => handleNumberButtonClick(tenPercentOfBalance)}>
              {tenPercentOfBalance.toString()}
            </Button>
          )}
          {twentyFivePercentOfBalance >= 1 && (
            <Button color="secondary" onClick={() => handleNumberButtonClick(twentyFivePercentOfBalance)}>
              {twentyFivePercentOfBalance.toString()}
            </Button>
          )}
          {fiftyPercentOfBalance >= 1 && (
            <Button color="secondary" onClick={() => handleNumberButtonClick(fiftyPercentOfBalance)}>
              {fiftyPercentOfBalance.toString()}
            </Button>
          )}
          {oneHundredPercentOfBalance >= 1 && (
            <Button color="secondary" onClick={() => handleNumberButtonClick(oneHundredPercentOfBalance)}>
              MAX
            </Button>
          )}
        </ShortcutButtonsWrapper>
      )}
      <div className="mt-3 mb-5 d-flex justify-content-between">
        <span className="text-muted">Total Cost:</span>
        <strong>0 HPL</strong>
      </div>
      <Buttons>
        {/* <Button>Enable</Button> */}
        <Button disabled={disableBuying}>Buy Instantly</Button>
        <Button disabled={disableBuying} color="secondary" onClick={() => setBuyingStage(BuyingStage.EDIT)}>
          View / Edit Numbers
        </Button>
      </Buttons>
    </Modal>
  )
}

export default BuyTicketsModal
