interface IPreviousRoundProps {
  roundId: number
}

function PreviousRoundTicketsInner(props: IPreviousRoundProps): JSX.Element {
  const { roundId } = props

  return (
    <div className="d-flex flex-column">
      <p className="text-uppercase">Your tickets</p>
    </div>
  )
}

export default PreviousRoundTicketsInner
