import Web3 from 'web3'
import { ethers } from 'ethers'
import * as crypto from 'crypto'
import { AbiItem, hexToAscii } from 'web3-utils'
import { Contract } from 'web3-eth-contract'
import BigNumber from 'bignumber.js'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const bytes32toString = bytes32 => {
  return ethers.utils.parseBytes32String(bytes32)
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const hexToString = hex => {
  const w3 = new Web3()
  return w3.utils.hexToAscii(hex)
}

export const getContract = (address: string, abi: AbiItem, web3: Web3): Contract | null => {
  try {
    // @ts-ignore
    return new web3.eth.Contract(abi, address)
  } catch (error: any) {
    console.error('Failed to get contract', error)
    return null
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const decrypt = text => {
  const algorithm = 'aes-128-cbc'
  // @ts-ignore
  const key = new Buffer.from('64a30c341e8f5f02ebb06aa8aaeb8a60', 'hex')
  // @ts-ignore
  const iv = new Buffer.from('4f61820b3e4e9e241fcbd22a97b9462f', 'hex')

  const decipher = crypto.createDecipheriv(algorithm, key, iv)
  // @ts-ignore
  let dec = decipher.update(text, 'hex', 'utf-8')
  // @ts-ignore
  dec += decipher.final()
  return dec
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getLandName = (commitment: string) => {
  let code
  try {
    code = bytes32toString(commitment)
  } catch (error: any) {
    console.error('Failed to get contract', error)
    code = hexToString(commitment)
  }

  return decrypt(code)
}

export const toWei = (number: string | number, decimals?: number): BigNumber => {
  const _decimals = decimals ?? 18
  return new BigNumber(number).multipliedBy(new BigNumber(10 ** _decimals))
}

export const fromWei = (number: string | number, decimals?: number): BigNumber => {
  const _decimals = decimals ?? 18
  return new BigNumber(number).div(new BigNumber(10 ** _decimals))
}

export const formatNumber = (number: number | string): string => {
  const seps = number.toString().split('.')
  seps[0] = seps[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  return seps.join('.')
}

export const isInt = (n: number): boolean => {
  return n % 1 === 0
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const decodeNFTFeatures = (features: any): { name: string; value: string }[] => {
  const names = features[0]
  const values = features[1]
  const featureDetails: { name: string; value: string }[] = []

  for (let i = 0; i < names.length; i++) {
    if (featureDetails.findIndex(f => f.name === hexToAscii(names[i])) < 0) {
      featureDetails.push({
        name: hexToAscii(names[i]),
        value: hexToAscii(values[i]),
      })
    }
  }

  return featureDetails
}

/**
 * Remove the '1' and reverse the digits in a lottery number retrieved from the smart contract
 */
export const parseRetrievedNumber = (number: string): string => {
  const numberAsArray = number.split('')
  numberAsArray.splice(0, 1)
  numberAsArray.reverse()
  return numberAsArray.join('')
}
