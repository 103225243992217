import { useState, useRef, useEffect } from 'react'
import axios, { AxiosError } from 'axios'
import RangeSlider from 'react-bootstrap-range-slider'
import { Zoom, toast } from 'react-toastify'
import ToastMessage from '../ToastMessage'
import Button from '../Button'
import Modal from '../Modal'
import Land from '../../types/Land'
import { fromWei, toWei } from '../../utils'
import { useActiveWeb3React, useGameControlContract, useMarketPlaceContract, useNFTContract } from '../../hooks'
import config from '../../config/config.json'
import {
  LandImage,
  LandID,
  LandName,
  AttributeTitle,
  ButtonFull,
  Input,
  InvalidText,
  DepositInputGroup,
  DepositInputInfo,
  StyledInputGroup,
  FormSelect,
  ListViewNFT,
  ListViewImg,
  ListViewContent,
  ListViewDescription,
  ListViewDescriptionItem,
  Ribbon,
  RibbonDanger,
  RibbonLock,
  LandPrice,
  ListViewFooter,
  LandOwner,
  LandPriceExchange,
  LandPriceWrapper,
  RibbonRent,
  RightRibbonContainer,
  TimeForRent,
} from './Styled'
import BigNumber from 'bignumber.js'
import { LockDurationRange } from '../../pages/Stake/Styled'

interface ILandProps {
  land: Land
  layout: string
  page: 'marketplace' | 'inventory' | 'playtoearn' | 'rent'
  rateBNB?: number
  rateHPL?: number
  rateHPW?: number
  isApproving?: boolean
  needApprove?: boolean
  onApprove?: () => void
  onRefresh: () => void
}

function LandListView(props: ILandProps): JSX.Element {
  const { land, layout, rateBNB, rateHPL, rateHPW, isApproving, needApprove, page, onApprove, onRefresh } = props
  // @ts-ignore
  const imagesDir = require.context(`../../assets/images/`, true)
  const sellerEllipsis = `${land.seller?.substring(0, 6)}...${land.seller?.substring(land.seller?.length - 4)}`

  const refSell = useRef()
  const refRent = useRef()
  const refChangePrice = useRef()
  const refGift = useRef()

  const [showSellModal, setShowSellModal] = useState(false)
  const [showRentModal, setShowRentModal] = useState(false)
  const [showEditPriceModal, setShowEditPriceModal] = useState(false)
  const [showGiftModal, setShowGiftModal] = useState(false)
  const [isSelling, setSelling] = useState(false)
  const [isRenting, setRenting] = useState(false)
  const [isEditing, setEditing] = useState(false)
  const [isGifting, setGifting] = useState(false)
  const [isWithrawing, setWithdrawing] = useState(false)
  const [isCanceling, setCanceling] = useState(false)
  const [price, setPrice] = useState(0)
  const [rentPrice, setRentPrice] = useState(0)
  const [editPrice, setEditPrice] = useState<any>(0)
  const [receiverAddress, setReceiverAddress] = useState('')
  const [isAddressValid, setAddressValid] = useState(false)
  const [lockedDuration, setLockedDuration] = useState<any>(2)

  const { account, chainId } = useActiveWeb3React()
  const networkId = chainId ?? Number(process.env.REACT_APP_CHAIN_ID)
  const apiURL = config.api[networkId]
  const [paymentToken, setPaymentToken] = useState(config.payments[networkId].BUSD)
  const [rentToken, setRentToken] = useState(config.payments[networkId].HPL)
  const marketplaceContract = useMarketPlaceContract(config.contracts[networkId].Market)
  const gameContract = useGameControlContract(config.contracts[networkId].GameControl)
  const nftContract = useNFTContract(config.contracts[networkId].LandNFT)

  const onPriceInputChange = e => {
    const { value } = e.currentTarget
    setPrice(Number(value))
  }

  const onRentPriceInputChange = e => {
    const { value } = e.currentTarget
    setRentPrice(Number(value))
  }

  const onEditPriceInputChange = e => {
    const { value } = e.currentTarget
    setEditPrice(Number(value))
  }

  const onReceiverInputChange = e => {
    const { value } = e.currentTarget
    const addressRegex = new RegExp(/^0x[a-fA-F0-9]{40}$/g)
    const match = addressRegex.test(value)

    if (value !== account) {
      setReceiverAddress(value)
      setAddressValid(match)
    }
  }

  const onSellLand = async () => {
    try {
      setSelling(true)
      if (marketplaceContract) {
        const receipt = await marketplaceContract.methods
          .setTokenSale(
            land.tokenAddress,
            land.tokenId,
            paymentToken,
            toWei(price).toString(10),
            '0x0000000000000000000000000000000000000000',
          )
          .send({ from: account })

        if (receipt) {
          toast.success(
            <ToastMessage
              color="success"
              bodyText={`${land.land} #${land.tokenId} has been listed on the market`}
              link={`${config.explorerURL[networkId]}/tx/${receipt.transactionHash}`}
              linkText="View Transaction"
            />,
            {
              toastId: 'onSellLand',
              position: 'bottom-right',
              autoClose: 5000,
              hideProgressBar: true,
              transition: Zoom,
            },
          )

          setTimeout(() => {
            onRefresh()
          }, 500)
        }
        setShowSellModal(false)
      }
      setShowSellModal(false)
    } catch (error: any) {
      if (error?.code !== 4001) {
        toast.error(<ToastMessage color="error" bodyText="Could not sell this land. Please try again." />, {
          toastId: 'onSellLand',
          position: 'bottom-right',
          autoClose: 5000,
          hideProgressBar: true,
          transition: Zoom,
        })
      }
      console.error(error)
    } finally {
      setSelling(false)
    }
  }

  const onRentLand = async () => {
    try {
      setRenting(true)
      if (gameContract) {
        const _rentPrice = new BigNumber(rentPrice).multipliedBy(1e18).toString()
        const _lockedDuration = Number(lockedDuration * 604800)
        const response = await axios.post(`${apiURL}/rents/requestSetRent`, {
          owner: account,
          nftAddress: config.contracts[networkId].LandNFT,
          tokenId: land.tokenId,
          tokenAddress: rentToken,
          duration: _lockedDuration,
          tokenAmount: _rentPrice,
        })
        if (response.status === 200 && response.data) {
          const { nft, tokenId, duration, tokenAddress, tokenAmount, expiredTime, r, s, v } = response.data
          const receipt = await gameContract.methods
            .setRent(nft, tokenId, duration, tokenAddress, tokenAmount, expiredTime, r, s, v)
            .send({ from: account })

          if (receipt) {
            toast.success(
              <ToastMessage
                color="success"
                bodyText={`${land.land} #${land.tokenId} has been listed on the rent market`}
                link={`${config.explorerURL[networkId]}/tx/${receipt.transactionHash}`}
                linkText="View Transaction"
              />,
              {
                toastId: 'onRentLand',
                position: 'bottom-right',
                autoClose: 5000,
                hideProgressBar: true,
                transition: Zoom,
              },
            )

            setTimeout(() => {
              onRefresh()
            }, 500)
          }
        }
        setShowSellModal(false)
      }
      setShowSellModal(false)
    } catch (error: any) {
      if (error?.code !== 4001) {
        toast.error(<ToastMessage color="error" bodyText="Could not set rent this land. Please try again." />, {
          toastId: 'onRentLand',
          position: 'bottom-right',
          autoClose: 5000,
          hideProgressBar: true,
          transition: Zoom,
        })
      }
      console.error(error)
    } finally {
      setRenting(false)
    }
  }

  const onKeyUpSellLand = e => {
    if (e.keyCode === 13) {
      onSellLand()
    }
  }

  const onOpenChangePrice = priceChange => {
    const _priceChange = new BigNumber(priceChange).dividedBy(1e18).toString()
    setEditPrice(_priceChange)
    setShowEditPriceModal(true)
  }

  const onEditPrice = async () => {
    try {
      setEditing(true)
      if (marketplaceContract) {
        const receipt = await marketplaceContract.methods
          .updateSaleInfo(
            land.tokenAddress,
            land.saleId,
            toWei(editPrice).toString(10),
            '0x0000000000000000000000000000000000000000',
          )
          .send({ from: account })

        if (receipt) {
          toast.success(
            <ToastMessage
              color="success"
              bodyText={`${land.land} #${land.tokenId} has been listed on the market`}
              link={`${config.explorerURL[networkId]}/tx/${receipt.transactionHash}`}
              linkText="View Transaction"
            />,
            {
              toastId: 'onSellLand',
              position: 'bottom-right',
              autoClose: 5000,
              hideProgressBar: true,
              transition: Zoom,
            },
          )

          setTimeout(() => {
            onRefresh()
          }, 500)
        }
        setShowEditPriceModal(false)
      }
      setShowEditPriceModal(false)
    } catch (error: any) {
      if (error?.code !== 4001) {
        toast.error(<ToastMessage color="error" bodyText="Could not sell this land. Please try again." />, {
          toastId: 'onSellLand',
          position: 'bottom-right',
          autoClose: 5000,
          hideProgressBar: true,
          transition: Zoom,
        })
      }
      console.error(error)
    } finally {
      setEditing(false)
    }
  }

  const onKeyUpEditPrice = e => {
    if (e.keyCode === 13) {
      onEditPrice()
    }
  }

  const onCancelSelling = async () => {
    try {
      setCanceling(true)
      if (marketplaceContract) {
        const receipt = await marketplaceContract.methods
          .cancelTokenSale(land.tokenAddress, land.saleId)
          .send({ from: account })

        if (receipt) {
          toast.success(
            <ToastMessage
              color="success"
              bodyText={`${land.land} #${land.tokenId} has been canceled for sale.`}
              link={`${config.explorerURL[networkId]}/tx/${receipt.transactionHash}`}
              linkText="View Transaction"
            />,
            {
              toastId: 'onCancelSelling',
              position: 'bottom-right',
              autoClose: 5000,
              hideProgressBar: true,
              transition: Zoom,
            },
          )

          setTimeout(() => {
            onRefresh()
          }, 500)
        }
      }
    } catch (error: any) {
      if (error?.code !== 4001) {
        toast.error(<ToastMessage color="error" bodyText="Could not cancel sale of this land. Please try again." />, {
          toastId: 'onCancelSelling',
          position: 'bottom-right',
          autoClose: 5000,
          hideProgressBar: true,
          transition: Zoom,
        })
      }
      console.error(error)
    } finally {
      setCanceling(true)
    }
  }

  const onCancelRenting = async () => {
    try {
      setRenting(true)
      if (gameContract) {
        const receipt = await gameContract.methods.cancelRent(land.rentId).send({ from: account })

        if (receipt) {
          toast.success(
            <ToastMessage
              color="success"
              bodyText={`${land.land} #${land.tokenId} has been canceled for rent.`}
              link={`${config.explorerURL[networkId]}/tx/${receipt.transactionHash}`}
              linkText="View Transaction"
            />,
            {
              toastId: 'onCancelRenting',
              position: 'bottom-right',
              autoClose: 5000,
              hideProgressBar: true,
              transition: Zoom,
            },
          )

          setTimeout(() => {
            onRefresh()
          }, 500)
        }
      }
    } catch (error: any) {
      if (error?.code !== 4001) {
        toast.error(<ToastMessage color="error" bodyText="Could not cancel rent of this land. Please try again." />, {
          toastId: 'onCancelRenting',
          position: 'bottom-right',
          autoClose: 5000,
          hideProgressBar: true,
          transition: Zoom,
        })
      }
      console.error(error)
    } finally {
      setRenting(true)
    }
  }

  const onClaimRenting = async () => {
    try {
      setRenting(true)
      if (gameContract) {
        const receipt = await gameContract.methods.claimFinishRent(land.rentId).send({ from: account })

        if (receipt) {
          toast.success(
            <ToastMessage
              color="success"
              bodyText={`${land.land} #${land.tokenId} has been claimed.`}
              link={`${config.explorerURL[networkId]}/tx/${receipt.transactionHash}`}
              linkText="View Transaction"
            />,
            {
              toastId: 'onClaimRenting',
              position: 'bottom-right',
              autoClose: 5000,
              hideProgressBar: true,
              transition: Zoom,
            },
          )

          setTimeout(() => {
            onRefresh()
          }, 500)
        }
      }
    } catch (error: any) {
      if (error?.code !== 4001) {
        toast.error(<ToastMessage color="error" bodyText="Could not claim rent of this land. Please try again." />, {
          toastId: 'onClaimRenting',
          position: 'bottom-right',
          autoClose: 5000,
          hideProgressBar: true,
          transition: Zoom,
        })
      }
      console.error(error)
    } finally {
      setRenting(true)
    }
  }

  const onGiftLand = async () => {
    try {
      setGifting(true)
      if (nftContract) {
        const receipt = await nftContract.methods
          .safeTransferFrom(account, receiverAddress, land.tokenId)
          .send({ from: account })

        if (receipt) {
          toast.success(
            <ToastMessage
              color="success"
              bodyText={`${land.land} #${land.tokenId} has been transferred to your friend`}
              link={`${config.explorerURL[networkId]}/tx/${receipt.transactionHash}`}
              linkText="View Transaction"
            />,
            {
              toastId: 'onGiftLand',
              position: 'bottom-right',
              autoClose: 5000,
              hideProgressBar: true,
              transition: Zoom,
            },
          )

          setTimeout(() => {
            onRefresh()
          }, 500)
        }
      }
      setShowGiftModal(false)
    } catch (error: any) {
      if (error?.code !== 4001) {
        toast.error(<ToastMessage color="error" bodyText="Could not transfer this land. Please try again." />, {
          toastId: 'onGiftLand',
          position: 'bottom-right',
          autoClose: 5000,
          hideProgressBar: true,
          transition: Zoom,
        })
      }
      console.error(error)
    } finally {
      setGifting(false)
    }
  }

  const onWithdrawNFT = async () => {
    try {
      setWithdrawing(true)

      if (account && gameContract) {
        const response = await axios.post(`${apiURL}/games/requestWithdrawNFT/${land.tokenAddress}`, {
          player: account,
          tokenIds: String(land.tokenId),
        })

        if (response.status === 200 && response.data) {
          const { nft, tokenIds, expiredTime, r, s, v } = response.data
          const receipt = await gameContract.methods
            .withdrawNFTs(nft, tokenIds, expiredTime, r, s, v)
            .send({ from: account })

          if (receipt) {
            onRefresh()

            toast.success(
              <ToastMessage
                color="success"
                bodyText="Unlock NFT successfully"
                link={`${config.explorerURL[networkId]}/tx/${receipt.transactionHash}`}
                linkText="View Transaction"
              />,
              {
                toastId: 'onUnlockNFT',
                position: 'bottom-right',
                autoClose: 50000,
                hideProgressBar: true,
                transition: Zoom,
              },
            )
          }
        }
      }
    } catch (error: any) {
      if (axios.isAxiosError(error)) {
        const _e = error as AxiosError
        const msg = _e.response?.data.errors
        toast.error(<ToastMessage color="error" bodyText={msg ? msg.toUpperCase() : 'Could not unlock this NFT.'} />, {
          toastId: 'onUnlockNFT',
          position: 'bottom-right',
          autoClose: 5000,
          hideProgressBar: true,
          transition: Zoom,
        })
      } else {
        // we only care if the error is something _other_ than the user rejected the tx
        if (error?.code !== 4001) {
          toast.error(<ToastMessage color="error" bodyText="Could not unlock this NFT." />, {
            toastId: 'onUnlockNFT',
            position: 'bottom-right',
            autoClose: 5000,
            hideProgressBar: true,
            transition: Zoom,
          })
        }
        console.error(error)
      }
    } finally {
      setWithdrawing(false)
    }
  }

  const onKeyUpGiftLand = e => {
    if (e.keyCode === 13 && isAddressValid) {
      onGiftLand()
    }
  }

  const onPaymentTokenChange = e => {
    const { value } = e.currentTarget
    setPaymentToken(value)
  }

  const onRentTokenChange = e => {
    const { value } = e.currentTarget
    setRentToken(value)
  }

  const priceNFT = (priceInput: any) => {
    if (priceInput === undefined) return 0
    if (priceInput >= 0) {
      return fromWei(priceInput).toNumber()
    }
  }

  const tokenPaymentFn = tokenPayment => {
    switch (tokenPayment) {
      case config.payments[networkId].BNB:
        return 'BNB'
      case config.payments[networkId].HPL:
        return 'HPL'
      case config.payments[networkId].HPW:
        return 'HPW'
      case config.payments[networkId].BUSD:
        return 'BUSD'
      default:
        return null
    }
  }

  const timeRent = (duration: number) => {
    return duration / 604800
  }

  const _expiredRent = (duration: number | undefined, rentTime: number | undefined): boolean | undefined => {
    if (rentTime && duration) {
      const now = Math.round(+new Date() / 1000)
      const _sumTime = Number(duration) + Number(rentTime)
      return _sumTime > now
    }
  }

  useEffect(() => {
    if (refSell.current) {
      // @ts-ignore
      refSell.current.focus()
    }

    if (refGift.current) {
      // @ts-ignore
      refGift.current.focus()
    }
    if (refChangePrice.current) {
      // @ts-ignore
      refChangePrice.current.focus()
    }
  })

  // @ts-ignore
  return (
    <>
      <ListViewNFT layout={layout}>
        <ListViewImg layout={layout}>
          <LandImage
            className={land.locked || land.banned ? 'image-banned' : ''}
            src={imagesDir(`./lands/${land.land}.png`).default}
            alt={land.land}
          />
          <LandID theme={land.land}>{`#${land.tokenId}`}</LandID>
        </ListViewImg>
        <ListViewContent layout={layout}>
          {land.locked && <RibbonLock>This NFT has been locked</RibbonLock>}
          {land.banned && <RibbonDanger>This NFT has been banned</RibbonDanger>}
          <RightRibbonContainer>
            {page !== 'marketplace' &&
            (land.seller === account?.toLowerCase() ||
              (land.gameDepositor === account?.toLowerCase() && page !== 'rent')) ? (
              <Ribbon>{land.seller == account?.toLowerCase() ? 'In Order' : 'Synchronized'}</Ribbon>
            ) : null}
            {land.isWaitingRent && page !== 'rent' && <RibbonRent>For Rent</RibbonRent>}
            {land.isRent && (
              <RibbonRent>{land.renter === account?.toLowerCase() ? 'Renting' : 'Rented Out'}</RibbonRent>
            )}
          </RightRibbonContainer>
          <LandName theme={land.land}>{land.land}</LandName>
          <ListViewDescription layout={layout}>
            <ListViewDescriptionItem>
              <AttributeTitle>
                Area Size: <span>{land.areaSize}</span>
              </AttributeTitle>
            </ListViewDescriptionItem>
            <ListViewDescriptionItem>
              <AttributeTitle>
                Fish Ponds: <span>{land.maxFishPonds}</span>
              </AttributeTitle>
            </ListViewDescriptionItem>
            <ListViewDescriptionItem>
              <AttributeTitle>
                Plots: <span>{land.maxPlots}</span>
              </AttributeTitle>
            </ListViewDescriptionItem>
            <ListViewDescriptionItem>
              <AttributeTitle>
                Animal Barns: <span>{land.maxAnimalBarns}</span>
              </AttributeTitle>
            </ListViewDescriptionItem>
          </ListViewDescription>

          {page === 'marketplace' && (
            <ListViewFooter layout={layout}>
              <div>
                {land.price && (
                  <LandPriceWrapper>
                    <LandPrice>
                      {priceNFT(land.price)} {tokenPaymentFn(land.paymentToken?.toLowerCase())}
                    </LandPrice>
                    {land.paymentToken?.toLowerCase() === config.payments[networkId].HPL && (
                      //@ts-ignore
                      <LandPriceExchange>(~ ${Number(priceNFT(land.price) * rateHPL).toFixed(1)})</LandPriceExchange>
                    )}
                    {land.paymentToken?.toLowerCase() === config.payments[networkId].HPW && (
                      //@ts-ignore
                      <LandPriceExchange>(~ ${Number(priceNFT(land.price) * rateHPW).toFixed(1)})</LandPriceExchange>
                    )}
                    {land.paymentToken?.toLowerCase() === config.payments[networkId].BNB && (
                      //@ts-ignore
                      <LandPriceExchange>(~ ${Number(priceNFT(land.price) * rateBNB).toFixed(1)})</LandPriceExchange>
                    )}
                  </LandPriceWrapper>
                )}
                {land.seller && (
                  <LandOwner
                    href={`${config.explorerURL[networkId]}/address/${land.seller}`}
                    target="_blank"
                    rel="nofollow"
                    style={{ display: 'inline-block', marginBottom: '8px' }}
                  >
                    Farmer: {sellerEllipsis}
                  </LandOwner>
                )}
              </div>
              <div>
                <ButtonFull>
                  <Button color="primary" url={`/buy/${land.tokenId}`}>
                    <span>More Detail</span>
                  </Button>
                </ButtonFull>
              </div>
            </ListViewFooter>
          )}

          {page === 'rent' && (
            <ListViewFooter layout={layout}>
              <div>
                {land.paymentAmount && (
                  <LandPriceWrapper>
                    <LandPrice>
                      {priceNFT(land.paymentAmount)} {tokenPaymentFn(land.paymentToken?.toLowerCase())}
                    </LandPrice>
                    {land.paymentToken?.toLowerCase() === config.payments[networkId].HPL && (
                      //@ts-ignore
                      <LandPriceExchange>(~ ${(priceNFT(land.paymentAmount) * rateHPL).toFixed(1)})</LandPriceExchange>
                    )}
                    {land.paymentToken?.toLowerCase() === config.payments[networkId].HPW && (
                      //@ts-ignore
                      <LandPriceExchange>(~ ${(priceNFT(land.paymentAmount) * rateHPW).toFixed(1)})</LandPriceExchange>
                    )}
                    {land.paymentToken?.toLowerCase() === config.payments[networkId].BNB && (
                      //@ts-ignore
                      <LandPriceExchange>(~ ${(priceNFT(land.paymentAmount) * rateBNB).toFixed(1)})</LandPriceExchange>
                    )}
                  </LandPriceWrapper>
                )}
                <TimeForRent>
                  Time for rent:{' '}
                  {land.duration && (
                    <span>
                      {timeRent(Number(land.duration))} {land.duration > 604800 ? 'weeks' : 'week'}
                    </span>
                  )}
                </TimeForRent>
                {land.gameDepositor && (
                  <LandOwner
                    href={`${config.explorerURL[networkId]}/address/${land.gameDepositor}`}
                    target="_blank"
                    rel="nofollow"
                    style={{ display: 'inline-block', marginBottom: '8px' }}
                  >
                    Renter:{' '}
                    {`${land.gameDepositor?.substring(0, 6)}...
                    ${land.gameDepositor?.substring(land.gameDepositor?.length - 4)}`}
                  </LandOwner>
                )}
              </div>
              {land.gameDepositor === account?.toLowerCase() ? (
                <ButtonFull>
                  <Button color="secondary" onClick={onCancelRenting} disabled={isRenting} loading={isRenting}>
                    Cancel Renting
                  </Button>
                </ButtonFull>
              ) : (
                <ButtonFull>
                  <Button color="primary" url={`/rent/${land.tokenId}`}>
                    <span>More Detail</span>
                  </Button>
                </ButtonFull>
              )}
            </ListViewFooter>
          )}

          {page === 'playtoearn' && (
            <>
              {needApprove ? (
                <ButtonFull>
                  <Button color="primary" disabled={isApproving} loading={isApproving} onClick={onApprove}>
                    <span>Approve</span>
                  </Button>
                </ButtonFull>
              ) : (
                <ButtonFull>
                  {!land.isWaitingRent && !land.isRent && (
                    <Button disabled={isWithrawing} loading={isWithrawing} onClick={onWithdrawNFT}>
                      Unlock
                    </Button>
                  )}
                  {land.isWaitingRent && (
                    <Button color="secondary" onClick={onCancelRenting} disabled={isRenting} loading={isRenting}>
                      Cancel Renting
                    </Button>
                  )}
                  {land.isRent && land.renter !== account?.toLowerCase() && (
                    <Button
                      color="secondary"
                      onClick={onClaimRenting}
                      disabled={_expiredRent(land?.duration, land?.rentTime)}
                      loading={isRenting}
                    >
                      Claim Land
                    </Button>
                  )}
                </ButtonFull>
              )}
            </>
          )}

          {page === 'inventory' && (
            <>
              {needApprove ? (
                <ButtonFull>
                  <Button color="primary" disabled={isApproving} loading={isApproving} onClick={onApprove}>
                    <span>Approve</span>
                  </Button>
                </ButtonFull>
              ) : (
                <>
                  {land.isRent && (
                    <ButtonFull className="mb-42">
                      <Button
                        color="secondary"
                        onClick={onClaimRenting}
                        disabled={_expiredRent(land?.duration, land?.rentTime)}
                        loading={isRenting}
                      >
                        Claim Land
                      </Button>
                    </ButtonFull>
                  )}
                  {land.isWaitingRent && (
                    <ButtonFull className="mb-42">
                      <Button color="secondary" onClick={onCancelRenting} disabled={isRenting} loading={isRenting}>
                        Cancel Renting
                      </Button>
                    </ButtonFull>
                  )}
                  {land.gameDepositor === account?.toLowerCase() && !land.isRent && (
                    <ButtonFull className="mb-42">
                      {!land.isWaitingRent && (
                        <Button
                          color="primary"
                          onClick={() => setShowRentModal(true)}
                          disabled={isSelling}
                          loading={isSelling}
                        >
                          Rent
                        </Button>
                      )}
                    </ButtonFull>
                  )}
                  {land.seller === account?.toLowerCase() && config.market && (
                    <ButtonFull>
                      <Button
                        color="primary"
                        onClick={() => onOpenChangePrice(land.price)}
                        disabled={isEditing}
                        loading={isEditing}
                      >
                        Change Price
                      </Button>
                      <Button color="secondary" onClick={onCancelSelling} disabled={isCanceling} loading={isCanceling}>
                        Cancel Selling
                      </Button>
                    </ButtonFull>
                  )}
                  {!land.gameDepositor && land.seller !== account?.toLowerCase() && config.market && (
                    <>
                      <ButtonFull>
                        <Button
                          color="primary"
                          onClick={() => setShowSellModal(true)}
                          disabled={isSelling}
                          loading={isSelling}
                        >
                          Sell
                        </Button>
                        <Button
                          color="secondary"
                          onClick={() => setShowGiftModal(true)}
                          disabled={isGifting}
                          loading={isGifting}
                        >
                          Transfer
                        </Button>
                      </ButtonFull>
                    </>
                  )}
                </>
              )}
            </>
          )}
        </ListViewContent>
      </ListViewNFT>
      {page === 'inventory' && (
        <>
          {/* Modal Transfer NFTs */}
          <Modal
            show={showGiftModal}
            onHide={() => setShowGiftModal(false)}
            title={
              <>
                Transfer {land.land} <span>#{land.tokenId}</span>
              </>
            }
          >
            <p className="mt-3">Enter receiver's BSC wallet address to continue:</p>
            <Input
              ref={refGift}
              placeholder="Receiver's BSC wallet address"
              onChange={e => onReceiverInputChange(e)}
              onKeyUp={e => onKeyUpGiftLand(e)}
            />
            {!isAddressValid && <InvalidText>Invalid address</InvalidText>}
            <ButtonFull className="mt-4">
              <Button color="primary" onClick={onGiftLand} disabled={isGifting} loading={isGifting}>
                Transfer Your Land
              </Button>
            </ButtonFull>
          </Modal>
          {/* Modal Sell NFTs */}
          <Modal
            show={showSellModal}
            onHide={() => setShowSellModal(false)}
            title={
              <>
                Sell {land.land} <span>#{land.tokenId}</span>
              </>
            }
          >
            <p>
              Your land will be listed on the market with this price.
              <br />
              In order to get it back, you'll have to cancel the sale.
            </p>
            <DepositInputGroup>
              <DepositInputInfo>
                <div className="d-flex justify-content-between w-100">
                  <h6>Input</h6>
                </div>
              </DepositInputInfo>
              <StyledInputGroup>
                <Input
                  ref={refSell}
                  type="number"
                  placeholder="0"
                  onChange={e => onPriceInputChange(e)}
                  onKeyUp={e => onKeyUpSellLand(e)}
                  borderwidth="0"
                  paddinginput="0"
                />
                <FormSelect aria-label="Token Payment" value={paymentToken} onChange={e => onPaymentTokenChange(e)}>
                  <option value={config.payments[networkId].BUSD}>BUSD</option>
                  <option value={config.payments[networkId].HPL}>HPL</option>
                  <option value={config.payments[networkId].BNB}>BNB</option>
                  <option value={config.payments[networkId].HPW}>HPW</option>
                </FormSelect>
              </StyledInputGroup>
            </DepositInputGroup>
            <ButtonFull className="mt-4">
              <Button color="primary" onClick={onSellLand} disabled={isSelling} loading={isSelling}>
                Sell
              </Button>
            </ButtonFull>
          </Modal>
          {/* Modal Rent NFTs */}
          <Modal
            show={showRentModal}
            onHide={() => setShowRentModal(false)}
            title={
              <>
                Rent {land.land} <span>#{land.tokenId}</span>
              </>
            }
          >
            <div className="mb-4">
              <LockDurationRange>
                <span>
                  Rent for: <span style={{ color: '#D75212', textTransform: 'uppercase' }}>{lockedDuration} weeks</span>
                </span>
              </LockDurationRange>
              <RangeSlider
                value={lockedDuration}
                min={2}
                max={52}
                onChange={e => setLockedDuration(e.target.value)}
                size="lg"
                tooltip="off"
              />
              <LockDurationRange>
                <span>2</span>
                <span>52</span>
              </LockDurationRange>
            </div>
            <DepositInputGroup>
              <DepositInputInfo>
                <div className="d-flex justify-content-between w-100">
                  <h6>Cost</h6>
                </div>
              </DepositInputInfo>
              <StyledInputGroup>
                <Input
                  ref={refRent}
                  type="number"
                  placeholder="0"
                  onChange={e => onRentPriceInputChange(e)}
                  borderwidth="0"
                  paddinginput="0"
                />
                <FormSelect aria-label="Rent Payment" value={rentToken} onChange={e => onRentTokenChange(e)}>
                  <option value={config.payments[networkId].BUSD}>BUSD</option>
                  <option value={config.payments[networkId].HPL}>HPL</option>
                  <option value={config.payments[networkId].BNB}>BNB</option>
                  <option value={config.payments[networkId].HPW}>HPW</option>
                </FormSelect>
              </StyledInputGroup>
            </DepositInputGroup>
            <ButtonFull className="mt-4">
              <Button color="primary" onClick={onRentLand} disabled={isRenting} loading={isRenting}>
                Rent
              </Button>
            </ButtonFull>
          </Modal>
          {/* Modal Change Price NFTs */}
          <Modal
            show={showEditPriceModal}
            onHide={() => setShowEditPriceModal(false)}
            title={
              <>
                Change price {land.land?.toUpperCase()} <span>#{land.tokenId}</span>
              </>
            }
          >
            <DepositInputGroup>
              <DepositInputInfo>
                <div className="d-flex justify-content-between w-100">
                  <h6>Input</h6>
                </div>
              </DepositInputInfo>
              <StyledInputGroup>
                <Input
                  ref={refChangePrice}
                  type="number"
                  placeholder="0"
                  value={editPrice}
                  onChange={e => onEditPriceInputChange(e)}
                  onKeyUp={e => onKeyUpEditPrice(e)}
                  borderwidth="0"
                  paddinginput="0"
                />
                <FormSelect
                  aria-label="Token Payment"
                  value={land.paymentToken}
                  disabled
                  onChange={e => onPaymentTokenChange(e)}
                >
                  <option value={config.payments[networkId].BUSD}>BUSD</option>
                  <option value={config.payments[networkId].HPL}>HPL</option>
                  <option value={config.payments[networkId].BNB}>BNB</option>
                  <option value={config.payments[networkId].HPW}>HPW</option>
                </FormSelect>
              </StyledInputGroup>
            </DepositInputGroup>
            <ButtonFull className="mt-4">
              <Button color="primary" onClick={onEditPrice} disabled={isEditing} loading={isEditing}>
                Change Price
              </Button>
            </ButtonFull>
          </Modal>
        </>
      )}
    </>
  )
}

export default LandListView
