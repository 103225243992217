import { Modal as BootstrapModal } from 'react-bootstrap'
import styled from 'styled-components/macro'

const StyledModal = styled(BootstrapModal)`
  .modal-content {
    background: #110f22;
    min-height: 500px;
    border-radius: 10px;
    border: 1px solid #000;
  }

  .modal-header {
    justify-content: center;
    align-items: center;
    border-bottom: none;
    padding: 30px 20px 0 20px;
    .modal-title {
      font-size: 24px;
    }
    .btn-close {
      position: absolute;
      top: -50px;
      right: 35px;
    }
  }

  .modal-body {
    border-radius: 0;
    padding: 1rem 1rem 4rem;
    @media (min-width: 576px) {
      padding: 1rem 1.5rem 5rem;
    }
  }

  .modal-footer {
    border-top: 0;
  }
`

const ModalTitle = styled(BootstrapModal.Title)`
  color: #fff;

  span {
    color: ${props => props.theme.color.orange[300]};
  }
`

interface IModalProps {
  size?: string
  className?: any
  show: boolean
  hideTitle?: boolean
  title: string | JSX.Element | JSX.Element[]
  children?: any
  button?: JSX.Element | JSX.Element[]
  onHide: () => void
  backdrop?: any
}

function Modal(props: IModalProps): JSX.Element {
  const { size, className, show, hideTitle, title, children, button, onHide, backdrop } = props

  return (
    <StyledModal className={className} autoFocus centered size={size} show={show} onHide={onHide} backdrop={backdrop}>
      <BootstrapModal.Header closeButton>
        <ModalTitle className={hideTitle && 'screen-reader-text'}>{title}</ModalTitle>
      </BootstrapModal.Header>
      <BootstrapModal.Body>{children}</BootstrapModal.Body>
      {button && <BootstrapModal.Footer>{button}</BootstrapModal.Footer>}
    </StyledModal>
  )
}

export default Modal
