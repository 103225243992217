import { useState } from 'react'
// import JSEncrypt from 'jsencrypt'
import Web3 from 'web3'
import axios, { AxiosError } from 'axios'
import { Form, FloatingLabel } from 'react-bootstrap'
import PasswordStrengthBar from 'react-password-strength-bar'
import { Zoom, toast } from 'react-toastify'
import ToastMessage from '../../components/ToastMessage'
import Button from '../../components/Button'
import { useActiveWeb3React } from '../../hooks'
import { FormInput, ButtonWrapper } from './Styled'
import config from '../../config/config.json'

interface IChangePasswordFormProps {
  username: string
  location: string
  children?: string | JSX.Element | JSX.Element[]
}

function ChangePasswordForm(props: IChangePasswordFormProps): JSX.Element {
  const { username, location, children } = props
  const { account, chainId, library } = useActiveWeb3React()
  const web3 = new Web3(library)
  const networkId = chainId ?? Number(process.env.REACT_APP_CHAIN_ID)
  const apiURL = config.api[networkId]

  const [isRegistering, setRegistering] = useState(false)
  const [password, setPassword] = useState('')
  const [passwordScore, setPasswordScore] = useState(1)
  const [isPasswordMatched, setPasswordMatched] = useState(false)

  const onPasswordChange = e => {
    const { value } = e.currentTarget
    setPassword(value)
  }

  const onConfirmPasswordChange = e => {
    const { value } = e.currentTarget
    setPasswordMatched(value === password)
  }

  const onChangeScore = score => {
    setPasswordScore(score)
  }

  const onChangePassword = async () => {
    try {
      setRegistering(true)

      if (account) {
        // const encrypt = new JSEncrypt()
        // encrypt.setPublicKey(config.public_key)
        // const hashedPassword = encrypt.encrypt(password)
        const hashedPassword = password
        const expiry = (Date.now() / 1000 + 120).toFixed(0)

        if (hashedPassword) {
          const hashedMsg = web3.utils.sha3(`${username}-${location}-${expiry}-${account.toLowerCase()}`)

          if (hashedMsg) {
            const signature = await web3.eth.personal.sign(hashedMsg, account, hashedPassword)

            const response = await axios.post(`${apiURL}/users/updatePassword`, {
              username,
              password: hashedPassword,
              location,
              expiry,
              player: account.toLowerCase(),
              signature,
            })

            if (response.status === 200 && response.data) {
              toast.success(<ToastMessage color="success" bodyText="Update password success." />, {
                toastId: 'onChangePassword',
                position: 'bottom-right',
                autoClose: 5000,
                hideProgressBar: true,
                transition: Zoom,
              })
            }
          } else {
            toast.error(<ToastMessage color="error" bodyText="Could not update password." />, {
              toastId: 'onChangePassword',
              position: 'bottom-right',
              autoClose: 5000,
              hideProgressBar: true,
              transition: Zoom,
            })
            throw new Error('Encrypt message failed')
          }
        } else {
          toast.error(<ToastMessage color="error" bodyText="Could not update password." />, {
            toastId: 'onChangePassword',
            position: 'bottom-right',
            autoClose: 5000,
            hideProgressBar: true,
          })
          throw new Error('Encrypt password failed')
        }
      }
    } catch (error: any) {
      if (axios.isAxiosError(error)) {
        const _e = error as AxiosError
        const msg = _e.response?.data.errors
        toast.error(<ToastMessage color="error" bodyText={msg ? msg.toUpperCase() : 'Could not update.'} />, {
          toastId: 'onChangePassword',
          position: 'bottom-right',
          autoClose: 5000,
          hideProgressBar: true,
          transition: Zoom,
        })
      }
      console.error(error)
    } finally {
      setRegistering(false)
    }
  }

  return (
    <>
      <Form>
        <Form.Group className="mb-3" controlId="formEmail">
          <FloatingLabel controlId="floatingFormEmail" label="Username" className="mb-3">
            <FormInput readOnly type="text" value={username} />
          </FloatingLabel>
        </Form.Group>
        <Form.Group className="mb-3" controlId="formPassword">
          <FloatingLabel controlId="floatingFormPassword" label="New Password">
            <FormInput
              type="password"
              className="mb-3"
              placeholder="Password"
              value={password}
              onChange={e => onPasswordChange(e)}
            />
          </FloatingLabel>
          <PasswordStrengthBar
            password={password}
            minLength={6}
            shortScoreWord="The password field must be at least 6 characters"
            onChangeScore={onChangeScore}
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="formPasswordConfirm">
          <FloatingLabel controlId="floatingFormPasswordConfirm" label="Confirm New Password">
            <FormInput type="password" placeholder="Confirm Password" onChange={e => onConfirmPasswordChange(e)} />
            {!isPasswordMatched && (
              <Form.Text className="d-block mt-2" muted>
                Passwords do not match
              </Form.Text>
            )}
          </FloatingLabel>
        </Form.Group>
        <ButtonWrapper>
          <Button
            disabled={isRegistering || passwordScore < 2 || !isPasswordMatched}
            loading={isRegistering}
            onClick={onChangePassword}
          >
            Change Password
          </Button>
          {children}
        </ButtonWrapper>
      </Form>
    </>
  )
}

export default ChangePasswordForm
