import { useState } from 'react'
import Button from 'components/Button'
import Modal from 'components/Modal'
import { FiArrowLeft } from 'react-icons/fi'
import { Buttons, ScrollWrapper } from '../../Styled'
import { UpdateTicketAction, Ticket } from './useTicketsReducer'
import TicketInput from './TicketInput'

interface IEditNumbersModal {
  show: boolean
  tickets: Ticket[]
  allComplete: boolean
  updateTicket: UpdateTicketAction
  onRandomize: () => void
  onDismiss: () => void
  onConfirm: () => void
}

function EditNumbersModal(props: IEditNumbersModal): JSX.Element {
  const { show, tickets, allComplete, updateTicket, onRandomize, onDismiss, onConfirm } = props

  const [isConfirming, setIsConfirming] = useState(false)

  return (
    <Modal show={show} onHide={onDismiss} title="Edit Numbers">
      <p className="text-small text-muted">
        Numbers are randomized, with no duplicates among your tickets. Tap a number to edit it. Available digits: 0-9
      </p>
      <Buttons>
        <Button color="secondary" disabled={isConfirming} onClick={onRandomize}>
          Randomize
        </Button>
      </Buttons>
      <ScrollWrapper className="mt-4 mb-4">
        {tickets.map(ticket => (
          <TicketInput
            key={ticket.id}
            ticket={ticket}
            duplicateWith={ticket.duplicateWith}
            updateTicket={updateTicket}
            disabled={isConfirming}
          />
        ))}
      </ScrollWrapper>
      <Buttons>
        <Button disabled={!allComplete || isConfirming} loading={isConfirming} onClick={onConfirm}>
          Confirm and buy
        </Button>
        <a href="#" onClick={onDismiss}>
          <FiArrowLeft />
          <span>Go back</span>
        </a>
      </Buttons>
    </Modal>
  )
}

export default EditNumbersModal
