import { useState } from 'react'
import styled, { css } from 'styled-components/macro'
import WalletBg from '../../assets/images/wallet.svg'
import ConnectModal from './ConnectModal'
import { useActiveWeb3React } from '../../hooks'
import { Link } from 'react-router-dom'

const ButtonAccountStyle = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: url(${WalletBg}) no-repeat center center;
  background-size: 100%;
  width: 140px;
  height: 42px;
  margin-right: 15px;
  margin-bottom: 5px;
  color: #fff;
  font-family: 'SVN-SAF', sans-serif;
  font-size: 12px;
  line-height: 1;
  border: none;
  @media (min-width: 1024px) {
    margin-right: 60px;
  }
`

const AccountLink = styled(Link)`
  ${ButtonAccountStyle}
`
const StyledButton = styled.button`
  ${ButtonAccountStyle}
`
export const TokenBalance = styled.span`
  margin-right: 0;
`

function AccountButton(): JSX.Element {
  const [showConnectModal, setShowConnectModal] = useState(false)

  const { account } = useActiveWeb3React()
  const accountEllipsis = account ? `${account.substring(0, 6)}...${account.substring(account.length - 4)}` : ''

  return (
    <>
      {account ? (
        <AccountLink to="/dashboard">{accountEllipsis}</AccountLink>
      ) : (
        <>
          <StyledButton onClick={() => setShowConnectModal(true)}>Connect Wallet</StyledButton>
          <ConnectModal show={showConnectModal} onHide={() => setShowConnectModal(false)} />
        </>
      )}
    </>
  )
}

export default AccountButton
