import { useEffect, useState } from 'react'
import axios, { AxiosError } from 'axios'
import { Link } from 'react-router-dom'
import { Container, Row, Col, Tab } from 'react-bootstrap'
import { Zoom, toast } from 'react-toastify'
import ToastMessage from '../../components/ToastMessage'
import Main from '../../components/Main'
import EmptyPage from '../../components/EmptyPage'
import StyledTab from '../../components/Tab'
import Button from '../../components/Button'
import Pagination from '../../components/Pagination'
import Loading from '../../components/Loading'
import TokenInfo from './components/TokenInfo'
import DepositTokenModal from './components/DepositTokenModal'
import UnlockTokenModal from './components/UnlockTokenModal'
import DepositNFTsModal from './components/DepositNFTsModal'
import LandListView from '../../components/NftItem/LandListView'
import NFT from '../../types/NFT'
import Land from '../../types/Land'
import { toWei, fromWei } from '../../utils'
import {
  useActiveWeb3React,
  ApprovalState,
  useApproveCallback,
  NFTApprovalState,
  useApproveNFTCallback,
  useTokenContract,
  useNFTContract,
  useGameControlContract,
  useNFTsInGameApi,
  useNFTsInGame,
} from '../../hooks'
import config from '../../config/config.json'
import { EmptyText, TokensWrapper, ButtonsRow, ButtonsWrapper } from './Styled'
import SectionTitle from '../../components/SectionTitle'
import Play from '../../assets/images/play.png'
import BigNumber from 'bignumber.js'
import Countdown from 'react-countdown'

BigNumber.config({ EXPONENTIAL_AT: [-100, 100] })

function PlayToEarn(): JSX.Element {
  const [isFetchingNFTs, setFetchingNFTs] = useState(false)
  const [isClaim, setClaim] = useState(false)
  const [isAccountMapped, setAccountMapped] = useState(false)

  const [landInGame, setLandInGame] = useState<Land[]>([])
  const [paginatedLands, setPaginatedLands] = useState<Land[]>([])
  const [itemsInGame, setItemsInGame] = useState<NFT[]>([])
  const [paginatedItems, setPaginatedItems] = useState<NFT[]>([])
  const [hplDeposited, setHPLDeposited] = useState<any>(0)
  const [hpwDeposited, setHPWDeposited] = useState<any>(0)
  const [totalHPLReward, setTotalHPLReward] = useState<any>(0)
  const [hplSpent, setHPLSpent] = useState<any>(0)
  const [hpwSpent, setHPWSpent] = useState<any>(0)
  const [totalHPLRewardClaimable, setTotalHPLRewardClaimable] = useState<any>(0)
  const [totalHPLRewardClaimed, setTotalHPLRewardClaimed] = useState<any>(0)
  const [totalHPWReward, setTotalHPWReward] = useState<any>(0)
  const [totalHPWRewardClaimed, setTotalHPWRewardClaimed] = useState<any>(0)
  const [totalHPWRewardClaimable, setTotalHPWRewardClaimable] = useState<any>(0)
  const [lastClaimed, setLastClaimed] = useState<any>(0)
  const [hplMaxWithdrawal, setHPLMaxWithdrawal] = useState<string>('0')
  const [hpwMaxWithdrawal, setHPWMaxWithdrawal] = useState<string>('0')

  const [showDepositTokenModal, setShowDepositTokenModal] = useState(false)
  const [showDepositLandsModal, setShowDepositLandsModal] = useState(false)
  const [showDepositItemsModal, setShowDepositItemsModal] = useState(false)
  const [showUnlockTokenModal, setShowUnlockTokenModal] = useState(false)
  const { account, chainId } = useActiveWeb3React()

  const networkId = chainId ?? Number(process.env.REACT_APP_CHAIN_ID)
  const apiURL = config.api[networkId]

  const landContract = useNFTContract(config.contracts[networkId].LandNFT)
  const hplContract = useTokenContract(config.contracts[networkId].HPL)
  const hpwContract = useTokenContract(config.contracts[networkId].HPW)
  const gameContract = useGameControlContract(config.contracts[networkId].GameControl)

  const [needApproveNFT, setNeedApproveNFT] = useState(true)
  const [isApprovingNFT, setApprovingNFT] = useState(false)
  const [approvalNFT, approveNFTCallback] = useApproveNFTCallback(
    config.contracts[networkId].LandNFT,
    config.contracts[networkId].GameControl,
  )

  const [needApproveHPL, setNeedApproveHPL] = useState(true)
  const [isApprovingHPL, setApprovingHPL] = useState(false)
  const [approvalHPL, approveHPLCallback] = useApproveCallback(
    config.contracts[networkId].HPL,
    config.contracts[networkId].GameControl,
  )

  const [needApproveHPW, setNeedApproveHPW] = useState(true)
  const [isApprovingHPW, setApprovingHPW] = useState(false)
  const [approvalHPW, approveCallbackHPW] = useApproveCallback(
    config.contracts[networkId].HPW,
    config.contracts[networkId].GameControl,
  )

  const tokensInGameCallback = useNFTsInGameApi(account)
  const nftsInGameCallback = useNFTsInGame(account, 'ingame')

  const onApproveNFT = async () => {
    try {
      setApprovingNFT(true)
      const receipt = await approveNFTCallback()

      if (receipt && landContract) {
        toast.success(<ToastMessage color="success" bodyText="Let's play!!" />, {
          toastId: 'onApprove',
          position: 'bottom-right',
          autoClose: 5000,
          hideProgressBar: true,
          transition: Zoom,
        })

        const _isApprovedForAll = await landContract.methods
          .isApprovedForAll(account, config.contracts[networkId].GameControl)
          .call()

        if (_isApprovedForAll) {
          setNeedApproveNFT(false)
        }
      }
    } catch (error: any) {
      // we only care if the error is something _other_ than the user rejected the tx
      if (error?.code !== 4001) {
        toast.error(<ToastMessage color="error" bodyText="Could not approve. Please try again." />, {
          toastId: 'onApprove',
          position: 'bottom-right',
          autoClose: 5000,
          hideProgressBar: true,
          transition: Zoom,
        })
      }
      console.error(error)
    } finally {
      setApprovingNFT(false)
    }
  }

  const onApproveHPL = async () => {
    try {
      setApprovingHPL(true)
      const receipt = await approveHPLCallback()

      if (receipt && hplContract) {
        toast.success(<ToastMessage color="success" bodyText="Now you can synchronized HPL" />, {
          toastId: 'onApprove',
          position: 'bottom-right',
          autoClose: 5000,
          hideProgressBar: true,
          transition: Zoom,
        })

        const _allowance = await hplContract.methods.allowance(account, config.contracts[networkId].GameControl).call()

        if (toWei(_allowance).gt(toWei(0))) {
          setNeedApproveHPL(false)
        }
      }
    } catch (error: any) {
      // we only care if the error is something _other_ than the user rejected the tx
      if (error?.code !== 4001) {
        toast.error(<ToastMessage color="error" bodyText="Could not approve. Please try again." />, {
          toastId: 'onApprove',
          position: 'bottom-right',
          autoClose: 5000,
          hideProgressBar: true,
          transition: Zoom,
        })
      }
      console.error(error)
    } finally {
      setApprovingHPL(false)
    }
  }

  const onApproveHPW = async () => {
    try {
      setApprovingHPW(true)
      const receipt = await approveCallbackHPW()

      if (receipt && hpwContract) {
        toast.success(<ToastMessage color="success" bodyText="Approve HPW successfully" />, {
          toastId: 'onApproveHPW',
          position: 'bottom-right',
          autoClose: 5000,
          hideProgressBar: true,
          transition: Zoom,
        })

        const _allowance = await hpwContract.methods.allowance(account, config.contracts[networkId].GameControl).call()

        if (toWei(_allowance).gt(toWei(0))) {
          setNeedApproveHPW(false)
        }
      }
    } catch (error: any) {
      // we only care if the error is something _other_ than the user rejected the tx
      if (error?.code !== 4001) {
        toast.error(<ToastMessage color="error" bodyText="Could not approve HPW. Please try again." />, {
          toastId: 'onApproveHPW',
          position: 'bottom-right',
          autoClose: 5000,
          hideProgressBar: true,
          transition: Zoom,
        })
      }
      console.error(error)
    } finally {
      setApprovingHPW(false)
    }
  }

  const fetchTokensInGame = async () => {
    try {
      setFetchingNFTs(true)
      const response = await tokensInGameCallback()
      if (response === undefined) {
        return
      }

      const {
        username: _username,
        totalHPLDeposit: _totalHPLDeposit,
        totalHPWDeposit: _totalHPWDeposit,
        totalHPLWithdraw: _totalHPLWithdraw,
        totalHPWWithdraw: _totalHPWWithdraw,
        totalHPLSpent: _totalHPLSpent,
        totalHPWSpent: _totalHPWSpent,
        totalHPLReward: _totalHPLReward,
        totalHPLRewardClaimed: _totalHPLRewardClaimed,
        totalHPWReward: _totalHPWReward,
        totalHPWRewardClaimed: _totalHPWRewardClaimed,
      } = response

      setHPLSpent(_totalHPLSpent)
      setHPWSpent(_totalHPWSpent)
      setHPLDeposited(
        _totalHPLDeposit >= 0
          ? new BigNumber(_totalHPLDeposit).minus(new BigNumber(_totalHPLWithdraw)).minus(new BigNumber(_totalHPLSpent))
          : 0,
      )
      setHPWDeposited(
        _totalHPWDeposit >= 0
          ? new BigNumber(_totalHPWDeposit).minus(new BigNumber(_totalHPWWithdraw)).minus(new BigNumber(_totalHPWSpent))
          : 0,
      )
      setTotalHPLReward(_totalHPLReward >= 0 ? fromWei(_totalHPLReward).toFixed(3) : 0)
      setTotalHPLRewardClaimed(_totalHPLRewardClaimed >= 0 ? fromWei(_totalHPLRewardClaimed).toFixed(3) : 0)
      setTotalHPWReward(_totalHPWReward >= 0 ? fromWei(_totalHPWReward).toFixed(3) : 0)
      setTotalHPWRewardClaimed(_totalHPWRewardClaimed >= 0 ? fromWei(_totalHPWRewardClaimed).toFixed(3) : 0)
      setTotalHPWRewardClaimable(new BigNumber(_totalHPWReward).minus(new BigNumber(_totalHPWRewardClaimed)))
      setTotalHPLRewardClaimable(new BigNumber(_totalHPLReward).minus(new BigNumber(_totalHPLRewardClaimed)))
      if (_username) {
        setAccountMapped(true)
      }
    } catch (error: any) {
      console.error(error)
    } finally {
      setFetchingNFTs(false)
    }
  }

  const fetchNFTsInGame = async () => {
    try {
      setFetchingNFTs(true)
      const response = await nftsInGameCallback()
      const { lands: _lands, items: _items } = response

      if (_lands && _items) {
        setLandInGame(_lands)
        setPaginatedLands(_lands)
        setItemsInGame(_items)
        setPaginatedItems(_items)
      }
    } catch (error: any) {
      console.error(error)
    } finally {
      setFetchingNFTs(false)
    }
  }

  const fetchLastRewardClaimed = async () => {
    let _lastRewardClaimed = 0
    try {
      if (account && gameContract) {
        _lastRewardClaimed = await gameContract.methods.getUserInfo2(account).call()
        // @ts-ignore
        const { lastRewardClaimedAt } = _lastRewardClaimed
        setLastClaimed(Number(lastRewardClaimedAt) + 172800)
      }
    } catch (error: any) {
      console.error(error)
    }
  }

  const fetchMaxWithdrawalNew = async () => {
    let _maxWithdrawal = 0
    try {
      if (account && gameContract) {
        _maxWithdrawal = await gameContract.methods.getMaxWithdrawalNew(account, false, false).call()
        // @ts-ignore
        const [_hplMaxWithdrawal, _hpwMaxWithdrawal] = _maxWithdrawal
        setHPLMaxWithdrawal(_hplMaxWithdrawal > 0 ? new BigNumber(_hplMaxWithdrawal).dividedBy(1e18).toFixed(3) : '0')
        setHPWMaxWithdrawal(_hpwMaxWithdrawal > 0 ? new BigNumber(_hpwMaxWithdrawal).dividedBy(1e18).toFixed(3) : '0')
      }
    } catch (error: any) {
      console.error(error)
    }
  }

  const onRefresh = async () => {
    try {
      await fetchTokensInGame()
      await fetchNFTsInGame()
      await fetchLastRewardClaimed()
      await fetchMaxWithdrawalNew()
    } catch (error: any) {
      console.error(error)
    }
  }

  const onClaimRewards = async () => {
    try {
      setClaim(true)
      if (account && gameContract) {
        const response = await axios.post(`${apiURL}/games/requestClaimReward/${account}`)
        if (response.status === 200 && response.data) {
          const { hplAmount, hpwAmount, player, expiry, r, s, v } = response.data
          const receipt = await gameContract.methods
            .claimRewards(hplAmount, hpwAmount, expiry, r, s, v)
            .send({ from: player })

          if (receipt) {
            onRefresh()

            toast.success(
              <ToastMessage
                color="success"
                bodyText="Your rewards have been claim successfully"
                link={`${config.explorerURL[networkId]}/tx/${receipt.transactionHash}`}
                linkText="View Transaction"
              />,
              {
                toastId: 'onClaimRewards',
                position: 'bottom-right',
                autoClose: 50000,
                hideProgressBar: true,
                transition: Zoom,
              },
            )
          }
        }
      }
    } catch (error: any) {
      if (axios.isAxiosError(error)) {
        const _e = error as AxiosError
        const msg = _e.response?.data.errors
        toast.error(<ToastMessage color="error" bodyText={msg ? msg?.toUpperCase() : 'Could not claim rewards.'} />, {
          toastId: 'onClaimRewards',
          position: 'bottom-right',
          autoClose: 5000,
          hideProgressBar: true,
          transition: Zoom,
        })
      } else {
        // we only care if the error is something _other_ than the user rejected the tx
        if (error?.code !== 4001) {
          toast.error(<ToastMessage color="error" bodyText="Could not claim rewards." />, {
            toastId: 'onClaimRewards',
            position: 'bottom-right',
            autoClose: 5000,
            hideProgressBar: true,
            transition: Zoom,
          })
        }
        console.error(error)
      }
    } finally {
      setClaim(false)
    }
  }

  const onClaimAndDepositRewards = async () => {
    try {
      setClaim(true)
      if (account && gameContract) {
        const response = await axios.post(`${apiURL}/games/requestClaimReward/${account}`)
        if (response.status === 200 && response.data) {
          const { hplAmount, hpwAmount, player, expiry, r, s, v } = response.data
          const receipt = await gameContract.methods
            .claimRewardsAndDeposit(hplAmount, hpwAmount, expiry, r, s, v)
            .send({ from: player })

          if (receipt) {
            onRefresh()

            toast.success(
              <ToastMessage
                color="success"
                bodyText="Your rewards have been synchronize successfully"
                link={`${config.explorerURL[networkId]}/tx/${receipt.transactionHash}`}
                linkText="View Transaction"
              />,
              {
                toastId: 'onClaimRewards',
                position: 'bottom-right',
                autoClose: 50000,
                hideProgressBar: true,
                transition: Zoom,
              },
            )
          }
        }
      }
    } catch (error: any) {
      if (axios.isAxiosError(error)) {
        const _e = error as AxiosError
        const msg = _e.response?.data.errors
        toast.error(
          <ToastMessage color="error" bodyText={msg ? msg?.toUpperCase() : 'Could not claim & synchronize rewards.'} />,
          {
            toastId: 'onClaimRewards',
            position: 'bottom-right',
            autoClose: 5000,
            hideProgressBar: true,
            transition: Zoom,
          },
        )
      } else {
        // we only care if the error is something _other_ than the user rejected the tx
        if (error?.code !== 4001) {
          toast.error(<ToastMessage color="error" bodyText="Could not claim rewards." />, {
            toastId: 'onClaimRewards',
            position: 'bottom-right',
            autoClose: 5000,
            hideProgressBar: true,
            transition: Zoom,
          })
        }
        console.error(error)
      }
    } finally {
      setClaim(false)
    }
  }

  useEffect(() => {
    fetchTokensInGame()
    fetchNFTsInGame()
    fetchLastRewardClaimed()
    fetchMaxWithdrawalNew()
  }, [account, chainId])

  const onLandsPageChange = _lands => {
    setPaginatedLands(_lands)
  }

  const onItemsPageChange = _items => {
    setPaginatedItems(_items)
  }

  const onRevoke = async () => {
    if (account && landContract && hplContract && hpwContract) {
      await landContract.methods
        .setApprovalForAll(config.contracts[networkId].GameControl, false)
        .send({ from: account })
      setNeedApproveNFT(true)
      await hplContract.methods.approve(config.contracts[networkId].GameControl, 0).send({ from: account })
      await hpwContract.methods.approve(config.contracts[networkId].GameControl, 0).send({ from: account })
    }
  }

  const onOpenDepositNFTsModal = (type: string) => {
    if (needApproveNFT && approvalNFT !== NFTApprovalState.APPROVED) {
      toast.error(<ToastMessage color="error" bodyText="Please approve the game contract first." />, {
        toastId: 'onOpenDepositNFTsModal',
        position: 'bottom-right',
        autoClose: 5000,
        hideProgressBar: true,
        transition: Zoom,
      })
    } else {
      if (type === 'land') {
        setShowDepositLandsModal(true)
      } else {
        setShowDepositItemsModal(true)
      }
    }
  }

  return (
    <Main>
      <Container>
        {isAccountMapped ? (
          <>
            {!isFetchingNFTs ? (
              <>
                <SectionTitle subTitle="Play To Earn" bgImage={Play} />
                <Row className="pb-5 justify-content-center">
                  <StyledTab defaultActiveKey="lands">
                    <Tab eventKey="lands" title="Lands">
                      <ButtonsRow>
                        <Col>
                          {needApproveNFT && approvalNFT !== NFTApprovalState.APPROVED ? (
                            <Button disabled={isApprovingNFT} loading={isApprovingNFT} onClick={onApproveNFT}>
                              Approve Lands
                            </Button>
                          ) : (
                            <>
                              <p>Lands need to be synchronized to start farming</p>
                              <Button onClick={() => onOpenDepositNFTsModal('land')}>Synchronize</Button>
                              <DepositNFTsModal
                                show={showDepositLandsModal}
                                onHide={() => setShowDepositLandsModal(false)}
                                onRefresh={onRefresh}
                                type="land"
                              />
                            </>
                          )}
                        </Col>
                      </ButtonsRow>
                      {landInGame.length > 0 ? (
                        <Row>
                          {paginatedLands.map(land => {
                            return (
                              <Col key={land.tokenId} md={6} lg={6} xl={4} xxl={3}>
                                <LandListView layout="grid" page="playtoearn" land={land} onRefresh={onRefresh} />
                              </Col>
                            )
                          })}
                          <Pagination items={landInGame} onPageChange={onLandsPageChange} pageSize={12} />
                        </Row>
                      ) : (
                        <EmptyText>No lands synchronized with the game.</EmptyText>
                      )}
                    </Tab>
                    <Tab eventKey="items" title="Items">
                      <ButtonsRow>
                        <Col>
                          {needApproveNFT && approvalNFT !== NFTApprovalState.APPROVED ? (
                            <Button disabled={isApprovingNFT} loading={isApprovingNFT} onClick={onApproveNFT}>
                              Approve Items
                            </Button>
                          ) : (
                            <>
                              <p>Items need to be synchronized to start farming</p>
                              <Button onClick={() => onOpenDepositNFTsModal('item')}>Synchronize</Button>
                              <DepositNFTsModal
                                show={showDepositItemsModal}
                                onHide={() => setShowDepositItemsModal(false)}
                                onRefresh={onRefresh}
                                type="item"
                              />
                            </>
                          )}
                        </Col>
                      </ButtonsRow>
                      {itemsInGame.length > 0 ? (
                        <Row>
                          {paginatedItems.map(item => {
                            return (
                              <Col key={item.tokenId} md={6} xl={4} className="mb-5">
                                <p>NFT Item</p>
                              </Col>
                            )
                          })}
                          <Pagination items={itemsInGame} onPageChange={onItemsPageChange} pageSize={12} />
                        </Row>
                      ) : (
                        <EmptyText>No items synchronized with the game.</EmptyText>
                      )}
                    </Tab>
                    <Tab eventKey="tokens" title="Wallet">
                      <Row className="justify-content-center">
                        <Col md={11} lg={8}>
                          <TokensWrapper>
                            <Row>
                              <h2 className="text-center mb-3">Wallet</h2>
                              <Col md={6} className="mb-3 mb-md-0">
                                <TokenInfo tokenName="HPL" depositedAmount={fromWei(hplDeposited).toFixed(3)} />
                              </Col>
                              <Col md={6} className="mb-3 mb-md-0">
                                <TokenInfo tokenName="HPW" depositedAmount={fromWei(hpwDeposited).toFixed(3)} />
                              </Col>
                              <Col md={12} className="mt-3">
                                <hr />
                                <ButtonsWrapper>
                                  {(needApproveHPL && approvalHPL !== ApprovalState.APPROVED) ||
                                  (needApproveHPW && approvalHPW !== ApprovalState.APPROVED) ? (
                                    <>
                                      {needApproveHPL && approvalHPL !== ApprovalState.APPROVED && (
                                        <Button loading={isApprovingHPL} onClick={onApproveHPL}>
                                          Approve HPL
                                        </Button>
                                      )}
                                      {needApproveHPW && approvalHPW !== ApprovalState.APPROVED && (
                                        <Button color="secondary" loading={isApprovingHPW} onClick={onApproveHPW}>
                                          Approve HPW
                                        </Button>
                                      )}
                                    </>
                                  ) : (
                                    <>
                                      <Button onClick={() => setShowDepositTokenModal(true)}>Synchronize</Button>
                                      <Button
                                        disabled={Number(hplDeposited) === 0 && Number(hpwDeposited) === 0}
                                        onClick={() => setShowUnlockTokenModal(true)}
                                        color="secondary"
                                      >
                                        Unlock
                                      </Button>
                                    </>
                                  )}
                                </ButtonsWrapper>
                              </Col>
                            </Row>
                            <DepositTokenModal
                              show={showDepositTokenModal}
                              onHide={() => setShowDepositTokenModal(false)}
                              onRefresh={onRefresh}
                            />
                            <UnlockTokenModal
                              _hplSpent={hplSpent}
                              _hpwSpent={hpwSpent}
                              hplAmount={hplDeposited}
                              hpwAmount={hpwDeposited}
                              show={showUnlockTokenModal}
                              onHide={() => setShowUnlockTokenModal(false)}
                              onRefresh={onRefresh}
                            />
                          </TokensWrapper>
                        </Col>
                      </Row>
                    </Tab>
                    <Tab eventKey="rewards" title="Rewards">
                      <Row className="justify-content-center">
                        <Col md={11} lg={8}>
                          <TokensWrapper>
                            <Row>
                              <h2 className="text-center mb-3">Rewards</h2>
                              <Col md={6} className="mb-3 mb-md-0">
                                <h5>HPL</h5>
                                <hr />
                                <p>Total HPL Reward: {totalHPLReward}</p>
                                <p>Total HPL Claimable: {fromWei(totalHPLRewardClaimable).toFixed(3)}</p>
                                <p>Total HPL Reward Claimed: {totalHPLRewardClaimed}</p>
                              </Col>
                              <Col md={6} className="mb-3 mb-md-0">
                                <h5>HPW</h5>
                                <hr />
                                <p>Total HPW Reward: {totalHPWReward}</p>
                                <p>Total HPW Claimable: {fromWei(totalHPWRewardClaimable).toFixed(3)}</p>
                                <p>Total HPW Reward Claimed: {totalHPWRewardClaimed}</p>
                              </Col>
                              <Col md={12}>
                                {(needApproveHPL && approvalHPL !== ApprovalState.APPROVED) ||
                                (needApproveHPW && approvalHPW !== ApprovalState.APPROVED) ? (
                                  <ButtonsWrapper className="mt-3">
                                    {needApproveHPL && approvalHPL !== ApprovalState.APPROVED && (
                                      <Button loading={isApprovingHPL} onClick={onApproveHPL}>
                                        Approve HPL
                                      </Button>
                                    )}
                                    {needApproveHPW && approvalHPW !== ApprovalState.APPROVED && (
                                      <Button color="secondary" loading={isApprovingHPW} onClick={onApproveHPW}>
                                        Approve HPW
                                      </Button>
                                    )}
                                  </ButtonsWrapper>
                                ) : (
                                  <>
                                    <hr />
                                    {Number(lastClaimed) - 172800 > 0 && (
                                      <Countdown
                                        date={lastClaimed * 1000}
                                        zeroPadTime={2}
                                        renderer={props2 =>
                                          props2.completed ? null : (
                                            <p className="text-center mt-3">
                                              Next Claimable in {new Date(lastClaimed * 1000).toLocaleString()}
                                            </p>
                                          )
                                        }
                                      />
                                    )}
                                    <p className="text-center mt-2">
                                      Max Withdrawal Reward: {hplMaxWithdrawal} HPL | {hpwMaxWithdrawal} HPW
                                    </p>
                                    <ButtonsWrapper>
                                      <Button onClick={onClaimAndDepositRewards} loading={isClaim} disabled={isClaim}>
                                        Claim Rewards And Synchronize
                                      </Button>
                                      <Button onClick={onClaimRewards} loading={isClaim} disabled={isClaim}>
                                        Claim
                                      </Button>
                                    </ButtonsWrapper>
                                  </>
                                )}
                              </Col>
                            </Row>
                          </TokensWrapper>
                        </Col>
                      </Row>
                    </Tab>
                  </StyledTab>
                </Row>
              </>
            ) : (
              <Loading />
            )}
          </>
        ) : (
          <EmptyPage>
            <p>
              To play the game, you need to <Link to="/dashboard">create an account here</Link>
            </p>
          </EmptyPage>
        )}
        {process.env.NODE_ENV !== 'production' && (
          <div className="mb-3 text-center">
            <Button onClick={onRevoke}>Revoke</Button>
          </div>
        )}
      </Container>
    </Main>
  )
}

export default PlayToEarn
