import { FormControl, InputGroup } from 'react-bootstrap'
import styled from 'styled-components/macro'

export const TotalPrize = styled.div`
  font-size: 64px;
  font-weight: 600;
  color: #fff;
  background: linear-gradient(rgb(255, 216, 0) 0%, rgb(253, 171, 50) 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`
export const RoundHistoryWrapper = styled.div`
  background-color: #110f22;
  border-radius: 10px;
  border: 1px solid #000;
`
export const RoundHistoryHeader = styled.div`
  background: linear-gradient(166.77deg, #110f22 0%, #0e0c16 100%);
  border-radius: 10px 10px 0px 0px;
  padding: 16px 24px;
  border-bottom: 1px solid rgb(56, 50, 65);

  h5 {
    font-family: 'Rubik', sans-serif;
  }
`
export const RoundDate = styled.div`
  text-align: left;
  margin-top: 0.5rem;
  font-size: 14px;
  color: #6c757d;
`
export const RoundIDInput = styled.input`
  border: none;
  background-color: #26233a;
  border-radius: 10px;
  width: 60px;
  margin-left: 10px;
  color: #fff;
  padding: 4px 16px;
  font-size: 1rem;

  &:focus {
    outline: none;
    box-shadow: #ffc11b 0px 0px 0px 1px, #ffc11bbf 0px 0px 0px 4px;
  }
`
export const RoundButton = styled.button`
  border: none;
  background: none;
  color: #fff;
  padding: 0;
  width: 32px;
  margin-right: 5px;

  &:disabled {
    color: #666171;
  }

  &:last-child {
    margin: 0;
  }
`
export const RoundHistoryBody = styled.div`
  padding: 24px;

  h4 {
    font-family: 'Rubik', sans-serif;
    margin-bottom: 1rem;
  }

  @media (min-width: 768px) {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`
export const WinningNumber = styled.span`
  font-family: 'SVN-SAF', sans-serif;
  font-size: 20px;
  margin: 0 0.5rem;

  &:first-child {
    color: #d750b2;
  }

  &:nth-child(2) {
    color: #a881fc;
  }

  &:nth-child(3) {
    color: #20c7d4;
  }

  &:nth-child(4) {
    color: #31d0aa;
  }

  &:nth-child(5) {
    color: #93d45a;
  }

  &:last-child {
    color: #ffc43d;
    margin-right: 0;
  }

  @media (min-width: 768px) {
    font-size: 42px;
    margin: 0 1rem;
  }
`
// Next Round
export const NextRoundWrapper = styled.div`
  background-color: #110f22;
  border-radius: 10px;
  border: 1px solid #000;
`
export const NextRoundHeader = styled.div`
  background: linear-gradient(166.77deg, #110f22 0%, #0e0c16 100%);
  border-radius: 10px 10px 0px 0px;
  padding: 16px 24px;
  border-bottom: 1px solid rgb(56, 50, 65);

  h5 {
    font-family: 'Rubik', sans-serif;
  }
`
export const NextRoundBody = styled.div`
  padding: 24px;

  @media (min-width: 768px) {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`
export const NextRoundTitle = styled.h4`
  font-family: 'Rubik', sans-serif;
  margin-bottom: 1rem;

  @media (min-width: 768px) {
    text-align: left;
  }
`
export const NextRoundGrid = styled.div`
  display: grid;
  grid-template-columns: auto;

  @media (min-width: 768px) {
    column-gap: 32px;
    grid-template-columns: auto 1fr;
    text-align: center;
  }
`
export const NextRoundPrizePot = styled.div`
  margin-bottom: 1rem;

  @media (min-width: 768px) {
    text-align: left;
  }
`
export const PrizePot = styled(TotalPrize)`
  font-size: 40px;
  line-height: 40px;
  margin-top: 0.5rem;
`
export const TicketsInformation = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 0.5rem;

  p {
    margin-bottom: 0;
  }

  button {
    margin-top: 2rem;
  }

  @media (min-width: 768px) {
    flex-direction: row;
    align-items: flex-start;
    margin-top: 0;

    button {
      margin-top: 0;
    }
  }
`
export const YourTickets = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: 768px) {
    align-items: flex-start;
    margin-right: 1.25rem;
    text-align: left;
  }
`
// Buy Tickets Modal
export const ShortcutButtonsWrapper = styled.div<{ isVisible: boolean }>`
  display: ${({ isVisible }) => (isVisible ? 'flex' : 'none')};
  align-items: center;
  justinfy-content: space-between;

  button {
    flex-grow: 1;
    margin-right: 0.125rem;
    margin-left: 0.125rem;
  }
`
export const StyledInputGroup = styled(InputGroup)`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  border: 1px solid #ffffff5e;
  border-radius: 10px;
  padding: 0.75rem 0.75rem 0.75rem 1rem;
`
export const BuyTicketsInput = styled(FormControl)`
  box-shadow: none !important;
  border-radius: 0;
  border: none;
  padding-right: 0;
  background-color: transparent !important;
  color: #fff !important;
  height: 60px;
  font-size: 17px;
  @media (min-width: 768px) {
    font-size: 20px;
  }

  &:placeholder-shown + p {
    display: none;
  }
`
export const Buttons = styled.div`
  text-align: center;

  button {
    width: 100%;
    padding: 15px;

    &:last-child {
      margin-top: 1.5rem;
    }
  }

  a {
    margin-top: 1rem;
    display: block;
  }
`
export const DuplicateWarning = styled.span`
  font-size: 14px;
  color: #ffb237;
`
export const ScrollWrapper = styled.div`
  max-height: 350px;
  padding: 0 0.5rem;
  overflow: auto;
`
export const InputsContainer = styled.div<{ focused: boolean; isDuplicate: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 36px;
  box-sizing: border-box;
  border: 1px solid #d7caec;
  background-color: #eeeaf4;
  border-radius: 16px;
  margin-bottom: 16px;
  ${({ isDuplicate }) =>
    isDuplicate &&
    `
    border: 1px solid #FFB237;
    box-shadow: 0px 0px 0px 2px #ffb237;
  `}
  ${({ focused }) =>
    focused &&
    `
    border: 1px solid #7645D9;
    box-shadow: 0px 0px 0px 2px #ffb237;
  `}
`
export const DigitInput = styled.input`
  border: none;
  height: 32px;
  padding: 0 10px;
  font-size: 16px;
  flex: 1;
  width: 16px;
  text-align: center;
  min-width: 0;
  background-color: transparent;
  caret-color: #7a6faa;

  &::placeholder {
    color: #000;
    text-align: center;
  }
  &:focus {
    outline: none;
  }
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
  }
  -moz-appearance: textfield; /* Firefox */
`
