import { useCallback } from 'react'
import axios from 'axios'

export const useHPWPrice = (): (() => Promise<number>) => {
  const callback = useCallback(async (): Promise<number> => {
    let result = 0

    try {
      const response = await axios.get(
        'https://api.coingecko.com/api/v3/simple/price?ids=happyland-reward-token&vs_currencies=usd',
      )

      if (response.status === 200 && response.data) {
        const { usd } = response.data?.['happyland-reward-token']
        result = Number(usd)
      }
    } catch (error: any) {
      console.error(error)
    }
    return result
  }, [])

  return callback
}
