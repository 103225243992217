import { useMemo } from 'react'
import Web3 from 'web3'
import { Contract } from 'web3-eth-contract'
import { useActiveWeb3React } from './useWeb3'
import { getContract } from '../utils'

// ABI
import HAPPYLANDNFT_ABI from '../constants/abi/LandNFT.abi.json'
import ERC20_ABI from '../constants/abi/ERC20.abi.json'
import MARKETPLACE_ABI from '../constants/abi/Marketplace.abi.json'
import MASTERCHEF_ABI from '../constants/abi/MasterChef.abi.json'
import GAMECONTROL_ABI from '../constants/abi/GameControl.abi.json'
import LANDSALE_ABI from '../constants/abi/LandSale.abi.json'
import FAUCET_ABI from '../constants/abi/Faucet.abi.json'
import LANDEXPAND_ABI from '../constants/abi/LandExpand.abi.json'

const useContract = (address?: string, abi?: any) => {
  const { library, account } = useActiveWeb3React()

  return useMemo(() => {
    if (!address || !abi) return null

    try {
      const web3 = new Web3(library)
      return getContract(address, abi, web3)
    } catch (error: any) {
      console.error('Failed to get contract', error)
      return null
    }
  }, [address, abi, library, account]) // eslint-disable-line react-hooks/exhaustive-deps
}

export const useNFTContract = (address?: string): Contract | null => {
  return useContract(address, HAPPYLANDNFT_ABI)
}

export const useTokenContract = (address?: string): Contract | null => {
  return useContract(address, ERC20_ABI)
}

export const useMarketPlaceContract = (address?: string): Contract | null => {
  return useContract(address, MARKETPLACE_ABI)
}

export const useMasterChefContract = (address?: string): Contract | null => {
  return useContract(address, MASTERCHEF_ABI)
}

export const useGameControlContract = (address?: string): Contract | null => {
  return useContract(address, GAMECONTROL_ABI)
}

export const useLandSaleContract = (address?: string): Contract | null => {
  return useContract(address, LANDSALE_ABI)
}

export const useFaucetContract = (address?: string): Contract | null => {
  return useContract(address, FAUCET_ABI)
}

export const useLandExpandContract = (address?: string): Contract | null => {
  return useContract(address, LANDEXPAND_ABI)
}
