import { useState } from 'react'
import Button from 'components/Button'
import {
  NextRoundWrapper,
  NextRoundHeader,
  NextRoundBody,
  PrizePot,
  TicketsInformation,
  YourTickets,
  NextRoundGrid,
  NextRoundTitle,
  NextRoundPrizePot,
} from '../Styled'
import ViewTicketsModal from './ViewTicketsModal'

interface INextRoundCardProps {
  openBuyTicketsModal: () => void
}

function NextRoundCard(props: INextRoundCardProps): JSX.Element {
  const { openBuyTicketsModal } = props

  const [showViewTicketsModal, setShowViewTicketsModal] = useState(false)

  return (
    <NextRoundWrapper>
      <NextRoundHeader>
        <div className="d-flex align-items-center justify-content-between text-left">
          <div className="d-flex">
            <h5 className="mb-0">Next Draw</h5>
          </div>
          <div className="d-flex">
            <span className="text-muted">#460 | Draw: Sat 5, 2022, 7:00 PM</span>
          </div>
        </div>
      </NextRoundHeader>
      <NextRoundBody>
        <NextRoundGrid>
          <NextRoundTitle>Prize Pot</NextRoundTitle>
          <NextRoundPrizePot>
            <PrizePot>$100,000</PrizePot>
            <span className="text-muted text-small">500,000 HPL</span>
          </NextRoundPrizePot>
          <NextRoundTitle className="d-none d-md-block">Your tickets</NextRoundTitle>
          <TicketsInformation>
            <YourTickets>
              You have 0 ticket this round
              <a
                href="#"
                onClick={e => {
                  e.preventDefault()
                  setShowViewTicketsModal(true)
                }}
              >
                View your tickets
              </a>
            </YourTickets>
            <ViewTicketsModal roundId={11} show={showViewTicketsModal} onHide={() => setShowViewTicketsModal(false)} />
            <Button onClick={openBuyTicketsModal}>Buy tickets</Button>
          </TicketsInformation>
        </NextRoundGrid>
      </NextRoundBody>
    </NextRoundWrapper>
  )
}

export default NextRoundCard
