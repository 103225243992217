import { useEffect, useState } from 'react'
import { StakeFooterDotted } from '../Styled'
import { usePendingRewards } from '../../../hooks'
import { formatNumber, fromWei } from '../../../utils'

interface IPendingRwProps {
  poolId: number
  account: any
  chainId: any
}

function PendingRewards(props: IPendingRwProps): JSX.Element {
  const { poolId: id, account, chainId } = props

  const [pendingRewards, setPendingRewards] = useState(0)

  const pendingRewardsCallback = usePendingRewards(id)

  const fetchPendingRewards = async () => {
    try {
      const _pendingRewards = await pendingRewardsCallback()
      const _rewardsHPL = fromWei(_pendingRewards._hpl).toFixed(3)
      if (_rewardsHPL) {
        setPendingRewards(Number(_rewardsHPL))
      } else {
        setPendingRewards(0)
      }
    } catch (error: any) {
      console.error(error)
    }
  }

  useEffect(() => {
    fetchPendingRewards()
    const interval = setInterval(() => {
      fetchPendingRewards()
    }, 1000 * 20)

    return () => clearInterval(interval)
  }, [account, chainId])

  return (
    <>
      <div>Pending Rewards</div>
      <StakeFooterDotted />
      <div>{formatNumber(pendingRewards)} HPL</div>
    </>
  )
}

export default PendingRewards
