import { useEffect, useState } from 'react'
import { useActiveWeb3React, useMasterChefContract } from '../../../hooks'
import { StakeFooterDotted } from '../Styled'
import config from '../../../config/config.json'
import { formatNumber, fromWei } from '../../../utils'

interface ITVLProps {
  poolId: number
  tokenName: string
  account: any
}

function MyLiquidity(props: ITVLProps): JSX.Element {
  const { poolId, account, tokenName } = props

  const [userInfo, setUserInfo] = useState({
    stakeAmount: '0',
  })

  const { chainId } = useActiveWeb3React()
  const networkId = chainId ?? Number(process.env.REACT_APP_CHAIN_ID)
  const masterChefContract = useMasterChefContract(config.contracts[networkId].MasterChef)

  const fetchData = async () => {
    if (account) {
      let _userInfo: any = {}
      if (masterChefContract) {
        _userInfo = await masterChefContract.methods.getUserInfo(poolId, account).call()
      }
      setUserInfo(_userInfo)
    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  return (
    <>
      <div>My stake</div>
      <StakeFooterDotted />
      <div>
        {formatNumber(fromWei(userInfo.stakeAmount).toNumber().toFixed(3))} {tokenName}
      </div>
    </>
  )
}

export default MyLiquidity
