import styled from 'styled-components/macro'
import { Col, Form } from 'react-bootstrap'

export const AccountCol = styled(Col)`
  background: #110f22;
  padding: 4rem 1.5rem;
  border-radius: 10px;
  border: 1px solid #000;

  @media (min-width: 992px) {
    padding: 3.5rem 2.5rem 6rem;
  }
  h5 {
    margin-bottom: 1.5rem;
    @media (min-width: 992px) {
      margin-bottom: 3rem;
    }
  }
`

export const FormSelect = styled(Form.Select)`
  background: transparent;
  color: #fff;
  box-shadow: none !important;
  border: 1px solid #fff;
  border-radius: 10px;
  &:focus,
  &:active {
    border-color: ${props => props.theme.color.orange[500]};
  }
`
export const FormInput = styled(Form.Control)`
  box-shadow: none !important;
  border-radius: 10px;
  border: 1px solid #fff;
  background-color: transparent !important;
  color: #fff !important;

  &:focus,
  &:active {
    border-color: ${props => props.theme.color.orange[500]};
  }

  &:placeholder-shown + small {
    display: none !important;
  }

  &[readonly] {
    opacity: 0.7;
    color: #ffffff22;
    background-color: #ffffff22 !important;

    &:focus,
    &:active {
      border-color: rgba(255, 255, 255, 0.3);
    }
  }
`

export const ButtonWrapper = styled.div`
  display: flex;
  margin-top: 2rem;
  justify-content: space-between;

  button {
    margin-right: 0.5rem;
  }
`
