import styled, { css, keyframes } from 'styled-components/macro'
import { Link } from 'react-router-dom'
import HeaderBg from '../../assets/images/header-bg.svg'
import BalanceBg from '../../assets/images/balance.svg'
import { NavDropdown } from 'react-bootstrap'

export const HeaderMain = styled.header`
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 9;
  background: url(${HeaderBg}) no-repeat center center;
  background-size: cover;
  @media (min-width: 992px) {
    background: none;
  }
`
export const HeaderWrapper = styled.div`
  position: relative;
  z-index: 9;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 70px;
  @media (min-width: 992px) {
    background: url(${HeaderBg}) no-repeat center center;
    background-size: 100%;
    height: 96px;
  }
}
  @media (max-width: 991px) {
    display: flex;
  }
`
export const LogoWrapper = styled.div`
  align-self: flex-start;
  max-width: 130px;
  @media (min-width: 1400px) {
    transform: translate(30px, 15px);
  }
`
export const HeaderTopLogin = styled.div`
  align-self: center;
`
export const MainMenu = styled.div`
  position: relative;
  flex: 1;
`
export const NavBarWrap = styled.div`
  ul {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    list-style: none;
    justify-content: space-around;
    width: 100%;
  }
`
export const Logo = styled.img`
  width: 80px;
  min-width: 80px;

  transition: all 0.5s ease;

  @media (min-width: 768px) {
    width: 120px;
  }

  @media (min-width: 1200px) {
    width: 100%;
  }
`
export const MenuLinkStyle = css`
  font-family: 'SVN-SAF', sans-serif;
  font-size: 17px;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  color: ${props => props.theme.color.orange[300]};
  padding: 10px 6px;
  display: block;
  line-height: 1;
  position: relative;
  z-index: 1;
  @media (min-width: 1200px) {
    font-size: 20px;
    padding: 10px 15px;
  }

  &:hover,
  &.active {
    color: ${props => props.theme.color.orange[200]};
  }
`
export const MenuDropdown = styled(NavDropdown)`
  .dropdown-toggle {
    ${MenuLinkStyle}
  }
`
export const MenuLink = styled(Link)`
  ${MenuLinkStyle}
`
export const MenuA = styled.a`
  ${MenuLinkStyle}
`
export const MobileMenuLink = styled(Link)`
  ${MenuLinkStyle};
  color: #fff;
  &:hover,
  &.active {
    color: #ffc11b;
  }
`
export const MobileMenuA = styled.a`
  ${MenuLinkStyle};
  color: #fff;
`
export const MobileMenu = styled.ul`
  list-style: none;
`
export const MenuToggle = styled.button`
  background: transparent;
  border: none;
  color: #fff;
  @media (min-width: 992px) {
    display: none;
  }
`
export const BalanceWrap = styled.div`
  position: absolute;
  right: -10px;
  bottom: -58px;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: end;
  width: 280px;
  height: 80px;
  background: url(${BalanceBg}) no-repeat center center;
  background-size: 100%;
  font-family: 'SVN-SAF', sans-serif;
  font-size: 14px;
  @media (min-width: 768px) {
    width: 360px;
    height: 102px;
    right: -10px;
    bottom: -80px;
    font-size: 16px;
  }
  @media (min-width: 992px) {
    bottom: -65px;
    right: 5px;
  }
  @media (min-width: 1200px) {
    bottom: -70px;
  }
`
export const BalanceInner = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  & > div {
    position: absolute;
    width: 100%;
    text-align: center;
    bottom: 11px;
    @media (min-width: 768px) {
      bottom: 16px;
    }
  }
`
const animateGlow = keyframes`
  0% {
    background-position: 0 50%;
  }
  100% {
    background-position: 200% 50%;
  }
`
export const LotteryButton = styled.div`
  position: fixed;
  bottom: 50%;
  left: 0;

  a {
    display: inline-block;
    position: relative;
    background-image: linear-gradient(to bottom, #ffc61c, #ff9c1c);
    padding: 16px 24px;
    border-radius: 8px;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.01);
  }

  a::after {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    right: 0;
    z-index: -1;
    height: 100%;
    width: 100%;
    filter: blur(15px);
    background: linear-gradient(
      to left,
      #ff5770,
      #e4428d,
      #c42da8,
      #9e16c3,
      #6501de,
      #9e16c3,
      #c42da8,
      #e4428d,
      #ff5770
    );
    background-size: 200% 200%;
    animation: ${animateGlow} 1.25s linear infinite;
  }
`
