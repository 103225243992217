import { Button as BootstrapButton } from 'react-bootstrap'
import styled, { css, ThemeProvider, keyframes } from 'styled-components/macro'
import { CgSpinner } from 'react-icons/cg'
import { Link } from 'react-router-dom'

const PrimaryTheme = {
  background: '#ffc11b',
  border: '#ffc11b',
  color: '#982f34',
  hover: {
    background: '#982f34',
    border: '#982f34',
    color: '#fff',
  },
}

const SecondaryTheme = {
  background: 'transparent',
  border: '#ffc11b',
  color: '#ffc11b',
  hover: {
    background: '#982f34',
    border: '#982f34',
    color: '#fff',
  },
}

const YellowTheme = {
  background: '#fcbc2e',
  border: '#fcbc2e',
  color: '#1f1528',
  hover: {
    background: '#ffcc5d',
    border: '#ffcc5d',
    color: '#1f1528',
  },
}

const StyleButton = css`
  background-color: ${props => props.theme.background};
  border: 1px solid ${props => props.theme.border};
  padding: 0.5rem 1rem;
  display: inline-block;
  text-align: center;
  text-transform: uppercase;
  line-height: 1rem;
  border-radius: 4px !important;
  font-size: 18px;
  font-weight: 500;
  color: ${props => props.theme.color};

  &:hover,
  &:focus,
  &:active {
    border-color: ${props => props.theme.hover.background};
    background-color: ${props => props.theme.hover.border};
    color: ${props => props.theme.hover.color};
  }

  &:active,
  &:focus {
    box-shadow: 0 0 0 0.25rem ${props => props.theme.hover.background}42;
  }

  &:disabled {
    background-color: ${props => props.theme.background};
    border-color: ${props => props.theme.border};
    color: ${props => props.theme.color};
  }

  svg {
    margin-right: 0.5rem;
  }
`

const StyledButton = styled(BootstrapButton)`
  ${StyleButton}
`

const StyledLink = styled(Link)`
  ${StyleButton}
`

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`
const LoadingIcon = styled(CgSpinner)`
  animation: ${spin} 0.75s linear infinite;
`

interface IButtonProps {
  color?: 'primary' | 'secondary' | 'yellow'
  icon?: JSX.Element
  children?: string | JSX.Element | JSX.Element[]
  disabled?: boolean
  loading?: boolean
  loadingText?: string
  url?: string
  onClick?: (e?: any) => void
}

function Button(props: IButtonProps): JSX.Element {
  const { color, icon, children, disabled, loading, loadingText, url, onClick } = props

  let _theme = PrimaryTheme

  switch (color) {
    case 'primary':
      _theme = PrimaryTheme
      break
    case 'secondary':
      _theme = SecondaryTheme
      break
    case 'yellow':
      _theme = YellowTheme
      break
  }

  return (
    <ThemeProvider theme={_theme}>
      {url ? (
        <StyledLink to={url}>{children}</StyledLink>
      ) : (
        <StyledButton disabled={disabled} onClick={onClick}>
          {loading && <LoadingIcon size={18} />}
          {!loading && icon}
          {loading && loadingText ? loadingText : <>{children}</>}
        </StyledButton>
      )}
    </ThemeProvider>
  )
}

Button.defaultProps = {
  color: 'primary',
}

export default Button
