import { useEffect, useState } from 'react'
import { useActiveWeb3React, useMasterChefContract, useHPLPerYear, usePoolInfo } from '../../../hooks'
import config from '../../../config/config.json'
import { fromWei } from '../../../utils'

interface IAPYProps {
  poolId: number
}

function PoolAPY(props: IAPYProps): JSX.Element {
  const { poolId } = props
  const [apy, setAPY] = useState<any>(0)

  const { chainId } = useActiveWeb3React()
  const networkId = chainId ?? Number(process.env.REACT_APP_CHAIN_ID)
  const pools = config.pools[networkId]
  // @ts-ignore
  const pool = pools.find(p => p.id === poolId)

  const masterChefContract = useMasterChefContract(config.contracts[networkId].MasterChef)
  const hplPerYearCallback = useHPLPerYear(pool.id)
  const poolInfoCallback = usePoolInfo(pool.id)

  useEffect(() => {
    const fetchData = async () => {
      try {
        const _hplPerYear = await hplPerYearCallback()
        const poolInfo = await poolInfoCallback()
        const { totalWeight } = poolInfo
        if (masterChefContract) {
          const _apy = ((_hplPerYear * 1.02) / Number(fromWei(totalWeight))) * 100 * 1e6
          if (totalWeight === 0 || totalWeight === undefined || _apy === Infinity) {
            setAPY(0)
          } else {
            setAPY(_apy.toFixed(3))
          }
        }
      } catch (error: any) {
        console.error(error)
      }
    }

    fetchData()

    const interval = setInterval(() => {
      fetchData()
    }, 1000 * 20)

    return () => clearInterval(interval)
  }, [chainId, poolId])

  return <>{apy} %</>
}

export default PoolAPY
