import { useEffect, useState } from 'react'
import { Container, Row, Col, Tab } from 'react-bootstrap'
import { Zoom, toast } from 'react-toastify'
import ToastMessage from '../../components/ToastMessage'
import Main from '../../components/Main'
import StyledTab from '../../components/Tab'
import Pagination from '../../components/Pagination'
import Loading from '../../components/Loading'
import Land from '../../types/Land'
import Item from '../../types/Item'
import {
  useActiveWeb3React,
  ApprovalState,
  useApproveCallback,
  useNFTContract,
  useMarketplaceApi,
  useHPLPrice,
  useHPWPrice,
  useBNBPrice,
  useNFTsInGameApi,
  useIsMounted,
} from '../../hooks'
import config from '../../config/config.json'
import LandListView from '../../components/NftItem/LandListView'
import { ListGridToggle, ResultNFT, ToolbarMarket } from './Styled'
import MarketplaceTitle from '../../assets/images/marketplace.png'
import SectionTitle from '../../components/SectionTitle'
import Filter from './Filter'
import axios from 'axios'
import { Link } from 'react-router-dom'
import EmptyPage from '../../components/EmptyPage'

function Marketplace(): JSX.Element {
  const [lands, setLands] = useState<Land[]>([])
  const [filteredLands, setFilteredLands] = useState<Land[]>([])
  const [countLands, setCountLands] = useState(0)
  const [paginatedLands, setPaginatedLands] = useState<Land[]>([])

  const [rentLand, setRentLand] = useState<Land[]>([])
  const [countRentLand, setCountRentLand] = useState(0)

  const [filteredItems, setFilteredItems] = useState<Item[]>([])
  const [paginatedItems, setPaginatedItems] = useState<Item[]>([])

  const [countItems, setCountItems] = useState(0)
  const [hplPrice, setHPLPrice] = useState(0)
  const [hpwPrice, setHPWPrice] = useState(0)
  const [bnbPrice, setBNBPrice] = useState(0)

  const [isAccountMapped, setAccountMapped] = useState(false)
  const [showBuy, setShowBuy] = useState('land')
  const [showRent, setShowRent] = useState('land')

  const [isLoading, setLoading] = useState(false)
  const [isRentLoading, setRentLoading] = useState(false)
  const [isApproving, setApproving] = useState(false)
  const [needApprove, setNeedApprove] = useState(true)

  const { account, chainId } = useActiveWeb3React()
  const networkId = chainId ?? 56
  const apiURL = config.api[networkId]
  const marketplaceCallback = useMarketplaceApi()
  const nftContract = useNFTContract(config.contracts[networkId].NFT)
  const [approval, approveCallback] = useApproveCallback(
    config.contracts[networkId].HPL,
    config.contracts[networkId].Market,
  )
  const hplPriceCallback = useHPLPrice()
  const hpwPriceCallback = useHPWPrice()
  const bnbPriceCallback = useBNBPrice()

  const tokensInGameCallback = useNFTsInGameApi(account)
  const isMounted = useIsMounted()

  const fetchNFTs = async () => {
    try {
      setLoading(true)
      const data = await marketplaceCallback()
      const { lands: _lands, items: _items } = data
      if (_lands && _items) {
        const sortedLands = _lands.sort((a, b) => b.timestamp - a.timestamp)
        const sortedItems = _items.sort((a, b) => b.timestamp - a.timestamp)

        if (isMounted.current) {
          setLands(_lands)
          setFilteredLands(sortedLands)
          setPaginatedLands(sortedLands)
          setCountLands(_lands.length)
          setFilteredItems(sortedItems)
          setPaginatedItems(sortedItems)
          setCountItems(_items.length)
        }
      } else {
        setFilteredLands([])
        setPaginatedLands([])
        setFilteredItems([])
        setPaginatedItems([])
      }
    } catch (error: any) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  const fetchAccountInfo = async () => {
    try {
      setLoading(false)
      const response = await tokensInGameCallback()
      if (response === undefined) {
        return
      }

      const { username: _username, masterAddress: _masterAddress } = response
      if (_username) {
        setAccountMapped(true)
      }
      if (_masterAddress) {
        setAccountMapped(false)
      }
    } catch (error: any) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  const fetchRent = async () => {
    try {
      setRentLoading(true)
      const response = await axios.get(`${apiURL}/rents`)

      if (response.status === 200 && response.data) {
        const { rents } = response.data
        const _rents = rents.filter(token => token.isWaitingRent === true)
        setRentLand(_rents)
        setCountRentLand(_rents.length)
      }
    } catch (error: any) {
      console.error(error)
    } finally {
      setRentLoading(false)
    }
  }

  useEffect(() => {
    fetchNFTs()
    fetchRent()
    fetchAccountInfo()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account, chainId])

  useEffect(() => {
    const fetchData = async () => {
      try {
        const _hplPrice = await hplPriceCallback()
        const _hpwPrice = await hpwPriceCallback()
        const _bnbPrice = await bnbPriceCallback()
        setBNBPrice(_bnbPrice)
        setHPLPrice(_hplPrice)
        setHPWPrice(_hpwPrice)
      } catch (error: any) {
        console.error(error)
      }
    }

    fetchData()

    const interval = setInterval(() => {
      fetchData()
      fetchRent()
    }, 1000 * 30)

    return () => clearInterval(interval)
  }, [chainId])

  const onRefresh = async () => {
    try {
      setLoading(true)
      await fetchNFTs()
      await fetchRent()
      await fetchAccountInfo()
    } catch (error: any) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  const onShowBuyLand = () => {
    setShowBuy('land')
  }

  const onShowRentLand = () => {
    setShowRent('land')
  }

  // const onShowBuyItem = () => {
  //   setShowBuy('item')
  // }

  // const onShowRentItem = () => {
  //   setShowRent('item')
  // }

  const onApprove = async () => {
    try {
      setApproving(true)
      const receipt = await approveCallback()

      if (receipt && nftContract) {
        toast.success(<ToastMessage color="success" bodyText="Now you can sell your lands or items" />, {
          toastId: 'onApprove',
          position: 'bottom-right',
          autoClose: 5000,
          hideProgressBar: true,
          transition: Zoom,
        })

        const _isApprovedForAll = await nftContract.methods
          .isApprovedForAll(account, config.contracts[networkId].Market)
          .call()

        if (_isApprovedForAll) {
          setNeedApprove(false)
        }
      }
    } catch (error: any) {
      // we only lande if the error is something _other_ than the user rejected the tx
      if (error?.code !== 4001) {
        toast.error(<ToastMessage color="error" bodyText="Could not approve. Please try again." />, {
          toastId: 'onApprove',
          position: 'bottom-right',
          autoClose: 5000,
          hideProgressBar: true,
          transition: Zoom,
        })
      }
      console.error(error)
    } finally {
      setApproving(false)
    }
  }

  const onLandsPageChange = _lands => {
    setPaginatedLands(_lands)
  }

  const onItemsPageChange = _items => {
    setPaginatedItems(_items)
  }

  const onFilter = _products => {
    setFilteredLands(_products)
    setPaginatedLands(_products)
  }

  const onFindID = _products => {
    setPaginatedLands(_products)
  }

  const onResetFilter = _products => {
    setFilteredLands(_products)
    setPaginatedLands(_products)
  }

  return (
    <>
      <Main>
        <Container>
          <SectionTitle subTitle="Marketplace" bgImage={MarketplaceTitle} />
          <Row className="pb-5 justify-content-center">
            <>
              {isLoading ? (
                <Loading />
              ) : (
                <StyledTab defaultActiveKey="buy">
                  <Tab eventKey="buy" title="Buy">
                    <ToolbarMarket>
                      <ResultNFT>
                        {showBuy === 'land' ? (
                          <>
                            {countLands} {countLands > 1 ? 'results' : 'result'}
                          </>
                        ) : (
                          <>
                            {countItems} {countItems > 1 ? 'results' : 'result'}
                          </>
                        )}
                      </ResultNFT>
                      <ListGridToggle>
                        <button
                          className={`grid-list-toggle ${showBuy === 'land' ? 'active' : ''}`}
                          onClick={onShowBuyLand}
                        >
                          Land
                        </button>
                        {/*<button*/}
                        {/*  className={`grid-list-toggle ${showBuy === 'item' ? 'active' : ''}`}*/}
                        {/*  onClick={onShowBuyItem}*/}
                        {/*>*/}
                        {/*  Items*/}
                        {/*</button>*/}
                      </ListGridToggle>
                    </ToolbarMarket>
                    {showBuy === 'land' ? (
                      <Row>
                        <Col lg={3}>
                          <Filter
                            items={lands}
                            onFilter={onFilter}
                            onIDChangeCallback={onFindID}
                            onResetCallback={onResetFilter}
                          />
                        </Col>
                        <Col lg={9}>
                          {paginatedLands.length > 0 ? (
                            <Row>
                              {paginatedLands.map(land => {
                                return (
                                  <Col key={land.tokenId} md={6} lg={6} xl={4}>
                                    <LandListView
                                      layout="grid"
                                      page="marketplace"
                                      rateHPL={hplPrice}
                                      rateHPW={hpwPrice}
                                      rateBNB={bnbPrice}
                                      onApprove={onApprove}
                                      isApproving={isApproving}
                                      needApprove={needApprove && approval !== ApprovalState.APPROVED}
                                      land={land}
                                      onRefresh={onRefresh}
                                    />
                                  </Col>
                                )
                              })}
                              <Pagination items={filteredLands} onPageChange={onLandsPageChange} pageSize={12} />
                            </Row>
                          ) : (
                            <Row>
                              <p className="text-center">No lands found.</p>
                            </Row>
                          )}
                        </Col>
                      </Row>
                    ) : (
                      <>
                        {paginatedItems.length > 0 ? (
                          <>
                            <Row>
                              {paginatedItems.map(item => {
                                return (
                                  <Col key={item.tokenId} xl={6}>
                                    <p>{item.tokenId}</p>
                                  </Col>
                                )
                              })}
                            </Row>
                            <Pagination items={filteredItems} onPageChange={onItemsPageChange} pageSize={6} />
                          </>
                        ) : (
                          <p className="text-center">No items found.</p>
                        )}
                      </>
                    )}
                  </Tab>
                  <Tab eventKey="rent" title="Rent">
                    {isAccountMapped ? (
                      <>
                        {isRentLoading ? (
                          <Loading />
                        ) : (
                          <>
                            <ToolbarMarket>
                              <ResultNFT>
                                {countRentLand} {countRentLand > 1 ? 'results' : 'result'}
                              </ResultNFT>
                              <ListGridToggle>
                                <button
                                  className={`grid-list-toggle ${showRent === 'land' ? 'active' : ''}`}
                                  onClick={onShowRentLand}
                                >
                                  Land
                                </button>
                                {/*<button*/}
                                {/*  className={`grid-list-toggle ${showRent === 'item' ? 'active' : ''}`}*/}
                                {/*  onClick={onShowRentItem}*/}
                                {/*>*/}
                                {/*  Items*/}
                                {/*</button>*/}
                              </ListGridToggle>
                            </ToolbarMarket>
                            {rentLand.length > 0 ? (
                              <Row>
                                {rentLand.map(land => {
                                  return (
                                    <Col key={land.tokenId} md={6} lg={4} xl={3}>
                                      <LandListView
                                        layout="grid"
                                        page="rent"
                                        rateHPL={hplPrice}
                                        rateHPW={hpwPrice}
                                        rateBNB={bnbPrice}
                                        land={land}
                                        onRefresh={onRefresh}
                                      />
                                    </Col>
                                  )
                                })}
                                {/*<Pagination items={filteredLands} onPageChange={onLandsPageChange} pageSize={12} />*/}
                              </Row>
                            ) : (
                              <Row>
                                <p className="text-center">No lands found.</p>
                              </Row>
                            )}
                          </>
                        )}
                      </>
                    ) : (
                      <EmptyPage>
                        <p>
                          To rent the lands, you need to <Link to="/dashboard">create an account here</Link>
                        </p>
                      </EmptyPage>
                    )}
                  </Tab>
                </StyledTab>
              )}
            </>
          </Row>
        </Container>
      </Main>
    </>
  )
}

export default Marketplace
