import { useRef, useState } from 'react'
import { Zoom, toast } from 'react-toastify'
import ToastMessage from '../../../components/ToastMessage'
import Modal from '../../../components/Modal'
import Button from '../../../components/Button'
import { BalanceText, DepositWrapper, DepositInputGroup, DepositInputInfo, Input, StyledInputGroup } from '../Styled'
import { useActiveWeb3React, useGameControlContract } from '../../../hooks'
import config from '../../../config/config.json'
import axios, { AxiosError } from 'axios'
import { fromWei } from '../../../utils'
import BigNumber from 'bignumber.js'

interface IUnlockTokenModalProps {
  hplAmount: number
  hpwAmount: number
  _hplSpent: number
  _hpwSpent: number
  show: boolean
  onHide: () => void
  onRefresh: () => void
}

function UnlockTokenModal(props: IUnlockTokenModalProps): JSX.Element {
  const { show, hplAmount, hpwAmount, _hplSpent, _hpwSpent, onHide, onRefresh } = props
  const [isLoading, setLoading] = useState(false)
  const [strHPLAmount, setStrHPLAmount] = useState<any>('0')
  const [strHPWAmount, setStrHPWAmount] = useState<any>('0')

  const { account, chainId } = useActiveWeb3React()
  const networkId = chainId ?? Number(process.env.REACT_APP_CHAIN_ID)
  const apiURL = config.api[networkId]
  const gameContract = useGameControlContract(config.contracts[networkId].GameControl)

  const ref = useRef()

  const closeModal = () => {
    onHide()
  }

  const onUnlockTokens = async () => {
    try {
      setLoading(true)

      if (account && gameContract) {
        const response = await axios.post(`${apiURL}/games/requestWithdrawToken/${account}`, {
          hplAmount: strHPLAmount,
          hpwAmount: strHPWAmount,
          hplSpent: new BigNumber(_hplSpent).dividedBy(1e18),
          hpwSpent: new BigNumber(_hpwSpent).dividedBy(1e18),
        })

        if (response.status === 200 && response.data) {
          const { hplSpent, hplWithdrawAmount, hpwSpent, hpwWithdrawAmount, expiry, r, s, v } = response.data
          const receipt = await gameContract.methods
            .withdrawTokens(hplSpent, hplWithdrawAmount, hpwSpent, hpwWithdrawAmount, expiry, r, s, v)
            .send({ from: account })

          if (receipt) {
            onRefresh()

            toast.success(
              <ToastMessage
                color="success"
                bodyText="All your HPL & HPW have been unlock successfully"
                link={`${config.explorerURL[networkId]}/tx/${receipt.transactionHash}`}
                linkText="View Transaction text"
              />,
              {
                toastId: 'onUnlockTokens',
                position: 'bottom-right',
                autoClose: 5000,
                hideProgressBar: true,
                transition: Zoom,
              },
            )
          }
        }
      }
    } catch (error: any) {
      if (axios.isAxiosError(error)) {
        const _e = error as AxiosError
        const message = _e.response?.data.errors
        toast.error(
          <ToastMessage color="error" bodyText={message ? message.toUpperCase() : 'Could not unlock tokens.'} />,
          {
            toastId: 'onUnlockTokens',
            position: 'bottom-right',
            autoClose: 5000,
            hideProgressBar: true,
            transition: Zoom,
          },
        )
      } else {
        // we only care if the error is something _other_ than the user rejected the tx
        if (error?.code !== 4001) {
          toast.error(<ToastMessage color="error" bodyText="Could not unlock tokens." />, {
            toastId: 'onUnlockTokens',
            position: 'bottom-right',
            autoClose: 5000,
            hideProgressBar: true,
            transition: Zoom,
          })
        }
      }
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  const onHPLAmountChange = (e: any) => {
    const { value } = e.currentTarget
    setStrHPLAmount(value)
  }

  const onHPWAmountChange = (e: any) => {
    const { value } = e.currentTarget
    setStrHPWAmount(value)
  }

  const onFocus = (e: any) => {
    e.target.select()
  }

  const onMaxHPL = () => {
    setStrHPLAmount(new BigNumber(hplAmount).dividedBy(1e18).toString())
  }

  const onMaxHPW = () => {
    setStrHPWAmount(new BigNumber(hpwAmount).dividedBy(1e18).toString())
  }

  const onKeyUpUnlockTokens = e => {
    if (e.keyCode === 13) {
      onUnlockTokens()
    }
  }

  return (
    <Modal show={show} onHide={closeModal} title="Unlock Tokens">
      <DepositWrapper>
        <DepositInputGroup>
          <DepositInputInfo>
            <div className="d-flex justify-content-between w-100">
              <h6>HPL</h6>
              <BalanceText>Balance: {fromWei(hplAmount).toFixed(3)} HPL</BalanceText>
            </div>
          </DepositInputInfo>
          <StyledInputGroup>
            <Input
              ref={ref}
              type="number"
              placeholder="0"
              min="0"
              value={strHPLAmount}
              onChange={onHPLAmountChange}
              onFocus={onFocus}
              borderwidth="0"
              paddinginput="0"
            />
            <Button color="secondary" onClick={onMaxHPL}>
              Max
            </Button>
          </StyledInputGroup>
        </DepositInputGroup>
        <div className="d-flex justify-content-center w-100">
          <svg viewBox="0 0 24 24" fill="#fff" width="20px" xmlns="http://www.w3.org/2000/svg">
            <path d="M18 13H13V18C13 18.55 12.55 19 12 19C11.45 19 11 18.55 11 18V13H6C5.45 13 5 12.55 5 12C5 11.45 5.45 11 6 11H11V6C11 5.45 11.45 5 12 5C12.55 5 13 5.45 13 6V11H18C18.55 11 19 11.45 19 12C19 12.55 18.55 13 18 13Z" />
          </svg>
        </div>
        <DepositInputGroup>
          <DepositInputInfo>
            <div className="d-flex justify-content-between w-100">
              <h6>HPW</h6>
              <BalanceText>Balance: {fromWei(hpwAmount).toFixed(3)} HPW</BalanceText>
            </div>
          </DepositInputInfo>
          <StyledInputGroup>
            <Input
              type="number"
              placeholder="0"
              min="0"
              value={strHPWAmount}
              onChange={onHPWAmountChange}
              onFocus={onFocus}
              onKeyUp={onKeyUpUnlockTokens}
              borderwidth="0"
              paddinginput="0"
            />
            <Button color="secondary" onClick={onMaxHPW}>
              Max
            </Button>
          </StyledInputGroup>
        </DepositInputGroup>
        <Button onClick={onUnlockTokens} disabled={isLoading} loading={isLoading}>
          Confirm
        </Button>
      </DepositWrapper>
    </Modal>
  )
}

export default UnlockTokenModal
