import { useCallback } from 'react'
import { useActiveWeb3React } from './useWeb3'
import { useMasterChefContract } from './useContract'
// import { fromWei } from '../utils'
import config from '../config/config.json'

export const usePendingRewards = (poolId: number): (() => Promise<any>) => {
  const { account, chainId } = useActiveWeb3React()
  const networkId = chainId ?? Number(process.env.REACT_APP_CHAIN_ID)
  const masterChefContract = useMasterChefContract(config.contracts[networkId].MasterChef)

  const callback = useCallback(async (): Promise<any> => {
    let result = {}
    if (account && masterChefContract) {
      let _rewards = 0

      if (masterChefContract) {
        _rewards = await masterChefContract.methods.pendingRewards(poolId, account).call()
      }

      result = _rewards
    }

    return result
  }, [account, masterChefContract])

  return callback
}
