import { useEffect, useState } from 'react'
import Land from '../../types/Land'
import Modal from '../../components/Modal'
import Button from '../../components/Button'
import {
  AttributeTitle,
  LandID,
  LandImage,
  LandName,
  ListViewContent,
  ListViewDescription,
  ListViewDescriptionItem,
  ListViewImg,
  ListViewNFT,
  Ribbon,
} from '../../components/NftItem/Styled'
import { ItemWrapper, NFTItemWrapper } from './Styled'
import { NFTList, SmallNFTID } from '../PlayToEarn/Styled'

interface IExpandItemProps {
  isLoading: boolean
  title: string
  lands: Land[]
  onSelectLandCallback: (landId: number, index: number) => void
  resetRef: any
}

function ExpandItem(props: IExpandItemProps): JSX.Element {
  const { isLoading, title, lands, onSelectLandCallback, resetRef } = props

  const [isShowLandModal, setShowLandModal] = useState(false)
  const [selectedLand, setSeletectedLand] = useState<Land>()
  const [filteredLands, setFilteredLands] = useState<Land[]>([])

  // @ts-ignore
  const imagesDir = require.context(`../../assets/images/`, true)

  useEffect(() => {
    if (selectedLand) {
      const _filteredLands = lands.filter(l => l.tokenId != selectedLand.tokenId)
      setFilteredLands(_filteredLands)
    } else {
      setFilteredLands(lands)
    }
  }, [isShowLandModal])

  const onReset = () => {
    setSeletectedLand(undefined)
    setFilteredLands(lands)
  }

  useEffect(() => {
    resetRef.current = onReset
  }, [])

  const onSelectLand = (landId: number) => {
    const _land = lands.find(l => l.tokenId === landId)
    let oldLandId = -1

    if (selectedLand) {
      oldLandId = selectedLand.tokenId
    }

    if (_land) {
      setSeletectedLand(_land)
    }

    setShowLandModal(false)
    onSelectLandCallback(landId, oldLandId)
  }
  return (
    <>
      {selectedLand ? (
        <ListViewNFT layout="grid">
          <ListViewImg layout="grid">
            <LandImage src={imagesDir(`./lands/${selectedLand.land}.png`).default} alt={selectedLand.land} />
            <LandID theme={selectedLand.land}>{`#${selectedLand.tokenId}`}</LandID>
          </ListViewImg>
          <ListViewContent layout="grid">
            <Ribbon>{title}</Ribbon>
            <LandName theme={selectedLand.land}>{selectedLand.land}</LandName>
            <ListViewDescription layout="grid">
              <ListViewDescriptionItem>
                <AttributeTitle>
                  Area Size: <span>{selectedLand.areaSize}</span>
                </AttributeTitle>
              </ListViewDescriptionItem>
              <ListViewDescriptionItem>
                <AttributeTitle>
                  Fish Ponds: <span>{selectedLand.maxFishPonds}</span>
                </AttributeTitle>
              </ListViewDescriptionItem>
              <ListViewDescriptionItem>
                <AttributeTitle>
                  Plots: <span>{selectedLand.maxPlots}</span>
                </AttributeTitle>
              </ListViewDescriptionItem>
              <ListViewDescriptionItem>
                <AttributeTitle>
                  Animal Barns: <span>{selectedLand.maxAnimalBarns}</span>
                </AttributeTitle>
              </ListViewDescriptionItem>
            </ListViewDescription>
          </ListViewContent>
          <div className="pt-3 pb-4 text-center">
            <Button disabled={lands.length === 0} onClick={() => setShowLandModal(true)}>
              Select another Land
            </Button>
          </div>
        </ListViewNFT>
      ) : (
        <ItemWrapper>
          <div>
            <Ribbon>{title}</Ribbon>
            <Button
              loading={isLoading}
              disabled={isLoading || lands.length === 0}
              onClick={() => setShowLandModal(true)}
            >
              Select a Land
            </Button>
          </div>
        </ItemWrapper>
      )}
      <Modal show={isShowLandModal} onHide={() => setShowLandModal(false)} title="Select a land">
        {lands.length > 0 && (
          <NFTList>
            {filteredLands.map(land => {
              return (
                <NFTItemWrapper key={land.tokenId}>
                  <h6>
                    <SmallNFTID>#{land.tokenId}</SmallNFTID>
                    {land.land}
                  </h6>
                  <Button loading={isLoading} disabled={!land.isExpandable} onClick={() => onSelectLand(land.tokenId)}>
                    Select
                  </Button>
                </NFTItemWrapper>
              )
            })}
          </NFTList>
        )}
      </Modal>
    </>
  )
}

export default ExpandItem
