import styled from 'styled-components/macro'

export const ItemWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  flex-flow: row nowrap;
  flex-direction: column;
  flex-flow: column nowrap;
  align-items: center;
  margin-bottom: 30px;
  padding: 20px;
  border-radius: 15px;
  color: #fff;
  background: linear-gradient(
    180deg,
    rgba(125, 60, 0, 0.15) 3.12%,
    rgba(73, 22, 0, 0.8) 57.81%,
    rgba(0, 0, 0, 0.9) 100%
  );
  border: 1px solid #beb8b4;
  box-sizing: border-box;
  box-shadow: 0 6px 0 #471712, inset 0 4px 0 rgba(255, 220, 95, 0.7);
  padding: 5rem 0;
`
export const NFTItemWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 15px 5px;
  border-bottom: 1px solid #ffffff1f;
`
export const ButtonWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;

  button {
    margin: 0 0.5rem;
  }
`
export const BreedCommitWrapper = styled.div`
  background: #110f22;
  padding: 4rem 1.5rem;
  border-radius: 10px;
  border: 1px solid #000;

  @media (min-width: 992px) {
    padding: 3.5rem 2.5rem;
  }
`
export const ExpandInfoHeader = styled.div`
  margin-bottom: 1rem;
  display: none;
  border-bottom: 1px solid #ffffff2b;
  padding-bottom: 1rem;
  align-items: center;
  justify-content: space-between;
  font-weight: 600;

  @media (min-width: 992px) {
    display: flex;
    font-size: 15px;
  }

  @media (min-width: 1200px) {
    font-size: 16px;
  }
`
export const ExpandInfoItemWrapper = styled.div`
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #ffffff2b;
  padding-bottom: 1rem;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  flex: 0 0 100%;

  @media (min-width: 992px) {
    flex-direction: row;
    justify-content: left;
  }

  > div {
    display: flex;
    justify-content: center;
    font-size: 14px;
    padding-bottom: 1rem;

    &::before {
      content: attr(data-title);
      margin-right: 1rem;
      width: 50%;
      text-align: right;
      color: #ffffff66;
    }

    > span {
      display: block;
      width: 50%;
    }

    @media (min-width: 992px) {
      display: block;
      width: 15%;
      padding-bottom: 0;

      &::before {
        display: none;
      }

      > span {
        width: 100%;
      }
    }

    @media (min-width: 1200px) {
      font-size: 16px;
    }
  }
`
export const ExpandInfoLandID = styled.div`
  @media (min-width: 992px) {
    width: 20%;
    flex: 0 0 20%;
  }
`
export const ExpandInfoDate = styled.div`
  @media (min-width: 992px) {
    width: 20%;
    flex: 0 0 20%;
  }
`
export const ExpandInfoAction = styled.div`
  text-align: right;

  @media (min-width: 992px) {
    width: 40%;
    flex: 0 0 40%;
  }
`
export const ExpandInfoScroller = styled.div`
  max-height: 500px;
  overflow: auto;
`
export const ExpandNoteText = styled.p`
  font-size: 14px;
`
