import { useState } from 'react'
import Button from '../../../components/Button'
import UnstakeModal from './UnstakeModal'

interface IUnstakeRowProps {
  poolId: number
  info: any
  index: number
  onRefresh: () => void
}

function UnstakeRow(props: IUnstakeRowProps): JSX.Element {
  const { poolId, info, index, onRefresh } = props

  const [showUnstakeModal, setShowUnstakeModal] = useState(false)

  return (
    <>
      <Button onClick={() => setShowUnstakeModal(true)}>Unstake</Button>
      <UnstakeModal
        show={showUnstakeModal}
        tokenAmount={info.tokenAmount}
        poolId={poolId}
        depositId={index}
        onHide={() => setShowUnstakeModal(false)}
        onRefresh={onRefresh}
      />
    </>
  )
}

export default UnstakeRow
