import { useState } from 'react'
import config from '../../config/config.json'
import { FilterReset, FilterField, FilterInput, FilterSelect } from './Styled'
import { useActiveWeb3React } from '../../hooks'

interface IFilterProps {
  items: any
  onFilter: (any) => void
  onIDChangeCallback: (any) => void
  onResetCallback: (any) => void
}

function Filter(props: IFilterProps): JSX.Element {
  const { items, onFilter, onIDChangeCallback, onResetCallback } = props
  const [filters, setFilters] = useState({})
  const [tokenId, setTokenId] = useState('')
  const [sortBy, setSortBy] = useState(0)
  const [type, setType] = useState('')
  const [paymentToken, setPaymentToken] = useState('')
  const { chainId } = useActiveWeb3React()
  const networkId = chainId ?? Number(process.env.REACT_APP_CHAIN_ID)

  const sortData = (_items: any, _sortBy: number) => {
    let _sortedItems = [..._items]
    switch (_sortBy) {
      case 1:
        _sortedItems.sort((a, b) => a.price - b.price)
        break
      case 2:
        _sortedItems.sort((a, b) => b.price - a.price)
        break
      case 3:
        _sortedItems.sort((a, b) => a.tokenId - b.tokenId)
        break
      case 4:
        _sortedItems.sort((a, b) => b.tokenId - a.tokenId)
        break
      case 0:
      default:
        _sortedItems = [..._items]
        break
    }

    return _sortedItems
  }

  const filterData = (_filters: any, _sortBy: number): any => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const _newFilters = Object.fromEntries(Object.entries(_filters).filter(([_, v]) => v !== ''))
    const _products = items.filter(p => {
      return Object.entries(_newFilters).every(([filter, value]) => {
        if (filter === 'tokenId') {
          return p[filter] === Number(value)
        }

        return p[filter] === value
      })
    })
    // onFilter(_products)
    // return _products

    const sortedProducts = sortData(_products, _sortBy)
    onFilter(sortedProducts)
    return sortedProducts
  }

  const onSortChange = e => {
    const { value } = e.currentTarget
    const _sortBy = Number(value)
    const _filteredProducts = filterData(filters, _sortBy)
    setSortBy(_sortBy)
    onFilter(_filteredProducts)
  }

  function onIDChange(e) {
    const { value } = e.currentTarget
    const _filters = {
      ...filters,
      tokenId: value,
    }
    setTokenId(value)
    setFilters(_filters)
    const products = filterData(_filters, sortBy)
    onIDChangeCallback(products)
  }

  const onPaymentTokenChange = e => {
    const { value } = e.currentTarget
    const _filters = {
      ...filters,
      paymentToken: value,
    }
    setPaymentToken(value)
    setFilters(_filters)
    filterData(_filters, sortBy)
  }

  const onLandTypeChange = e => {
    const { value } = e.currentTarget
    const _filters = {
      ...filters,
      land: value,
    }
    setType(value)
    setFilters(_filters)
    filterData(_filters, sortBy)
  }

  const onReset = () => {
    if (Object.keys(filters).length) {
      setPaymentToken('')
      setTokenId('')
      setType('')
      setFilters({})
      onResetCallback(items)
    }
  }

  return (
    <div>
      <FilterField>
        <h6>ID</h6>
        <FilterInput autoFocus placeholder="Search by ID Land" onChange={onIDChange} value={tokenId} />
      </FilterField>
      <FilterField>
        <h6>Currency</h6>
        <FilterSelect onChange={e => onPaymentTokenChange(e)} value={paymentToken}>
          <option value="">Default</option>
          <option value={config.payments[networkId].HPL}>HPL</option>
          <option value={config.payments[networkId].BUSD}>BUSD</option>
          <option value={config.payments[networkId].BNB}>BNB</option>
          <option value={config.payments[networkId].HPW}>HPW</option>
        </FilterSelect>
      </FilterField>
      <FilterField>
        <h6>Land</h6>
        <FilterSelect onChange={e => onLandTypeChange(e)} value={type}>
          <option value="">Default</option>
          <option value="highland">Highland</option>
          <option value="valley">Valley</option>
          <option value="woodland">Woodland</option>
          <option value="meadow">Meadow</option>
          <option value="euphoria">Euphoria</option>
        </FilterSelect>
      </FilterField>
      {paymentToken !== '' && (
        <FilterField>
          <h6>Sort by</h6>
          <FilterSelect onChange={e => onSortChange(e)} value={sortBy}>
            <option value="0">Default</option>
            <option value="1">Lowest Price</option>
            <option value="2">Highest Price</option>
            <option value="3">Lowest ID</option>
            <option value="4">Highest ID</option>
          </FilterSelect>
        </FilterField>
      )}
      <FilterReset className="mb-5" onClick={onReset}>
        Reset
      </FilterReset>
    </div>
  )
}

export default Filter
