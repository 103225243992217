import { useCallback } from 'react'
import { useMasterChefContract } from './useContract'
import config from '../config/config.json'
import { useActiveWeb3React } from './useWeb3'
import { fromWei } from '../utils'

export const useHPLPerYear = (poolInfo: number): (() => Promise<number>) => {
  const { chainId } = useActiveWeb3React()
  const networkId = chainId ?? Number(process.env.REACT_APP_CHAIN_ID)
  const masterChefContract = useMasterChefContract(config.contracts[networkId].MasterChef)

  const callback = useCallback(async (): Promise<any> => {
    let result = {}
    try {
      let _hplPerBlock = 0
      let _totalAllocPoint = 0
      let _allocPointCallback: any
      let _allocPoint = '0'

      if (masterChefContract) {
        _hplPerBlock = await masterChefContract.methods.hplPerBlock().call()
        _totalAllocPoint = await masterChefContract.methods.totalAllocPoint().call()
        _allocPointCallback = await masterChefContract.methods.poolInfo(poolInfo).call()
        _allocPoint = _allocPointCallback.allocPoint
      }
      result = Number(fromWei(_hplPerBlock)) * 28800 * 365 * (Number(_allocPoint) / _totalAllocPoint)
    } catch (error: any) {
      console.error(error)
    }
    return result
  }, [])
  return callback
}
