import styled from 'styled-components/macro'
import { Row, Col, InputGroup, FormControl, Tabs } from 'react-bootstrap'
import { Link } from 'react-router-dom'

export const StyledStakeRow = styled(Row)`
  padding-top: 60px;
`
export const StakeCol = styled(Col)`
  margin-bottom: 30px;
`
export const StyledTabs = styled(Tabs)`
  display: inline-flex;
  justify-content: center;
  padding: 3px;
  grid-gap: 3px;
  gap: 3px;
  margin-bottom: 2rem;
  border: 1px solid rgba(255, 255, 255, 0.6);
  border-radius: 4px;

  .nav-item {
    flex-basis: 0;
    flex-grow: 1;
    text-align: center;
    button {
      width: 100%;
      padding-left: 0;
      padding-right: 0;
      font-size: 18px;
      border-radius: 4px;
    }
  }

  .nav-link {
    color: #fff;
  }

  .nav-link.active {
    background-color: ${props => props.theme.color.orange[300]};
    border-color: ${props => props.theme.color.orange[300]};
    color: #fff;
  }
`
export const StakeWrapper = styled.div`
  background: #110f22;
  height: 100%;
  padding: 2rem 1.5rem 2.5rem;
  border-radius: 10px;
  border: 1px solid #000;
  text-align: center;
`
export const StakeWrapperFlex = styled(StakeWrapper)`
  display: flex;
  flex-direction: column;
  .tab-content {
    flex-grow: 1;
    & > .active {
      display: flex;
      flex-direction: column;
      height: 100%;
    }
  }
`
export const Icons = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
`
export const Icon = styled.img`
  width: 30px;
`
export const StakeTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  font-size: 24px;
`
export const StakeTitleText = styled.span`
  margin-left: 10px;
  font-family: 'SVN-SAF', sans-serif;
  font-size: 20px;
  line-height: 1;
`
export const LineGap = styled.hr`
  margin: 1.5rem 0;
  background: rgba(255, 255, 255, 0.5);
`
export const StakeLink = styled(Link)`
  width: 100%;
  height: 45px;
  background-color: #ffc11b;
  border: 1px solid #ffc11b;
  padding: 0.5rem 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  line-height: 1rem;
  border-radius: 4px !important;
  font-size: 18px;
  font-weight: 500;
  color: #982f34;

  &:hover,
  &:focus,
  &:active {
    color: #fff;
    background: #982f34;
    border-color: #982f34;
  }
`
export const StakeFooter = styled.div`
  width: 100%;
  margin-bottom: 24px;
  line-height: 1.6;
  font-size: 20px;
  text-align: center;
  color: #fff;
  span {
    padding-left: 3px;
    font-size: 15px;
    color: #c7bbbb;
  }
`
export const StakeFooterTitle = styled.div`
  margin-bottom: 1rem;
  font-size: 1.125rem;
  font-weight: 600;
  text-align: left;
`
export const StakeFooterItem = styled.div`
  display: flex;
  margin-bottom: 3px;
  justify-content: space-between;
  font-size: 1rem;
`
export const StakeFooterDotted = styled.div`
  flex-grow: 1;
  background-image: linear-gradient(90deg, #fff 30%, hsla(0, 0%, 100%, 0) 0);
  margin: 10px 3px 0 3px;
  background-position: 50%;
  background-size: 5px 1px;
  background-repeat: repeat-x;
`
export const LpIcons = styled(Icons)`
  img:first-child {
    width: 30px;
    margin-left: 0;
    margin-right: -20px;
    margin-top: -25px;
  }
`
export const StakeNumber = styled.p`
  margin: 0;
  font-size: 20px;
  font-weight: 500;
`
export const LockDurationRange = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  color: #888;
`
export const EstApy = styled.div`
  margin-top: 12px;
  text-align: left;
  color: #888;
  font-size: 14px;
  span {
    color: #fff;
  }
`
export const StakeButtons = styled.div`
  margin-top: 1.5rem;
  width: 100%;

  button {
    width: 100%;
    height: 45px;
  }
`
// export const ClaimCountdownText = styled.div`
//   margin-top: 2rem;
//   border: 1px solid ${props => props.theme.color.orange[300]};
//   padding: 0 1rem;
//   border-radius: 4px;
//   opacity: 0.7;
//   width: 100%;
//   height: 34px;
//   line-height: 34px;
//   cursor: pointer;
//   text-align: center;
//   font-size: 14px;
//   font-weight: 600;
//   color: ${props => props.theme.color.orange[300]};
//
//   span {
//     font-size: 14px;
//     font-weight: 600;
//     color: ${props => props.theme.color.orange[300]};
//   }
// `
export const BalanceText = styled.p`
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
  color: #888;
  text-align: right;
  font-size: 15px;
  font-weight: 400;
`
export const InputGroupStyled = styled(InputGroup)`
  align-items: center;
  border: 1px solid #fff;
  border-radius: 4px;
  padding-right: 10px;
  img {
    margin: 0 3px;
  }
  button {
    padding: 5px 10px;
    font-size: 13px;
  }
`
export const Input = styled(FormControl)`
  box-shadow: none !important;
  border-radius: 0;
  border: none;
  padding-right: 0;
  background-color: transparent !important;
  color: #fff !important;
  height: 60px;
  font-size: 17px;
  @media (min-width: 768px) {
    font-size: 20px;
  }

  &:placeholder-shown + p {
    display: none;
  }
`

/* Locked Rewards */
export const WithdrawHeading = styled.div`
  display: flex;
  margin-bottom: 0.5rem;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid #ffffff44;
  color: #fff;
  width: 100%;
  font-size: 17px;
  font-weight: 600;
`
export const WithdrawDiv = styled.div`
  max-height: 210px;
  width: 100%;
  overflow: auto;
`
export const StyledWithdrawRow = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
  padding-bottom: 0.5rem;
  width: 100%;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  font-size: 16px;
  &:first-child {
    margin-top: 10px;
  }
  &:last-child {
    border-bottom: none;
  }
`
export const WithdrawAmount = styled.span`
  text-align: left;
  display: block;
  width: 60%;
  font-weight: 500;
`
export const WithdrawAction = styled.span`
  text-align: right;
  display: block;
  width: 40%;
`
export const WithdrawCountdown = styled.span`
  color: rgba(255, 255, 255, 0.6);
`
export const WithdrawSpan = styled.span`
  cursor: pointer;
  color: ${props => props.theme.color.orange[300]};
`
