import { useRef, useState } from 'react'
import { DigitInput, DuplicateWarning, InputsContainer } from '../../Styled'
import { Ticket, UpdateTicketAction } from './useTicketsReducer'

interface ITicketInput {
  ticket: Ticket
  duplicateWith: number[]
  updateTicket: UpdateTicketAction
  disabled: boolean
}

const getIdLabel = (id: number): string => {
  if (id < 10) return `#00${id}`
  if (id < 100) return `#0${id}`
  return `#${id}`
}

function TicketInput(props: ITicketInput): JSX.Element {
  const { ticket, duplicateWith, updateTicket, disabled } = props

  const [focused, setFocused] = useState(false)
  const containerRef = useRef<HTMLDivElement>(null)
  const digit1 = useRef<HTMLInputElement>(null)
  const digit2 = useRef<HTMLInputElement>(null)
  const digit3 = useRef<HTMLInputElement>(null)
  const digit4 = useRef<HTMLInputElement>(null)
  const digit5 = useRef<HTMLInputElement>(null)
  const digit6 = useRef<HTMLInputElement>(null)

  const digitRefs = [digit1, digit2, digit3, digit4, digit5, digit6]

  const scrollInputIntoView = () => {
    if (containerRef.current) {
      containerRef.current.scrollIntoView({ block: 'center', behavior: 'smooth' })
    }
  }

  const onPasteHandler = (e: React.ClipboardEvent) => {
    e.preventDefault()
    const pasteContent = e.clipboardData.getData('Text')
    if (pasteContent.length <= 6 && /^\d+$/.test(pasteContent)) {
      const filler = Array(6 - pasteContent.length).fill('')
      updateTicket(ticket.id, [...pasteContent.split(''), ...filler])
    }
  }

  const onFocusHandler = () => {
    scrollInputIntoView()
    setFocused(true)
  }

  const onBlurHandler = () => {
    setFocused(false)
  }

  const onChangeHandler = (event: React.KeyboardEvent, digitId: number) => {
    const currentKey = parseInt(event.key, 10)

    if (['e', 'E', '.', ',', '-', 'Unidentified'].includes(event.key)) {
      event.preventDefault()
      return
    }

    // Handling numeric inputs
    if (currentKey >= 0 && currentKey <= 9) {
      event.preventDefault()
      const newNumbers = [...ticket.numbers]
      newNumbers[digitId] = `${currentKey}`
      updateTicket(ticket.id, newNumbers)
      const nextDigitId = digitId + 1
      // if we're not on the last digit - auto-tab
      const nextInput = digitRefs[nextDigitId]
      if (nextDigitId !== 6 && nextInput.current) {
        nextInput.current.focus()
      }
    }

    if (event.key === 'Backspace') {
      event.preventDefault()
      // If some number is there - delete the number
      if (ticket.numbers[digitId]) {
        const newNumbers = [...ticket.numbers]
        newNumbers[digitId] = ''
        updateTicket(ticket.id, newNumbers)
      } else {
        // if the cell is empty and user presses backspace - remove previous
        const prevDigitId = digitId - 1
        const nextInput = digitRefs[prevDigitId]
        // prevent focusing on non-existent input
        if (prevDigitId !== -1 && nextInput.current) {
          nextInput.current.focus()
          const newNumbers = [...ticket.numbers]
          newNumbers[prevDigitId] = ''
          updateTicket(ticket.id, newNumbers)
        }
      }
    }

    if (event.key === 'Delete') {
      event.preventDefault()
      if (ticket.numbers[digitId]) {
        const newNumbers = [...ticket.numbers]
        newNumbers[digitId] = ''
        updateTicket(ticket.id, newNumbers)
      } else {
        // if the cell is empty and user presses delete - remove next
        const nextDigitId = digitId + 1
        const nextInput = digitRefs[nextDigitId]
        // prevent focusing on non-existent input
        if (nextDigitId !== 6 && nextInput.current) {
          nextInput.current.focus()
          const newNumbers = [...ticket.numbers]
          newNumbers[nextDigitId] = ''
          updateTicket(ticket.id, newNumbers)
        }
      }
    }

    if (event.key === 'ArrowLeft') {
      event.preventDefault()
      const prevDigitId = digitId - 1
      const nextInput = digitRefs[prevDigitId]
      // prevent focusing on non-existent input
      if (prevDigitId !== -1 && nextInput.current) {
        nextInput.current.focus()
      }
    }

    if (event.key === 'ArrowRight') {
      event.preventDefault()
      const nextDigitId = digitId + 1
      const nextInput = digitRefs[nextDigitId]
      // prevent focusing on non-existent input
      if (nextDigitId !== 6 && nextInput.current) {
        nextInput.current.focus()
      }
    }
  }

  return (
    <>
      <div className="d-flex justify-content-between">
        <p className="mb-0 text-small">{getIdLabel(ticket.id)}</p>
        <DuplicateWarning>{duplicateWith.length !== 0 && 'Duplicate'}</DuplicateWarning>
      </div>
      <InputsContainer
        ref={containerRef}
        onClick={scrollInputIntoView}
        focused={focused}
        isDuplicate={duplicateWith.length !== 0}
      >
        <DigitInput
          ref={digit1}
          type="number"
          value={ticket.numbers[0]}
          onKeyDown={(e: React.KeyboardEvent) => onChangeHandler(e, 0)}
          placeholder="_"
          onChange={e => e.preventDefault()}
          disabled={disabled}
          onFocus={onFocusHandler}
          onBlur={onBlurHandler}
          onPaste={onPasteHandler}
          inputMode="numeric"
        />
        <DigitInput
          ref={digit2}
          type="number"
          value={ticket.numbers[1]}
          onKeyDown={(e: React.KeyboardEvent) => onChangeHandler(e, 1)}
          placeholder="_"
          onChange={e => e.preventDefault()}
          disabled={disabled}
          onFocus={onFocusHandler}
          onBlur={onBlurHandler}
          onPaste={onPasteHandler}
          inputMode="numeric"
        />
        <DigitInput
          ref={digit3}
          type="number"
          value={ticket.numbers[2]}
          onKeyDown={(e: React.KeyboardEvent) => onChangeHandler(e, 2)}
          placeholder="_"
          onChange={e => e.preventDefault()}
          disabled={disabled}
          onFocus={onFocusHandler}
          onBlur={onBlurHandler}
          onPaste={onPasteHandler}
          inputMode="numeric"
        />
        <DigitInput
          ref={digit4}
          type="number"
          value={ticket.numbers[3]}
          onKeyDown={(e: React.KeyboardEvent) => onChangeHandler(e, 3)}
          placeholder="_"
          onChange={e => e.preventDefault()}
          disabled={disabled}
          onFocus={onFocusHandler}
          onBlur={onBlurHandler}
          onPaste={onPasteHandler}
          inputMode="numeric"
        />
        <DigitInput
          ref={digit5}
          type="number"
          value={ticket.numbers[4]}
          onKeyDown={(e: React.KeyboardEvent) => onChangeHandler(e, 4)}
          placeholder="_"
          onChange={e => e.preventDefault()}
          disabled={disabled}
          onFocus={onFocusHandler}
          onBlur={onBlurHandler}
          onPaste={onPasteHandler}
          inputMode="numeric"
        />
        <DigitInput
          ref={digit6}
          type="number"
          value={ticket.numbers[5]}
          onKeyDown={(e: React.KeyboardEvent) => onChangeHandler(e, 5)}
          placeholder="_"
          onChange={e => e.preventDefault()}
          disabled={disabled}
          onFocus={onFocusHandler}
          onBlur={onBlurHandler}
          onPaste={onPasteHandler}
          inputMode="numeric"
        />
      </InputsContainer>
    </>
  )
}

export default TicketInput
