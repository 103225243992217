import { useCallback, useRef, useState } from 'react'
import { Zoom, toast } from 'react-toastify'
import ToastMessage from '../../../components/ToastMessage'
import Modal from '../../../components/Modal'
import Button from '../../../components/Button'
import { BalanceText, Input, InputGroupStyled, StakeButtons } from '../Styled'
import { useActiveWeb3React, useMasterChefContract } from '../../../hooks'
import config from '../../../config/config.json'
import { formatNumber, fromWei } from '../../../utils'
import BigNumber from 'bignumber.js'

BigNumber.config({ EXPONENTIAL_AT: [-100, 100] })

interface IUnstakeModalProps {
  poolId: number
  depositId: number
  tokenAmount: number
  show: boolean
  onHide: () => void
  onRefresh: () => void
}

function UnstakeModal(props: IUnstakeModalProps): JSX.Element {
  const { show, tokenAmount, poolId, depositId, onHide, onRefresh } = props
  const [isLoading, setLoading] = useState(false)
  const [amount, setAmount] = useState<any>('0')
  const [strTokenAmount, setStrTokenAmount] = useState('0')

  const { account, chainId } = useActiveWeb3React()
  const networkId = chainId ?? Number(process.env.REACT_APP_CHAIN_ID)
  const masterChefContract = useMasterChefContract(config.contracts[networkId].MasterChef)

  const ref = useRef()

  const closeModal = () => {
    onHide()
  }

  const onAmountChange = (e: any) => {
    const { value } = e.currentTarget
    setStrTokenAmount(value)
    setAmount(new BigNumber(value).multipliedBy(1e18).toString() || 0)
  }

  const onBlur = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    () => {
      setStrTokenAmount(fromWei(amount).toString())
    },
    [amount],
  )

  const onFocus = (e: any) => {
    e.target.select()
  }

  const onConfirm = async () => {
    try {
      setLoading(true)
      let receipt: any = {}

      if (masterChefContract) {
        receipt = await masterChefContract.methods.withdraw(poolId, amount, depositId).send({ from: account })
      }

      if (receipt) {
        toast.success(
          <ToastMessage
            color="success"
            bodyText="Unstake successful"
            link={`${config.explorerURL[networkId]}/tx/${receipt.transactionHash}`}
            linkText="View Transaction"
          />,
          {
            toastId: 'onConfirm',
            position: 'bottom-right',
            autoClose: 5000,
            hideProgressBar: true,
            transition: Zoom,
          },
        )

        await onRefresh()
        closeModal()
      }
    } catch (error: any) {
      // we only care if the error is something _other_ than the user rejected the tx
      if (error?.code !== 4001) {
        toast.error(<ToastMessage color="error" bodyText="Could not unstake. Please try again." />, {
          toastId: 'onConfirm',
          position: 'bottom-right',
          autoClose: 5000,
          hideProgressBar: true,
          transition: Zoom,
        })
      }
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  const onKeyUp = (e: any) => {
    if (e.keyCode === 13) {
      onConfirm()
    }
  }

  const onMax = () => {
    const _amount = tokenAmount
    const _amountStr = fromWei(Number(tokenAmount)).toNumber()
    setAmount(_amount)
    setStrTokenAmount(_amountStr.toString())
  }

  return (
    <Modal show={show} onHide={closeModal} title="Unstake Token">
      <div className="d-flex flex-column mt-5">
        <BalanceText>
          <span>Amount</span>
          <span>
            Available: <span style={{ color: '#fff' }}>{formatNumber(fromWei(tokenAmount).toNumber().toFixed(3))}</span>
          </span>
        </BalanceText>
        <InputGroupStyled>
          <Input
            ref={ref}
            type="number"
            placeholder="0"
            min="0"
            value={strTokenAmount}
            onChange={onAmountChange}
            onBlur={onBlur}
            onFocus={onFocus}
            onKeyUp={onKeyUp}
          />
          <div>
            <Button color="secondary" onClick={onMax}>
              Max
            </Button>
          </div>
        </InputGroupStyled>
        <StakeButtons>
          <Button onClick={onConfirm} disabled={isLoading} loading={isLoading}>
            Unstake
          </Button>
        </StakeButtons>
      </div>
    </Modal>
  )
}

export default UnstakeModal
