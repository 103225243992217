import { Container } from 'react-bootstrap'
import { useParams } from 'react-router-dom'
import Main from '../../components/Main'
import StakedLPCards from './components/StakeLPCards'
import { useActiveWeb3React } from '../../hooks'
import config from '../../config/config.json'

function SingleStake(): JSX.Element {
  // @ts-ignore
  const { slug } = useParams()
  const { chainId } = useActiveWeb3React()
  const networkId = chainId ?? Number(process.env.REACT_APP_CHAIN_ID)
  const pools = config.pools[networkId]
  // @ts-ignore
  const pool = pools.find(p => p.slug === slug)

  return (
    <Main>
      <Container>{pool ? <StakedLPCards /> : <p className="text-center">No pools found.</p>}</Container>
    </Main>
  )
}

export default SingleStake
