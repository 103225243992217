import { useState, useEffect } from 'react'
import CountUp from 'react-countup'
import { StakeNumber } from '../Styled'

interface IValueProps {
  value: string | number
  tokenType: string
}

function Value(props: IValueProps): JSX.Element {
  const { value, tokenType } = props
  const [start, updateStart] = useState(0)
  const [end, updateEnd] = useState(0)

  useEffect(() => {
    if (typeof value === 'number') {
      updateStart(end)
      updateEnd(value)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])

  return (
    <StakeNumber>
      {typeof value == 'string' ? value : <CountUp start={start} end={end} duration={1} separator="," decimals={3} />}
      {tokenType && <span style={{ paddingLeft: '5px' }}>{tokenType.toUpperCase()}</span>}
    </StakeNumber>
  )
}

export default Value
