import { useState } from 'react'
import { Zoom, toast } from 'react-toastify'
import Countdown, { zeroPad } from 'react-countdown'
import ToastMessage from '../../../components/ToastMessage'
import LockInfo from '../../../types/LockInfo'
import { formatNumber } from '../../../utils'
import { StyledWithdrawRow, WithdrawAmount, WithdrawAction, WithdrawCountdown, WithdrawSpan } from '../Styled'
import { useActiveWeb3React, useMasterChefContract } from '../../../hooks'
import config from '../../../config/config.json'

interface IWithdrawRowProps {
  lockInfo: LockInfo
  onRefresh: () => void
}

function WithdrawRow(props: IWithdrawRowProps): JSX.Element {
  const { lockInfo, onRefresh } = props

  const [isWithdrawing, setWithdrawing] = useState(false)

  const { account, chainId } = useActiveWeb3React()
  const networkId = chainId ?? Number(process.env.REACT_APP_CHAIN_ID)
  const masterChefContract = useMasterChefContract(config.contracts[networkId].MasterChef)

  const onWithdraw = async index => {
    try {
      setWithdrawing(true)

      if (account && masterChefContract) {
        const receipt = await masterChefContract.methods.unlock(account, index).send({
          from: account,
        })

        if (receipt) {
          onRefresh()

          toast.success(
            <ToastMessage
              color="success"
              bodyText="Withdraw success!"
              link={`${config.explorerURL[networkId]}/tx/${receipt.transactionHash}`}
              linkText="View Transaction"
            />,
            {
              toastId: 'onWithdraw',
              position: 'bottom-right',
              autoClose: 5000,
              hideProgressBar: true,
              transition: Zoom,
            },
          )
        }
      }
    } catch (error: any) {
      // we only care if the error is something _other_ than the user rejected the tx
      if (error?.code !== 4001) {
        toast.error(<ToastMessage color="error" bodyText="Could not withdraw. Please try again." />, {
          toastId: 'onWithdraw',
          position: 'bottom-right',
          autoClose: 5000,
          hideProgressBar: true,
          transition: Zoom,
        })
      }
      console.error(error)
    } finally {
      setWithdrawing(false)
    }
  }

  return (
    <StyledWithdrawRow>
      <WithdrawAmount>{`${formatNumber(lockInfo.amount.toFixed(3))} ${lockInfo.tokenSymbol}`}</WithdrawAmount>
      <WithdrawAction>
        <Countdown
          date={lockInfo.unlockableAt * 1000}
          zeroPadTime={2}
          renderer={props2 =>
            props2.completed ? (
              <WithdrawSpan onClick={() => onWithdraw(lockInfo.index)}>
                {isWithdrawing ? 'Confirming...' : 'Withdraw'}
              </WithdrawSpan>
            ) : (
              <WithdrawCountdown>
                {zeroPad(props2.days)}d:{zeroPad(props2.hours)}h:{zeroPad(props2.minutes)}m:{zeroPad(props2.seconds)}s
              </WithdrawCountdown>
            )
          }
        />
      </WithdrawAction>
    </StyledWithdrawRow>
  )
}

export default WithdrawRow
