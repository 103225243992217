import { Tabs } from 'react-bootstrap'
import styled from 'styled-components/macro'

export const StyledTabs = styled(Tabs)`
  width: auto;
  justify-content: center;
  margin-bottom: 3rem;
  padding-right: 0;
  border: none;

  .nav-item {
    border-radius: 0;
    font-family: 'SVN-SAF', sans-serif;
    font-size: 16px;
    line-height: 1;
    @media (min-width: 768px) {
      font-size: 24px;
    }
    @media (min-width: 992px) {
      font-size: 32px;
    }
    .nav-link {
      border-radius: 0;
    }
    &:first-child {
      .nav-link {
        border-radius: 10px 0 0 10px;
      }
    }
    &:last-child {
      .nav-link {
        border-radius: 0 10px 10px 0;
      }
    }
  }

  .nav-link {
    background: #982f34;
    padding: 8px 16px 13px;
    color: #fff;
    border-color: #982f34;
    @media (min-width: 768px) {
      padding: 8px 33px 13px;
    }
  }

  .nav-link.active {
    background-color: #ffc11b;
    border-color: #ffc11b;
    color: #982f34;
    box-shadow: 0 0 30px -2px rgba(255, 193, 27, 0.75);
    -webkit-box-shadow: 0 0 30px -2px rgba(255, 193, 27, 0.75);
    -moz-box-shadow: 0 0 30px -2px rgba(255, 193, 27, 0.75);
  }
`

interface ITabProps {
  defaultActiveKey: string
  children?: string | JSX.Element | JSX.Element[]
}

function StyledTab(props: ITabProps): JSX.Element {
  const { children, defaultActiveKey } = props
  return (
    <StyledTabs defaultActiveKey={defaultActiveKey} transition={false}>
      {children}
    </StyledTabs>
  )
}

export default StyledTab
