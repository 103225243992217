import { useEffect, useRef, useState } from 'react'
import { Container, Row, Col, Tab } from 'react-bootstrap'
import { Zoom, toast } from 'react-toastify'
import axios, { AxiosError } from 'axios'
import ToastMessage from '../../components/ToastMessage'
import Main from '../../components/Main'
import Button from '../../components/Button'
import SectionTitle from '../../components/SectionTitle'
import StyledTab from '../../components/Tab'
import Land from '../../types/Land'
import ExpandItem from './ExpandItem'
import {
  BreedCommitWrapper,
  ButtonWrapper,
  ExpandInfoAction,
  ExpandInfoDate,
  ExpandInfoHeader,
  ExpandInfoLandID,
  ExpandInfoScroller,
  ExpandNoteText,
} from './Styled'
import {
  useActiveWeb3React,
  useNFTsApi,
  NFTApprovalState,
  useApproveNFTCallback,
  useNFTContract,
  useLandExpandContract,
  useApproveCallback,
  ApprovalState,
  useTokenContract,
} from '../../hooks'
import config from '../../config/config.json'
import { fromWei, toWei } from '../../utils'
import ExpandInfoItem from './ExpandInfoItem'

function Expand(): JSX.Element {
  const [isLoading, setLoading] = useState(false)
  const [isExpanding, setExpanding] = useState(false)

  const [lands, setLands] = useState<Land[]>([])
  const [selectedLands, setSeletedLands] = useState<Land[]>([])
  const [availableLands, setAvailableLands] = useState<Land[]>([])

  const [hplFee, setHPLFee] = useState(0)
  const [hpwFee, setHPWFee] = useState(0)
  const [earlyOpenFeePercent, setEarlyOpenFeePercent] = useState(50)
  const [breedingPeriod, setBreedingPeriod] = useState(1800)
  const [breedList, setBreedList] = useState<any[]>([])
  const [completeBreedList, setCompleteBreedList] = useState<any[]>([])

  const resetRef1 = useRef(null)
  const resetRef2 = useRef(null)

  const { account, chainId } = useActiveWeb3React()
  const networkId = chainId ?? Number(process.env.REACT_APP_CHAIN_ID)
  const ntfsCallback = useNFTsApi(account)
  const landContract = useNFTContract(config.contracts[networkId].LandNFT)
  const hplContract = useTokenContract(config.contracts[networkId].HPL)
  const hpwContract = useTokenContract(config.contracts[networkId].HPW)
  const landExpandContract = useLandExpandContract(config.contracts[networkId].LandExpand)

  const [needApproveNFT, setNeedApproveNFT] = useState(true)
  const [isApprovingNFT, setApprovingNFT] = useState(false)
  const [approvalNFT, approveNFTCallback] = useApproveNFTCallback(
    config.contracts[networkId].LandNFT,
    config.contracts[networkId].LandExpand,
  )

  const [needApproveHPL, setNeedApproveHPL] = useState(true)
  const [isApprovingHPL, setApprovingHPL] = useState(false)
  const [approvalHPL, approveHPLCallback] = useApproveCallback(
    config.contracts[networkId].HPL,
    config.contracts[networkId].LandExpand,
  )

  const [needApproveHPW, setNeedApproveHPW] = useState(true)
  const [isApprovingHPW, setApprovingHPW] = useState(false)
  const [approvalHPW, approveHPWCallback] = useApproveCallback(
    config.contracts[networkId].HPW,
    config.contracts[networkId].LandExpand,
  )

  const apiURL = config.api[networkId]

  const fetchNFTs = async () => {
    try {
      setLoading(true)
      const data = await ntfsCallback()

      const { lands: _lands } = data

      if (_lands) {
        const sortedLands = _lands
          .filter(l => !l.saleId && l.landName != 'wildland')
          .sort((a, b) => b.tokenId - a.tokenId)

        const _tokenIds = sortedLands.map(l => {
          return l.tokenId
        })

        if (account && landExpandContract) {
          const _isExpandable = await landExpandContract.methods.isExpandable(account, _tokenIds).call()
          _isExpandable.forEach((l, index) => {
            if (sortedLands[index]) {
              sortedLands[index].isExpandable = _isExpandable[index]
            }
          })
        }
        setLands(sortedLands)
        setAvailableLands(sortedLands)
      } else {
        setLands([])
        setAvailableLands([])
      }
    } catch (error: any) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  const fetchData = async () => {
    try {
      setLoading(true)

      if (account && landExpandContract) {
        const _hplFee = await landExpandContract.methods.hplExpandFee().call()
        const _hpwFee = await landExpandContract.methods.hpwExpandFee().call()
        const _earlyOpenFeePercent = await landExpandContract.methods.earlyOpenFeePercent().call()
        const _breedingPeriod = await landExpandContract.methods.breedingPeriod().call()
        const _completeBreedList = await landExpandContract.methods.getCompleteBreedList(account).call()
        const _breedList = await landExpandContract.methods.getBreedList(account).call()
        const _rBreedList: any[] = []

        setHPLFee(fromWei(_hplFee).toNumber())
        setHPWFee(fromWei(_hpwFee).toNumber())
        setEarlyOpenFeePercent(Number(_earlyOpenFeePercent))
        setBreedingPeriod(Number(_breedingPeriod))
        setCompleteBreedList(_completeBreedList)

        _breedList.forEach((b, index) => {
          _rBreedList.push({
            ...b,
            index,
          })
        })
        setBreedList(_rBreedList)
      }
    } catch (error: any) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchNFTs()
    fetchData()
  }, [account])

  const onSelectLand = (landId: number, oldLandId: number) => {
    const _land = lands.find(l => l.tokenId === landId)
    const _oLand = lands.find(l => l.tokenId === oldLandId)

    if (_land) {
      selectedLands.push(_land)
    }

    if (_oLand) {
      const index = selectedLands.findIndex(l => l.tokenId === oldLandId)
      selectedLands.splice(index, 1)
    }

    setSeletedLands(selectedLands)
    const _availableLands = lands.filter(l => !selectedLands.includes(l))
    setAvailableLands(_availableLands)
  }

  const onCancel = () => {
    setSeletedLands([])
    setAvailableLands(lands)
    // @ts-ignore
    resetRef1.current()
    // @ts-ignore
    resetRef2.current()
  }

  const onApproveNFT = async () => {
    try {
      setApprovingNFT(true)
      const receipt = await approveNFTCallback()

      if (receipt && landContract) {
        toast.success(<ToastMessage color="success" bodyText="Let's expand your land!!" />, {
          toastId: 'onApprove',
          position: 'bottom-right',
          autoClose: 5000,
          hideProgressBar: true,
          transition: Zoom,
        })

        const _isApprovedForAll = await landContract.methods
          .isApprovedForAll(account, config.contracts[networkId].LandExpand)
          .call()

        if (_isApprovedForAll) {
          setNeedApproveNFT(false)
        }
      }
    } catch (error: any) {
      // we only care if the error is something _other_ than the user rejected the tx
      if (error?.code !== 4001) {
        toast.error(<ToastMessage color="error" bodyText="Could not approve. Please try again." />, {
          toastId: 'onApprove',
          position: 'bottom-right',
          autoClose: 5000,
          hideProgressBar: true,
          transition: Zoom,
        })
      }
      console.error(error)
    } finally {
      setApprovingNFT(false)
    }
  }

  const onApproveHPL = async () => {
    try {
      setApprovingHPL(true)
      const receipt = await approveHPLCallback()

      if (receipt && hplContract) {
        toast.success(<ToastMessage color="success" bodyText="Approve HPL success!" />, {
          toastId: 'onApprove',
          position: 'bottom-right',
          autoClose: 5000,
          hideProgressBar: true,
          transition: Zoom,
        })

        const _allowance = await hplContract.methods.allowance(account, config.contracts[networkId].LandExpand).call()

        if (toWei(_allowance).gt(toWei(0))) {
          setNeedApproveHPL(false)
        }
      }
    } catch (error: any) {
      // we only care if the error is something _other_ than the user rejected the tx
      if (error?.code !== 4001) {
        toast.error(<ToastMessage color="error" bodyText="Could not approve. Please try again." />, {
          toastId: 'onApprove',
          position: 'bottom-right',
          autoClose: 5000,
          hideProgressBar: true,
          transition: Zoom,
        })
      }
      console.error(error)
    } finally {
      setApprovingHPL(false)
    }
  }

  const onApproveHPW = async () => {
    try {
      setApprovingHPW(true)
      const receipt = await approveHPWCallback()

      if (receipt && hpwContract) {
        toast.success(<ToastMessage color="success" bodyText="Approve HPW success!" />, {
          toastId: 'onApproveHPW',
          position: 'bottom-right',
          autoClose: 5000,
          hideProgressBar: true,
          transition: Zoom,
        })

        const _allowance = await hpwContract.methods.allowance(account, config.contracts[networkId].LandExpand).call()

        if (toWei(_allowance).gt(toWei(0))) {
          setNeedApproveHPW(false)
        }
      }
    } catch (error: any) {
      // we only care if the error is something _other_ than the user rejected the tx
      if (error?.code !== 4001) {
        toast.error(<ToastMessage color="error" bodyText="Could not approve HPW. Please try again." />, {
          toastId: 'onApproveHPW',
          position: 'bottom-right',
          autoClose: 5000,
          hideProgressBar: true,
          transition: Zoom,
        })
      }
      console.error(error)
    } finally {
      setApprovingHPW(false)
    }
  }

  const onRevoke = async () => {
    if (account && landContract && hplContract && hpwContract) {
      await landContract.methods
        .setApprovalForAll(config.contracts[networkId].LandExpand, false)
        .send({ from: account })
      setNeedApproveNFT(true)

      await hplContract.methods.approve(config.contracts[networkId].LandExpand, 0).send({ from: account })
      await hpwContract.methods.approve(config.contracts[networkId].LandExpand, 0).send({ from: account })
    }
  }

  const onRefresh = async () => {
    try {
      setLoading(true)
      await fetchNFTs()
      await fetchData()
    } catch (error: any) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  const onExpandLand = async () => {
    try {
      setExpanding(true)

      if (landExpandContract && selectedLands) {
        const response = await axios.post(`${apiURL}/expands/requestExpandLands`, {
          land1: selectedLands[0].tokenId,
          land2: selectedLands[1].tokenId,
          player: account,
        })

        if (response.status === 200 && response.data) {
          const { successPercentage, commitment, expired, r, s, v } = response.data

          const receipt = await landExpandContract.methods
            .createBreed(
              selectedLands[0].tokenId,
              selectedLands[1].tokenId,
              successPercentage,
              commitment,
              expired,
              r,
              s,
              v,
            )
            .send({ from: account })

          if (receipt) {
            toast.success(<ToastMessage color="success" bodyText="Transaction confirmed!" />, {
              toastId: 'onExpand',
              position: 'bottom-right',
              autoClose: 5000,
              hideProgressBar: true,
              transition: Zoom,
            })
          }

          onCancel()
          await onRefresh()
        }
      }
    } catch (error: any) {
      if (axios.isAxiosError(error)) {
        const _e = error as AxiosError
        const message = _e.response?.data.errors
        toast.error(
          <ToastMessage
            color="error"
            headerText="Error"
            bodyText={message ? message.toUpperCase() : 'Could not expand your lands.'}
          />,
          {
            toastId: 'onExpand',
            position: 'bottom-right',
            autoClose: 5000,
            hideProgressBar: true,
            transition: Zoom,
          },
        )
      } else {
        // we only care if the error is something _other_ than the user rejected the tx
        if (error?.code !== 4001) {
          toast.error(<ToastMessage color="error" bodyText="Could not expand your lands. Please try again." />, {
            toastId: 'onExpand',
            position: 'bottom-right',
            autoClose: 5000,
            hideProgressBar: true,
            transition: Zoom,
          })
        }
      }
      console.error(error)
    } finally {
      setExpanding(false)
    }
  }

  return (
    <Main>
      <Container>
        <Row className="pb-5 justify-content-center">
          <SectionTitle>Expand Your Land</SectionTitle>
          <Col className="text-center">
            Expand Fee: {hplFee} HPL + {hpwFee} HPW
          </Col>
        </Row>
        <StyledTab defaultActiveKey="expand">
          <Tab eventKey="expand" title="Expand">
            <Row className="pb-3 justify-content-center">
              <Col lg={3}>
                <ExpandItem
                  isLoading={isLoading}
                  title="Land #1"
                  lands={availableLands}
                  onSelectLandCallback={onSelectLand}
                  resetRef={resetRef1}
                />
              </Col>
              <Col lg={3}>
                <ExpandItem
                  isLoading={isLoading}
                  title="Land #2"
                  lands={availableLands}
                  onSelectLandCallback={onSelectLand}
                  resetRef={resetRef2}
                />
              </Col>
            </Row>
            <Row className="justify-content-center">
              <Col>
                <ButtonWrapper>
                  <Button color="secondary" onClick={onCancel}>
                    Cancel
                  </Button>
                  {(needApproveNFT && approvalNFT !== NFTApprovalState.APPROVED) ||
                  (needApproveHPL && approvalHPL !== ApprovalState.APPROVED) ||
                  (needApproveHPW && approvalHPW !== ApprovalState.APPROVED) ? (
                    <>
                      {needApproveNFT && approvalNFT !== NFTApprovalState.APPROVED && (
                        <Button disabled={isApprovingNFT} loading={isApprovingNFT} onClick={onApproveNFT}>
                          Approve Lands
                        </Button>
                      )}
                      {needApproveHPL && approvalHPL !== ApprovalState.APPROVED && (
                        <Button disabled={isApprovingHPW} loading={isApprovingHPL} onClick={onApproveHPL}>
                          Approve HPL
                        </Button>
                      )}
                      {needApproveHPW && approvalHPW !== ApprovalState.APPROVED && (
                        <Button disabled={isApprovingHPW} loading={isApprovingHPW} onClick={onApproveHPW}>
                          Approve HPW
                        </Button>
                      )}
                    </>
                  ) : (
                    <Button
                      disabled={selectedLands.length < 2 || isExpanding}
                      loading={isExpanding}
                      onClick={onExpandLand}
                    >
                      Expand
                    </Button>
                  )}
                  {process.env.NODE_ENV !== 'production' && <Button onClick={onRevoke}>Revoke</Button>}
                </ButtonWrapper>
              </Col>
            </Row>
          </Tab>
          <Tab eventKey="in-progress" title="In progress">
            <Row className="justify-content-center">
              <Col lg={9}>
                <BreedCommitWrapper>
                  <ExpandInfoHeader>
                    <ExpandInfoLandID>Land #1</ExpandInfoLandID>
                    <ExpandInfoLandID>Land #2</ExpandInfoLandID>
                    <ExpandInfoDate>Expanded at</ExpandInfoDate>
                    <ExpandInfoAction>&nbsp;</ExpandInfoAction>
                  </ExpandInfoHeader>
                  <div>
                    {[...breedList].reverse().map(b => {
                      return (
                        <div key={b.createdAt}>
                          {!b.open && (
                            <ExpandInfoItem
                              expandInfo={b}
                              commitmentIndex={b.index}
                              canOpen={Number(b.createdAt) + breedingPeriod < Date.now() / 1000}
                              isComplete={b.open}
                              openTimestamp={Number(b.createdAt) + breedingPeriod}
                              onRefresh={onRefresh}
                            />
                          )}
                        </div>
                      )
                    })}
                  </div>
                  <ExpandNoteText className="mt-5 text-muted text-center">
                    You will have to pay some extra fees if you want to open earlier
                    <br />
                    <span>
                      Early Open Fee: {(hplFee * earlyOpenFeePercent) / 100} HPL +{' '}
                      {(hpwFee * earlyOpenFeePercent) / 100} HPW
                    </span>
                  </ExpandNoteText>
                </BreedCommitWrapper>
              </Col>
            </Row>
          </Tab>
          <Tab eventKey="history" title="History">
            <Row className="justify-content-center">
              <Col lg={9}>
                <BreedCommitWrapper>
                  <ExpandInfoHeader>
                    <ExpandInfoLandID>Land #1</ExpandInfoLandID>
                    <ExpandInfoLandID>Land #2</ExpandInfoLandID>
                    <ExpandInfoDate>Expand at</ExpandInfoDate>
                    <ExpandInfoAction>Result</ExpandInfoAction>
                  </ExpandInfoHeader>
                  <ExpandInfoScroller>
                    {[...completeBreedList].reverse().map((b, index) => {
                      return (
                        <div key={b}>
                          {b.open && (
                            <ExpandInfoItem
                              expandInfo={b}
                              commitmentIndex={index}
                              canOpen={false}
                              isComplete={b.open}
                              openTimestamp={Number(b.createdAt) + breedingPeriod}
                              onRefresh={onRefresh}
                            />
                          )}
                        </div>
                      )
                    })}
                  </ExpandInfoScroller>
                </BreedCommitWrapper>
              </Col>
            </Row>
          </Tab>
        </StyledTab>
      </Container>
    </Main>
  )
}

export default Expand
