import { useCallback } from 'react'
import BigNumber from 'bignumber.js'
import axios from 'axios'
import { fromWei } from '../utils'
import { useTokenContract } from './useContract'
import { useActiveWeb3React } from './useWeb3'
import config from '../config/config.json'

export const useTVL = (poolId: number): (() => Promise<number>) => {
  const { chainId } = useActiveWeb3React()
  const networkId = chainId ?? Number(process.env.REACT_APP_CHAIN_ID)
  const pools = config.pools[networkId]
  // @ts-ignore
  const pool = pools.find(p => p.id === Number(poolId))

  const tokenContract = useTokenContract(config.contracts[networkId].HPL)
  const lpContract = useTokenContract(pool.lpAddress)
  const wethContract = useTokenContract(pool.weth)

  const callback = useCallback(async (): Promise<number> => {
    let result = 0
    try {
      if (tokenContract && lpContract && wethContract) {
        // Get balance of the token address
        const tokenAmountWholeLP = await tokenContract.methods.balanceOf(pool.lpAddress).call()
        // Get the share of lpContract that masterChefContract owns
        const balance = await lpContract.methods.balanceOf(config.contracts[networkId].MasterChef).call()
        // Convert that into the portion of total lpContract = p1
        const totalSupply = await lpContract.methods.totalSupply().call()
        // Get total weth value for the lpContract = w1
        const lpContractWeth = await wethContract.methods.balanceOf(pool.lpAddress).call()

        // Return p1 * w1 * 2
        const portionLp = new BigNumber(balance).div(new BigNumber(totalSupply))

        // Calculate
        const tokenAmount = fromWei(new BigNumber(tokenAmountWholeLP).times(portionLp).toNumber())
        const wethAmount = fromWei(new BigNumber(lpContractWeth).times(portionLp).toNumber())

        const usdValue = wethAmount
        const response = await axios.get(
          'https://api.coingecko.com/api/v3/simple/price?ids=happyland&vs_currencies=usd',
        )

        if (response.status === 200 && response.data) {
          const { usd } = response.data?.['happyland']
          result = usdValue.plus(tokenAmount.times(new BigNumber(usd))).toNumber()
        }
      }
    } catch (error: any) {
      console.error(error)
    }
    return result
  }, [])

  return callback
}
