import { useState } from 'react'
import axios, { AxiosError } from 'axios'
import { format, fromUnixTime } from 'date-fns'
import { Zoom, toast } from 'react-toastify'
import Countdown, { zeroPad } from 'react-countdown'
import Button from '../../components/Button'
import ToastMessage from '../../components/ToastMessage'
import { useActiveWeb3React, useLandExpandContract } from '../../hooks'
import config from '../../config/config.json'
import { ExpandInfoAction, ExpandInfoDate, ExpandInfoItemWrapper, ExpandInfoLandID } from './Styled'

interface IExpandInfoItemProps {
  expandInfo: any
  commitmentIndex: number
  canOpen: boolean
  isComplete: boolean
  openTimestamp: number
  onRefresh: () => void
}

function ExpandInfoItem(props: IExpandInfoItemProps): JSX.Element {
  const { expandInfo, commitmentIndex, canOpen, isComplete, openTimestamp, onRefresh } = props

  const [isOpening, setOpening] = useState(false)

  const { account, chainId } = useActiveWeb3React()
  const networkId = chainId ?? Number(process.env.REACT_APP_CHAIN_ID)
  const landExpandContract = useLandExpandContract(config.contracts[networkId].LandExpand)

  const apiURL = config.api[networkId]

  const onOpenLand = async (openEarly: boolean) => {
    try {
      setOpening(true)

      if (landExpandContract) {
        const { commitment } = expandInfo
        const response = await axios.get(`${apiURL}/expands/secret/${commitment}`)

        if (response.status === 200 && response.data) {
          const { secret } = response.data

          if (landExpandContract) {
            const receipt = await landExpandContract.methods
              .openBreed(secret, commitmentIndex, openEarly)
              .send({ from: account })

            if (receipt) {
              const lastestBreedOpen = await landExpandContract.methods.getLatestBreedOpen(account).call()
              let success = false

              if (lastestBreedOpen) {
                // eslint-disable-next-line prefer-destructuring
                success = lastestBreedOpen.success
              }

              if (success) {
                toast.success(
                  <ToastMessage color="success" bodyText="Congrats! Your lands have been expanded success!" />,
                  {
                    toastId: 'onOpenLand',
                    position: 'bottom-right',
                    autoClose: 5000,
                    hideProgressBar: true,
                    transition: Zoom,
                  },
                )
              } else {
                toast.error(<ToastMessage color="error" bodyText="Unlucky, let's try in the next time!" />, {
                  toastId: 'onOpenLand',
                  position: 'bottom-right',
                  autoClose: 5000,
                  hideProgressBar: true,
                  transition: Zoom,
                })
              }

              onRefresh()
            }
          }
        }
      }
    } catch (error: any) {
      if (axios.isAxiosError(error)) {
        const _e = error as AxiosError
        const message = _e.response?.data.errors
        toast.error(
          <ToastMessage
            color="error"
            headerText="Error"
            bodyText={message ? message.toUpperCase() : 'Could not expand your lands.'}
          />,
          {
            toastId: 'onOpenLand',
            position: 'bottom-right',
            autoClose: 5000,
            hideProgressBar: true,
            transition: Zoom,
          },
        )
      } else {
        // we only care if the error is something _other_ than the user rejected the tx
        if (error?.code !== 4001) {
          toast.error(<ToastMessage color="error" bodyText="Could not open your land. Please try again." />, {
            toastId: 'onOpenLand',
            position: 'bottom-right',
            autoClose: 5000,
            hideProgressBar: true,
            transition: Zoom,
          })
        }
      }
      console.error(error)
    } finally {
      setOpening(false)
    }
  }

  return (
    <ExpandInfoItemWrapper>
      <ExpandInfoLandID data-title="Land #1: ">#{expandInfo.land1}</ExpandInfoLandID>
      <ExpandInfoLandID data-title="Land #2: ">#{expandInfo.land2}</ExpandInfoLandID>
      <ExpandInfoDate data-title="Expanded at: ">
        <span>{format(fromUnixTime(expandInfo.createdAt), 'LLL dd, yyy HH:mm')}</span>
      </ExpandInfoDate>
      <ExpandInfoAction data-title={isComplete ? 'Result' : ''}>
        {isComplete ? (
          <>
            {Number(expandInfo.createdTokenId) > 0 ? (
              <span>WildLand #{expandInfo.createdTokenId}</span>
            ) : (
              <span className="text-muted">Failed</span>
            )}
          </>
        ) : (
          <>
            {canOpen ? (
              <Button disabled={isOpening} loading={isOpening} onClick={() => onOpenLand(false)}>
                Open
              </Button>
            ) : (
              <Countdown
                date={openTimestamp * 1000}
                zeroPadTime={2}
                renderer={props2 =>
                  props2.completed ? (
                    <Button disabled={isOpening} loading={isOpening} onClick={() => onOpenLand(false)}>
                      Open
                    </Button>
                  ) : (
                    <div className="d-flex align-items-center justify-content-end">
                      {zeroPad(props2.hours)}h:{zeroPad(props2.minutes)}m:{zeroPad(props2.seconds)}s &nbsp;
                      <Button disabled={isOpening} loading={isOpening} onClick={() => onOpenLand(true)}>
                        Open Early
                      </Button>
                    </div>
                  )
                }
              />
            )}
          </>
        )}
      </ExpandInfoAction>
    </ExpandInfoItemWrapper>
  )
}

export default ExpandInfoItem
