import { useEffect, useState } from 'react'
import { Row } from 'react-bootstrap'
import TVL from './TVL'
import DailyRewards from './DailyRewards'
import { useMasterChefContract, useActiveWeb3React } from '../../../hooks'
import { fromWei } from '../../../utils'
import config from '../../../config/config.json'
import {
  StakeCol,
  StakeWrapper,
  Icons,
  Icon,
  StakeTitle,
  StakeLink,
  StakeFooter,
  StakeFooterItem,
  StakeFooterDotted,
  LineGap,
  StakeTitleText,
  StakeFooterTitle,
} from '../Styled'
import PendingRewards from './PendingRewards'
import MyLiquidity from './MyLiquidity'
import PoolAPY from './PoolAPY'
import MyAPY from './MyAPY'

function StakeRow(): JSX.Element {
  const [hplPerBlock, setHPLPerBlock] = useState(0)
  const [totalAllocPoint, setTotalAllocPoint] = useState(0)

  const { account, chainId } = useActiveWeb3React()
  const networkId = chainId ?? Number(process.env.REACT_APP_CHAIN_ID)
  let pools = config.pools[networkId]

  pools = pools.filter(p => p.id !== 3)
  const masterChefContract = useMasterChefContract(config.contracts[networkId].MasterChef)

  const fetchData = async () => {
    try {
      let _hplPerBlock = 0
      let _totalAllocPoint = 0

      if (masterChefContract) {
        _hplPerBlock = await masterChefContract.methods.hplPerBlock().call()
        _totalAllocPoint = await masterChefContract.methods.totalAllocPoint().call()
      }

      const _hplPerBlockBN = fromWei(_hplPerBlock)
      setHPLPerBlock(_hplPerBlockBN.toNumber())
      setTotalAllocPoint(_totalAllocPoint)
    } catch (error: any) {
      console.error(error)
    }
  }

  useEffect(() => {
    fetchData()
  }, [masterChefContract])

  return (
    <Row className="justify-content-center">
      {pools.length > 0 && (
        <>
          {pools.map(p => {
            return (
              p && (
                <StakeCol md={6} xl={4} key={`${p.slug}`}>
                  <StakeWrapper>
                    <StakeTitle>
                      <Icons>
                        <Icon src={p.icon} alt="" style={{ width: 36 }} />
                        {p.icon2 && <Icon src={p.icon2} alt="" style={{ width: 28 }} />}
                      </Icons>
                      <StakeTitleText>{p.name}</StakeTitleText>
                    </StakeTitle>
                    <LineGap />
                    <StakeFooter>
                      <StakeFooterTitle>Details</StakeFooterTitle>
                      <TVL poolId={p.id} tokenName={p.name} />
                      <StakeFooterItem>
                        <DailyRewards poolId={p.id} hplPerBlock={hplPerBlock} totalAllocPoint={totalAllocPoint} />
                      </StakeFooterItem>
                      <StakeFooterItem>
                        <PendingRewards poolId={p.id} account={account} chainId={chainId} />
                      </StakeFooterItem>
                      <StakeFooterItem>
                        <div>Base Pool APY</div>
                        <StakeFooterDotted />
                        <div>
                          <PoolAPY poolId={p.id} />
                        </div>
                      </StakeFooterItem>
                      <StakeFooterItem>
                        <div>My APY</div>
                        <StakeFooterDotted />
                        <div>
                          <MyAPY poolId={p.id} account={account} />
                        </div>
                      </StakeFooterItem>
                      <StakeFooterItem>
                        <MyLiquidity poolId={p.id} account={account} tokenName={p.name} />
                      </StakeFooterItem>
                    </StakeFooter>
                    <StakeLink to={`/stake/${p.slug}`}>Select</StakeLink>
                  </StakeWrapper>
                </StakeCol>
              )
            )
          })}
        </>
      )}
    </Row>
  )
}

export default StakeRow
