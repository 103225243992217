import { useEffect, useState } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { Zoom, toast } from 'react-toastify'
import ToastMessage from '../../components/ToastMessage'
import Main from '../../components/Main'
import Loading from '../../components/Loading'
import { useParams } from 'react-router-dom'
import Land from '../../types/Land'
import {
  useActiveWeb3React,
  ApprovalState,
  useApproveCallback,
  useHPLPrice,
  useHPWPrice,
  useBNBPrice,
  useMarketPlaceContract,
} from '../../hooks'
import config from '../../config/config.json'
import { BiChevronLeft } from 'react-icons/all'
import { BackBtn, RentOwner, RentWrapper, RentID, RentTitle, RentStats } from './Styled'
import { ButtonFull, LandImage, LandPriceExchange } from '../../components/NftItem/Styled'
import Button from '../../components/Button'
import { fromWei } from '../../utils'
import axios from 'axios'
import PlotInfo from './components/plotInfo'
import BigNumber from 'bignumber.js'

function BuySingle(): JSX.Element {
  const [isLandMapped, setLandMapped] = useState(false)
  const [buyItem, setBuyItem] = useState<Land>()
  const [hplPrice, setHPLPrice] = useState(0)
  const [hpwPrice, setHPWPrice] = useState(0)
  const [bnbPrice, setBNBPrice] = useState(0)
  const [plotEU, setPlotEU] = useState([])
  const [plotASIA, setPlotASIA] = useState([])

  const [isLoading, setLoading] = useState(false)
  const [isRenting, setRenting] = useState(false)
  const [isApprovingBUSD, setApprovingBUSD] = useState(false)
  const [isApprovingHPL, setApprovingHPL] = useState(false)
  const [isApprovingHPW, setApprovingHPW] = useState(false)
  const [showPlotInfoModal, setShowPlotInfoModal] = useState(false)

  // @ts-ignore
  const { slug } = useParams()
  const { account, chainId } = useActiveWeb3React()
  const networkId = chainId ?? 56
  const apiURL = config.api[networkId]
  const marketplaceContract = useMarketPlaceContract(config.contracts[networkId].Market)
  const [approvalBUSD, approveCallbackBUSD] = useApproveCallback(
    config.payments[networkId].BUSD,
    config.contracts[networkId].Market,
  )

  const [approvalHPL, approveCallbackHPL] = useApproveCallback(
    config.payments[networkId].HPL,
    config.contracts[networkId].Market,
  )

  const [approvalHPW, approveCallbackHPW] = useApproveCallback(
    config.payments[networkId].HPW,
    config.contracts[networkId].Market,
  )
  const hplPriceCallback = useHPLPrice()
  const hpwPriceCallback = useHPWPrice()
  const bnbPriceCallback = useBNBPrice()

  // @ts-ignore
  const imagesDir = require.context(`../../assets/images/`, true)

  const fetchBuy = async () => {
    try {
      setLoading(true)
      const response = await axios.get(`${apiURL}/markets`)

      if (response.status === 200 && response.data) {
        const { markets } = response.data
        const _buyItem = markets.filter(token => token.tokenId === Number(slug))
        if (_buyItem[0]) {
          setLandMapped(true)
          setBuyItem(_buyItem[0])
        }
      }
    } catch (error: any) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  const fetchPlotForLand = async () => {
    try {
      setLoading(true)

      const euPlotURL = networkId === 56 ? 'https://api-eu.happyland.finance' : 'https://api-eu-test.happyland.finance'
      const asiaPlotURL =
        networkId === 56 ? 'https://api-asia.happyland.finance' : 'https://api-asia-test.happyland.finance'

      const responseEU = await axios.post(`${euPlotURL}/cms/api/farm-happyland-finace/getPlotForLand`, {
        landTokenId: slug,
      })

      const responseASIA = await axios.post(`${asiaPlotURL}/cms/api/farm-happyland-finace/getPlotForLand`, {
        landTokenId: slug,
      })

      if (responseEU.status === 200 && responseEU.data) {
        const _responseEU = responseEU.data.data
        const _plotEU = _responseEU.filter(token => token.plantCount > 0)
        setPlotEU(_plotEU)
      }

      if (responseASIA.status === 200 && responseASIA.data) {
        const _responseASIA = responseASIA.data.data
        const _plotASIA = _responseASIA.filter(token => token.plantCount > 0)
        setPlotASIA(_plotASIA)
      }
    } catch (error: any) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchPlotForLand()
    fetchBuy()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account, chainId])

  useEffect(() => {
    const fetchData = async () => {
      try {
        const _hplPrice = await hplPriceCallback()
        const _hpwPrice = await hpwPriceCallback()
        const _bnbPrice = await bnbPriceCallback()
        setBNBPrice(_bnbPrice)
        setHPLPrice(_hplPrice)
        setHPWPrice(_hpwPrice)
      } catch (error: any) {
        console.error(error)
      }
    }

    fetchData()

    const interval = setInterval(() => {
      fetchData()
    }, 1000 * 120)

    return () => clearInterval(interval)
  }, [])

  const onApproveBUSD = async () => {
    try {
      setApprovingBUSD(true)
      const receipt = await approveCallbackBUSD()

      if (receipt && marketplaceContract) {
        const _isApprovedForAll = await marketplaceContract.methods
          .isApprovedForAll(config.payments[networkId].BUSD, config.contracts[networkId].GameControl)
          .call()

        if (_isApprovedForAll) {
          toast.success(<ToastMessage color="success" bodyText="Now you can buy lands using BUSD" />, {
            toastId: 'onApprove',
            position: 'bottom-right',
            autoClose: 5000,
            hideProgressBar: true,
            transition: Zoom,
          })
        }
      }
    } catch (error: any) {
      // we only lande if the error is something _other_ than the user rejected the tx
      if (error?.code !== 4001) {
        toast.error(<ToastMessage color="error" bodyText="Could not approve. Please try again." />, {
          toastId: 'onApprove',
          position: 'bottom-right',
          autoClose: 5000,
          hideProgressBar: true,
          transition: Zoom,
        })
      }
      console.error(error)
    } finally {
      setApprovingBUSD(false)
    }
  }

  const onApproveHPL = async () => {
    try {
      setApprovingHPL(true)
      const receipt = await approveCallbackHPL()

      if (receipt && marketplaceContract) {
        const _isApprovedForAll = await marketplaceContract.methods
          .isApprovedForAll(config.payments[networkId].HPL, config.contracts[networkId].GameControl)
          .call()

        if (_isApprovedForAll) {
          toast.success(<ToastMessage color="success" bodyText="Now you can buy lands using HPL" />, {
            toastId: 'onApprove',
            position: 'bottom-right',
            autoClose: 5000,
            hideProgressBar: true,
            transition: Zoom,
          })
        }
      }
    } catch (error: any) {
      // we only lande if the error is something _other_ than the user rejected the tx
      if (error?.code !== 4001) {
        toast.error(<ToastMessage color="error" bodyText="Could not approve. Please try again." />, {
          toastId: 'onApprove',
          position: 'bottom-right',
          autoClose: 5000,
          hideProgressBar: true,
          transition: Zoom,
        })
      }
      console.error(error)
    } finally {
      setApprovingHPL(false)
    }
  }

  const onApproveHPW = async () => {
    try {
      setApprovingHPW(true)
      const receipt = await approveCallbackHPW()

      if (receipt && marketplaceContract) {
        const _isApprovedForAll = await marketplaceContract.methods
          .isApprovedForAll(config.payments[networkId].HPW, config.contracts[networkId].GameControl)
          .call()

        if (_isApprovedForAll) {
          toast.success(<ToastMessage color="success" bodyText="Now you can buy lands using HPW" />, {
            toastId: 'onApprove',
            position: 'bottom-right',
            autoClose: 5000,
            hideProgressBar: true,
            transition: Zoom,
          })
        }
      }
    } catch (error: any) {
      // we only lande if the error is something _other_ than the user rejected the tx
      if (error?.code !== 4001) {
        toast.error(<ToastMessage color="error" bodyText="Could not approve. Please try again." />, {
          toastId: 'onApprove',
          position: 'bottom-right',
          autoClose: 5000,
          hideProgressBar: true,
          transition: Zoom,
        })
      }
      console.error(error)
    } finally {
      setApprovingHPW(false)
    }
  }

  const onBuyNFT = async () => {
    try {
      setRenting(true)
      // @ts-ignore
      const _price = buyItem.price >= 0 ? new BigNumber(buyItem.price).multipliedBy(1035).dividedBy(1000).toString() : 0
      // @ts-ignore
      if (marketplaceContract && buyItem) {
        // @ts-ignore
        const receipt =
          buyItem.paymentToken === '0xffffffffffffffffffffffffffffffffffffffff'
            ? await marketplaceContract.methods
                .buyNFTWithPrice(buyItem.saleId, _price)
                .send({ value: _price, from: account })
            : await marketplaceContract.methods.buyNFTWithPrice(buyItem?.saleId, _price).send({ from: account })

        if (receipt) {
          toast.success(
            <ToastMessage
              color="success"
              bodyText={`${buyItem.land} #${buyItem.tokenId} has been purchased successfully.`}
              link={`${config.explorerURL[networkId]}/tx/${receipt.transactionHash}`}
              linkText="View Transaction"
            />,
            {
              toastId: 'onSellLand',
              position: 'bottom-right',
              autoClose: 5000,
              hideProgressBar: true,
              transition: Zoom,
            },
          )

          // setTimeout(() => {
          //   onRefresh()
          // }, 500)
        }
      }
    } catch (error: any) {
      if (error?.code !== 4001) {
        toast.error(<ToastMessage color="error" bodyText="Could not buy this land. Please try again." />, {
          toastId: 'onSellLand',
          position: 'bottom-right',
          autoClose: 5000,
          hideProgressBar: true,
          transition: Zoom,
        })
      }
      console.error(error)
    } finally {
      setRenting(false)
    }
  }

  const priceNFT = priceInput => {
    if (priceInput > 0) {
      return fromWei(priceInput).toNumber()
    } else {
      return 0
    }
  }

  const tokenPaymentFn = tokenPayment => {
    switch (tokenPayment) {
      case config.payments[networkId].BNB:
        return 'BNB'
      case config.payments[networkId].HPL:
        return 'HPL'
      case config.payments[networkId].HPW:
        return 'HPW'
      case config.payments[networkId].BUSD:
        return 'BUSD'
      default:
        return null
    }
  }

  return (
    <>
      <Main>
        <Container>
          <Row className="pb-5 justify-content-center">
            {isLoading ? (
              <Loading />
            ) : (
              <>
                {!isLandMapped ? (
                  <Col xs={12} lg={10} xl={9}>
                    <div className="d-flex justify-content-center">
                      <BackBtn to="/marketplace">
                        <BiChevronLeft size={24} /> Go back
                      </BackBtn>
                    </div>
                    <RentWrapper>
                      <h4 className="text-center">Land Not Found</h4>
                    </RentWrapper>
                  </Col>
                ) : (
                  <>
                    {isLoading ? (
                      <Loading />
                    ) : (
                      <Col xs={12} lg={10} xl={9}>
                        <div className="d-flex justify-content-center">
                          <BackBtn to="/marketplace">
                            <BiChevronLeft size={24} /> Go back
                          </BackBtn>
                        </div>
                        <RentWrapper>
                          <Row className="justify-content-between">
                            <Col xs={12} md={5} lg={5}>
                              <LandImage
                                className={buyItem?.locked || buyItem?.banned ? 'image-banned' : ''}
                                src={imagesDir(`./lands/${buyItem?.land}.png`).default}
                                alt={buyItem?.land}
                              />
                            </Col>
                            <Col xs={12} md={7} lg={6}>
                              <RentID theme={buyItem?.land}>{`#${buyItem?.tokenId}`}</RentID>
                              <RentTitle theme={buyItem?.land}>{buyItem?.land}</RentTitle>
                              <RentOwner
                                href={`${config.explorerURL[networkId]}/address/${buyItem?.seller}`}
                                target="_blank"
                                rel="nofollow"
                                style={{ display: 'inline-block', marginBottom: '8px' }}
                              >
                                Farmer:{' '}
                                {`${buyItem?.seller?.substring(0, 6)}...${buyItem?.seller?.substring(
                                  buyItem.seller?.length - 4,
                                )}`}
                              </RentOwner>
                              <RentStats>
                                <ul>
                                  <li>
                                    Area Size: <span>{buyItem?.areaSize}</span>
                                  </li>
                                  <li>
                                    Fish Ponds: <span>{buyItem?.maxFishPonds}</span>
                                  </li>
                                  <li>
                                    Plots: <span>{buyItem?.maxPlots}</span>
                                  </li>
                                  <li>
                                    Animal Barns: <span>{buyItem?.maxAnimalBarns}</span>
                                  </li>
                                </ul>
                              </RentStats>
                              <RentStats>
                                <ul>
                                  <li>
                                    Price:{' '}
                                    <div>
                                      <span>
                                        {priceNFT(buyItem?.price)}{' '}
                                        {tokenPaymentFn(buyItem?.paymentToken?.toLowerCase())}
                                      </span>
                                      {buyItem?.paymentToken?.toLowerCase() === config.payments[networkId].HPL && (
                                        //@ts-ignore
                                        <LandPriceExchange>
                                          (~ ${Number(priceNFT(buyItem?.paymentAmount) * hplPrice).toFixed(1)})
                                        </LandPriceExchange>
                                      )}
                                      {buyItem?.paymentToken?.toLowerCase() === config.payments[networkId].HPW && (
                                        //@ts-ignore
                                        <LandPriceExchange>
                                          (~ ${Number(priceNFT(buyItem?.paymentAmount) * hpwPrice).toFixed(1)})
                                        </LandPriceExchange>
                                      )}
                                      {buyItem?.paymentToken?.toLowerCase() === config.payments[networkId].BNB && (
                                        //@ts-ignore
                                        <LandPriceExchange>
                                          (~ ${Number(priceNFT(buyItem?.paymentAmount) * bnbPrice).toFixed(1)})
                                        </LandPriceExchange>
                                      )}
                                    </div>
                                  </li>
                                  <li>
                                    Plot Info:{' '}
                                    <span>
                                      <span
                                        style={{ cursor: 'pointer', textDecoration: 'underline', opacity: '0.6' }}
                                        onClick={() => setShowPlotInfoModal(true)}
                                      >
                                        see more
                                      </span>
                                      <PlotInfo
                                        plotEU={plotEU}
                                        plotASIA={plotASIA}
                                        show={showPlotInfoModal}
                                        onHide={() => setShowPlotInfoModal(false)}
                                      />
                                    </span>
                                  </li>
                                </ul>
                              </RentStats>
                              <div>
                                {buyItem?.paymentToken?.toLowerCase() === config.payments[networkId].BUSD && (
                                  <ButtonFull>
                                    {approvalBUSD !== ApprovalState.APPROVED ? (
                                      <Button
                                        color="primary"
                                        disabled={isApprovingBUSD}
                                        loading={isApprovingBUSD}
                                        onClick={onApproveBUSD}
                                      >
                                        Approve BUSD
                                      </Button>
                                    ) : (
                                      <Button
                                        color="primary"
                                        disabled={isRenting}
                                        loading={isRenting}
                                        onClick={onBuyNFT}
                                      >
                                        Buy Now
                                      </Button>
                                    )}
                                  </ButtonFull>
                                )}
                                {buyItem?.paymentToken?.toLowerCase() === config.payments[networkId].HPL && (
                                  <ButtonFull>
                                    {approvalHPL !== ApprovalState.APPROVED ? (
                                      <Button
                                        color="primary"
                                        disabled={isApprovingHPL}
                                        loading={isApprovingHPL}
                                        onClick={onApproveHPL}
                                      >
                                        Approve HPL
                                      </Button>
                                    ) : (
                                      <Button
                                        color="primary"
                                        disabled={isRenting}
                                        loading={isRenting}
                                        onClick={onBuyNFT}
                                      >
                                        Buy Now
                                      </Button>
                                    )}
                                  </ButtonFull>
                                )}
                                {buyItem?.paymentToken?.toLowerCase() === config.payments[networkId].HPW && (
                                  <ButtonFull>
                                    {approvalHPW !== ApprovalState.APPROVED ? (
                                      <Button
                                        color="primary"
                                        disabled={isApprovingHPW}
                                        loading={isApprovingHPW}
                                        onClick={onApproveHPW}
                                      >
                                        Approve HPW
                                      </Button>
                                    ) : (
                                      <Button
                                        color="primary"
                                        disabled={isRenting}
                                        loading={isRenting}
                                        onClick={onBuyNFT}
                                      >
                                        Buy Now
                                      </Button>
                                    )}
                                  </ButtonFull>
                                )}
                                {buyItem?.paymentToken?.toLowerCase() === config.payments[networkId].BNB && (
                                  <ButtonFull>
                                    <Button color="primary" disabled={isRenting} loading={isRenting} onClick={onBuyNFT}>
                                      Buy Now
                                    </Button>
                                  </ButtonFull>
                                )}
                              </div>
                            </Col>
                          </Row>
                        </RentWrapper>
                      </Col>
                    )}
                  </>
                )}
              </>
            )}
          </Row>
        </Container>
      </Main>
    </>
  )
}

export default BuySingle
