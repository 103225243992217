import { useCallback } from 'react'
import axios from 'axios'
import { useActiveWeb3React } from './useWeb3'
import NFT from '../types/NFT'
import config from '../config/config.json'

export const useMarketplaceApi = (): (() => Promise<any>) => {
  const { chainId } = useActiveWeb3React()
  const networkId = chainId ?? Number(process.env.REACT_APP_CHAIN_ID)
  const apiURL = config.api[networkId]

  return useCallback(async (): Promise<any> => {
    let lands = [] as NFT[]
    let items = [] as NFT[]

    try {
      const response = await axios.get(`${apiURL}/markets`)

      if (response.status === 200 && response.data) {
        const { markets } = response.data
        const _lands = markets.filter(token => token.tokenType === 'land')
        const _items = markets.filter(token => token.tokenType === 'item')

        lands = _lands.map(l => {
          return {
            ...l,
          }
        })

        items = _items.map(i => {
          return {
            ...i,
          }
        })
      }
    } catch (error: any) {
      console.error(error)
      return {}
    }
    return { lands, items }
  }, [])
}
