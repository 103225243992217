import Modal from 'components/Modal'
import CurrentRoundTicketsInner from './CurrentRoundTicketsInner'
import PreviousRoundTicketsInner from './PreviousRoundTicketsInner'

interface IViewTicketsModal {
  roundId: number
  show: boolean
  onHide: () => void
}

function ViewTicketsModal(props: IViewTicketsModal): JSX.Element {
  const { show, roundId, onHide } = props
  const isPreviousRound = true

  return (
    <Modal show={show} onHide={onHide} title={`Round ${roundId}`}>
      {isPreviousRound ? <PreviousRoundTicketsInner roundId={roundId} /> : <CurrentRoundTicketsInner />}
    </Modal>
  )
}

export default ViewTicketsModal
