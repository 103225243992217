import { useState } from 'react'
import { Container, Row } from 'react-bootstrap'
import Main from '../../components/Main'
import SectionTitle from '../../components/SectionTitle'
import Button from '../../components/Button'
import StakeRow from './components/StakeRow'
import StakeTitle from '../../assets/images/stake.png'
import { useActiveWeb3React } from '../../hooks'
import { AccountCol } from '../Dashboard/Styled'
import ConnectModal from '../../components/AccountButton/ConnectModal'

function Stake(): JSX.Element {
  const [showConnectModal, setShowConnectModal] = useState(false)
  const { account } = useActiveWeb3React()
  return (
    <Main>
      <Container>
        <SectionTitle subTitle="Farming - Staking" bgImage={StakeTitle} />
        {account ? (
          <StakeRow />
        ) : (
          <Row className="justify-content-center">
            <AccountCol xs={11} md={8} className="text-center">
              <h5 className="mb-3">Looks like your wallet is not connected.</h5>
              <p className="mb-5">Connect your wallet to see available core pools</p>
              <Button onClick={() => setShowConnectModal(true)}>Connect Wallet</Button>
              <ConnectModal show={showConnectModal} onHide={() => setShowConnectModal(false)} />
            </AccountCol>
          </Row>
        )}
      </Container>
    </Main>
  )
}

export default Stake
