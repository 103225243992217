import styled, { css } from 'styled-components/macro'
import { InputGroup, Form, FormControl } from 'react-bootstrap'

export const LandImage = styled.img`
  width: 100%;
`
export const ButtonFull = styled.div`
  a,
  button {
    width: 100%;
    height: auto;
  }
  button + button {
    margin-top: 0.5rem;
  }
`
export const ButtonWrap = styled.div`
  margin-top: 30px;
  margin-bottom: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  button {
    min-width: 48%;
  }
`
export const Input = styled(FormControl)`
  box-shadow: none !important;
  border-radius: 4px;
  border: ${props => props.borderwidth || '1px'} solid #555;
  background-color: transparent !important;
  color: #fff !important;
  font-size: 20px;
  padding: ${props => props.paddinginput || '.375rem .75rem'};

  &:focus,
  &:active {
    border-color: #fff;
  }

  &:placeholder-shown + p {
    display: none;
  }
`
export const InvalidText = styled.p`
  margin-top: 1rem;
  font-size: 12px;
  font-weight: 400;
`
export const FormSelect = styled(Form.Select)`
  max-width: 22%;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23aaa' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
  color: #888;
  box-shadow: none !important;
  border-radius: 4px !important;
  border: 1px solid #555;
  font-family: sans-serif;
  padding: 0.375rem 0.75rem;
  &:focus,
  &:active {
    border-color: ${props => props.theme.color.orange[500]};
  }
  &:disabled {
    background: transparent;
    opacity: 0.5;
  }
`
export const DepositInputGroup = styled.div`
  display: flex;
  flex-flow: column nowrap;
  position: relative;
  border-radius: 8px;
  background: transparent;
  z-index: 1;
  border: 1px solid #555;
`
export const DepositInputInfo = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  font-size: 0.75rem;
  line-height: 1rem;
  padding: 0.75rem 1rem 0;
`
export const StyledInputGroup = styled(InputGroup)`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  padding: 0.75rem 0.75rem 0.75rem 1rem;
`
// List View
export const ListViewNFT = styled.div<{ layout: string }>`
  position: relative;
  display: flex;
  flex-direction: column;
  flex-flow: column nowrap;
  align-items: center;
  margin-bottom: 30px;
  padding: 20px 20px 30px;
  border-radius: 15px;
  color: #fff;
  background: linear-gradient(
    180deg,
    rgba(125, 60, 0, 0.15) 3.12%,
    rgba(73, 22, 0, 0.8) 57.81%,
    rgba(0, 0, 0, 0.9) 100%
  );
  border: 1px solid #beb8b4;
  box-sizing: border-box;
  box-shadow: 0 6px 0 #471712, inset 0 4px 0 rgba(255, 220, 95, 0.7);
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: 'Rubik', sans-serif;
  }
`
export const ListViewImg = styled.div<{ layout: string }>`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 155px;
  min-height: 195px;
  border-radius: 10px;
  @media (min-width: 1200px) {
    min-width: 210px;
  }
  & > img {
    //height: 280px;
    width: auto;
  }
  ${props =>
    props.layout === 'grid' &&
    `
    width: 100%;
    min-height: 280px;
    margin-bottom: 15px;
    > img {
      max-width: 240px;
      max-height: 274px;
    }
  `}
`
export const TagStyle = css`
  width: 96px;
  height: 26px;
  padding: 0 10px;
  background: #d2cec3;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  line-height: 26px;
  border-radius: 0 5px 5px 0;
  text-align: center;
`

export const Ribbon = styled.span`
  display: inline-block;
  ${TagStyle};
  width: 115px;
  border-radius: 5px 0 0 5px;
  background-color: #ffc11b;
  color: #982f34;
`

export const RightRibbonContainer = styled.div`
  position: absolute;
  right: 0;
  top: 30px;
  width: 115px;
  height: 120px;
`

export const RibbonRent = styled.span`
  display: inline-block;
  ${TagStyle};
  width: 115px;
  border-radius: 5px 0 0 5px;
  background-color: #644fc9;
  color: #ffffff;
`

export const RibbonDanger = styled.span`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: inline-block;
  width: 250px;
  padding: 10px;
  border-radius: 5px;
  background: #982f34;
  color: #fff;
  text-align: center;
`

export const RibbonLock = styled.span`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: inline-block;
  width: 250px;
  padding: 10px;
  border-radius: 5px;
  background: #e8cd0c;
  color: #fff;
  text-align: center;
`

export const ListViewContent = styled.div<{ layout: string }>`
  padding: 0;
  ${props => props.layout === 'list' && 'padding: 0 0 0 20px;'}
`
export const ListViewDescription = styled.ul<{ layout: string }>`
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
  list-style: none;
  margin-bottom: 0.75rem;
  > li:nth-child(odd) {
    flex: 0 0 45%;
  }
  > li:nth-child(even) {
    flex: 0 0 55%;
  }
  ${props =>
    props.layout === 'list' &&
    `
    > li:nth-child(odd),
    > li:nth-child(even) {
      flex: 0 0 80%;
    }
    @media (min-width: 1200px) {
      > li:nth-child(odd) {
        flex: 0 0 40%;
      }
      > li:nth-child(even) {
        flex: 0 0 60%;
      }
    }
    `}
`
export const ListViewDescriptionItem = styled.li`
  display: flex;
  align-items: center;
  width: auto;
`
export const LandID = styled.span`
  position: absolute;
  top: 15px;
  left: -20px;
  display: block;
  ${TagStyle}
  ${props => props.theme === 'valley' && 'background: #61ff69;'}
  ${props => props.theme === 'woodland' && 'background: #e8cd0c;'}
  ${props => props.theme === 'meadow' && 'background: #cd2ae8;'}
  ${props => props.theme === 'euphoria' && 'background: #ff6961;'}
`
export const LandName = styled.h4`
  color: #d2cec3;
  font-size: 22px;
  font-weight: 700;
  text-transform: capitalize;
  ${props => props.theme === 'valley' && 'color: #61ff69;'}
  ${props => props.theme === 'woodland' && 'color: #e8cd0c;'}
  ${props => props.theme === 'meadow' && 'color: #cd2ae8;'}
  ${props => props.theme === 'euphoria' && 'color: #ff6961;'}
`
export const ListViewFooter = styled.div<{ layout: string }>`
  display: flex;
  flex-direction: column;
  flex-flow: column nowrap;
  justify-content: space-between;
  button {
    width: 100%;
  }
`
export const LandPriceWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-flow: row nowrap;
`
export const LandPrice = styled.p`
  margin-bottom: 0;
  font-size: 18px;
  line-height: 1.1;
  font-weight: 700;
  color: #fff;
  text-align: left;
`
export const LandPriceExchange = styled.span`
  margin-left: 8px;
  font-size: 16px;
  line-height: 1.1;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.7);
  text-align: right;
`
export const LandOwner = styled.a`
  display: inline-block;
  margin-bottom: 10px;
  font-size: 14px;
  color: #ffc11b;
  &:hover {
    text-decoration: underline;
  }
`
export const AttributeTitle = styled.span`
  font-size: 15px;
  color: #fff;
  span {
    font-weight: 500;
  }
`

export const TimeForRent = styled.span`
  font-size: 16px;
  line-height: 1.1;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.7);
  text-align: right;
`
