import styled from 'styled-components/macro'
import { Form } from 'react-bootstrap'
import { Link } from 'react-router-dom'

export const ToolbarMarket = styled.div`
  display: flex;
  flex-direction: row;
  flex-flow: row nowrap;
  justify-content: space-between;
  margin-bottom: 20px;
  border-bottom: 1px solid #fff;
`
export const ResultNFT = styled.span`
  background: #fff;
  padding: 8px 20px;
  border-radius: 10px 10px 0 0;
  color: #982f34;
  font-size: 16px;
  font-weight: 600;
`
export const ListGridToggle = styled.div`
  display: flex;
  align-items: end;
  & .grid-list-toggle {
    display: flex;
    align-items: center;
    padding: 5px 25px;
    background: #982f34;
    color: #fff;
    font-size: 16px;
    border: none;
    transition: all 0.2s ease;
    &.active {
      padding: 7px 25px;
      background: #fff;
      font-weight: 600;
      color: #982f34;
      box-shadow: 0 0 25px -2px rgb(255 255 255 / 75%);
      -webkit-box-shadow: 0 0 25px -2px rgb(255 255 255 / 75%);
      -moz-box-shadow: 0 0 25px -2px rgba(255 255 255 / 75%);
    }
    &:first-child {
      border-radius: 10px 0 0 0;
    }
    &:last-child {
      border-radius: 0 10px 0 0;
    }
    svg {
      margin-right: 5px;
      font-size: 12px;
    }
  }
`

export const FilterField = styled.div`
  margin-bottom: 35px;
`

export const FilterInput = styled.input`
  background: rgba(255, 255, 255, 0.2);
  border-radius: 4px;
  border: 1px solid #fff;
  padding: 0 15px;
  width: 100%;
  height: 40px;
  font-size: 16px;
  font-weight: 500;
  color: #fff;
  &:focus,
  &:active {
    outline: none;
    box-shadow: none;
  }
`

export const FilterReset = styled.span`
  background-color: transparent;
  border: 1px solid #fff;
  padding: 0.5rem 1rem;
  display: inline-block;
  transition: all 0.5s ease;
  text-align: center;
  text-transform: uppercase;
  line-height: 1rem;
  border-radius: 4px;
  cursor: pointer;
  font-size: 13px;
  font-weight: 600;
  color: #fff;

  &:hover {
    background-color: #fff;
    color: #982f34;
  }
`

export const FilterSelect = styled(Form.Select)`
  background-color: rgba(255, 255, 255, 0.2);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23ffffff' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
  border: 1px solid #fff;
  padding: 0 15px;
  width: 100%;
  height: 40px;
  font-size: 16px;
  font-weight: 500;
  color: #fff;
`

export const FilterColorWrapper = styled.div`
  position: relative;
`
export const FilterColor = styled.input`
  -webkit-appearance: none;
  appearance: none;

  &:checked + label {
    box-shadow: 0 0 15px 3px #ee8819;

    &::before {
      content: url(data:image/svg+xml;base64,PHN2ZyBzdHJva2U9ImN1cnJlbnRDb2xvciIgZmlsbD0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIwIiB2aWV3Qm94PSIwIDAgMjQgMjQiIGhlaWdodD0iMWVtIiB3aWR0aD0iMWVtIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxwYXRoIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIgc3Ryb2tlLXdpZHRoPSIyIiBkPSJNOSAxMmwyIDIgNC00bTYgMmE5IDkgMCAxMS0xOCAwIDkgOSAwIDAxMTggMHoiPjwvcGF0aD48L3N2Zz4=);
      width: 35px;
      height: 35px;
      line-height: 40px;
      display: block;
      text-align: center;
    }
  }
`

export const BackBtn = styled(Link)`
  display: flex;
  align-items: center;
  color: #fff;
  &:hover,
  &:active {
    color: #fff;
  }
  svg {
    margin-right: 20px;
  }
`

export const RentWrapper = styled.div`
  margin-top: 20px;
  padding: 1.5rem 1rem;
  background-color: #110f22;
  border: 1px solid #000;
  border-radius: 10px;
  @media (min-width: 768px) {
    padding: 2rem 2rem 3rem;
  }
`

export const RentTitle = styled.h2`
  color: #d2cec3;
  font-size: 22px;
  font-weight: 700;
  text-transform: capitalize;
  ${props => props.theme === 'valley' && 'color: #61ff69;'}
  ${props => props.theme === 'woodland' && 'color: #e8cd0c;'}
  ${props => props.theme === 'meadow' && 'color: #cd2ae8;'}
  ${props => props.theme === 'euphoria' && 'color: #ff6961;'}
`

export const RentOwner = styled.a`
  display: inline-block;
  margin-bottom: 10px;
  font-size: 15px;
  color: #ffc11b;
  &:hover {
    text-decoration: underline;
  }
`

export const RentID = styled.span`
  font-size: 15px;
  font-weight: 500;
  line-height: 26px;
`

export const RentStats = styled.div`
  margin: 15px 0;
  padding: 10px 16px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 4px;
  font-size: 16px;
  ul {
    list-style: none;
  }
  li {
    display: flex;
    justify-content: space-between;
    padding: 8px 0;
    align-items: center;
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
    &:last-child {
      border-bottom: none;
    }
  }
`

export const PlotInfo = styled.ul`
  margin: 15px 0;
  padding: 10px 16px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 4px;
  font-size: 16px;
  list-style: none;
  li {
    display: flex;
    justify-content: space-between;
    padding: 8px 0;
    align-items: center;
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
    &:last-child {
      border-bottom: none;
    }
  }
`

export const PlotEmpty = styled.div`
  border-radius: 4px;
  margin-top: 15px;
  padding: 30px 50px;
  background: rgba(255, 255, 255, 0.1);
  p {
    margin-bottom: 0;
  }
`
