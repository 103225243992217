import { useEffect, useState } from 'react'
import { Container, Row } from 'react-bootstrap'
import Main from '../../components/Main'
import Button from '../../components/Button'
import ConnectModal from '../../components/AccountButton/ConnectModal'
import { Zoom, toast } from 'react-toastify'
import ToastMessage from '../../components/ToastMessage'
import { ButtonWrap, ConnectWalletCol, FaucetCol } from './Styled'
import { useActiveWeb3React, useFaucetContract } from '../../hooks'
import config from '../../config/config.json'
import SectionTitle from '../../components/SectionTitle'
import { WithdrawCountdown } from '../Stake/Styled'
import Countdown, { zeroPad } from 'react-countdown'

function Faucet(): JSX.Element {
  const [showConnectModal, setShowConnectModal] = useState(false)
  const [isDisableLand, setDisableLand] = useState(true)
  const [nextClaimToken, setNextClaimToken] = useState<any>(true)

  const [isGettingLand, setGettingLand] = useState(false)
  const [isGettingToken, setGettingToken] = useState(false)

  const { account, chainId } = useActiveWeb3React()
  const networkId = chainId ?? Number(process.env.REACT_APP_CHAIN_ID)

  const faucetContract = useFaucetContract(config.contracts[networkId].Faucet)

  const fetchAllowance = async () => {
    try {
      if (faucetContract) {
        const _claimedLand = await faucetContract.methods.claimedLand(account).call()
        const _claimedToken = await faucetContract.methods.nextClaimToken(account).call()
        setDisableLand(_claimedLand)
        setNextClaimToken(_claimedToken)
      }
    } catch (error: any) {
      console.error(error)
    }
  }

  useEffect(() => {
    fetchAllowance()
  }, [])

  const onGetLandFaucet = async () => {
    try {
      setGettingLand(true)

      if (faucetContract && account) {
        const receipt = await faucetContract.methods.faucetLand().send({ from: account })

        if (receipt) {
          toast.success(<ToastMessage color="success" bodyText="You have just received HPL Land on testnet" />, {
            toastId: 'onGetLandFaucet',
            position: 'bottom-right',
            autoClose: 5000,
            hideProgressBar: true,
            transition: Zoom,
          })
        }
      }
    } catch (error: any) {
      // we only care if the error is something _other_ than the user rejected the tx
      if (error?.code !== 4001) {
        toast.error(<ToastMessage color="error" bodyText="Could not receive HPL Land on testnet." />, {
          toastId: 'onGetLandFaucet',
          position: 'bottom-right',
          autoClose: 5000,
          hideProgressBar: true,
          transition: Zoom,
        })
      }
      console.error(error)
    } finally {
      setGettingLand(false)
    }
  }

  const onGetTokenFaucet = async () => {
    try {
      setGettingToken(true)

      if (faucetContract && account) {
        const receipt = await faucetContract.methods.faucetToken().send({ from: account })

        if (receipt) {
          toast.success(<ToastMessage color="success" bodyText="You have just received HPL & HPW on testnet" />, {
            toastId: 'onGetLandFaucet',
            position: 'bottom-right',
            autoClose: 5000,
            hideProgressBar: true,
            transition: Zoom,
          })
        }
      }
    } catch (error: any) {
      // we only care if the error is something _other_ than the user rejected the tx
      if (error?.code !== 4001) {
        toast.error(<ToastMessage color="error" bodyText="Could not receive HPL & HPW on testnet." />, {
          toastId: 'onGetLandFaucet',
          position: 'bottom-right',
          autoClose: 5000,
          hideProgressBar: true,
          transition: Zoom,
        })
      }
      console.error(error)
    } finally {
      setGettingToken(false)
    }
  }

  return (
    <>
      <Main>
        <Container>
          <Row className="mt-5 justify-content-center">
            <FaucetCol xs={11} lg={6}>
              <SectionTitle subTitle="Dashboard">Claim Your Testing Pack</SectionTitle>
              {account ? (
                <>
                  <p className="text-center">
                    We will be given a testing pack that is available only in HappyLand testnet version.
                  </p>
                  {isDisableLand && (
                    <p className="text-center" style={{ fontSize: '16px', fontWeight: '500' }}>
                      Please check your inventory, your Land is already there.
                    </p>
                  )}
                  <ButtonWrap>
                    {!isDisableLand && (
                      <Button
                        color="primary"
                        disabled={isGettingLand}
                        loading={isGettingLand}
                        onClick={onGetLandFaucet}
                      >
                        Claim Land
                      </Button>
                    )}
                  </ButtonWrap>
                  <Countdown
                    date={nextClaimToken * 1000}
                    zeroPadTime={2}
                    renderer={props2 =>
                      props2.completed ? (
                        <ButtonWrap>
                          <Button
                            color="primary"
                            disabled={isGettingToken}
                            loading={isGettingToken}
                            onClick={onGetTokenFaucet}
                          >
                            Claim HPL & HPW
                          </Button>
                        </ButtonWrap>
                      ) : (
                        <p className="text-center">
                          <WithdrawCountdown>
                            {zeroPad(props2.hours)}h:{zeroPad(props2.minutes)}m:{zeroPad(props2.seconds)}s
                          </WithdrawCountdown>
                        </p>
                      )
                    }
                  />
                </>
              ) : (
                <ConnectWalletCol>
                  <Button onClick={() => setShowConnectModal(true)}>Unlock Wallet</Button>
                  <ConnectModal show={showConnectModal} onHide={() => setShowConnectModal(false)} />
                </ConnectWalletCol>
              )}
            </FaucetCol>
          </Row>
        </Container>
      </Main>
    </>
  )
}

export default Faucet
