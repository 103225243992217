import { useCallback, useEffect, useRef, useState } from 'react'
import { Zoom, toast } from 'react-toastify'
import ToastMessage from '../../../components/ToastMessage'
import RangeSlider from 'react-bootstrap-range-slider'
import Button from '../../../components/Button'
import { BalanceText, EstApy, Icon, Input, InputGroupStyled, LockDurationRange, LpIcons, StakeButtons } from '../Styled'
import { useTokenBalance, useActiveWeb3React, useMasterChefContract, useHPLPerYear, usePoolInfo } from '../../../hooks'
import { formatNumber, fromWei, toWei } from '../../../utils'
import config from '../../../config/config.json'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { BsQuestionCircle } from 'react-icons/bs'

interface IStakeTabProps {
  children?: any
  poolId: number
  icon?: string
  icon2?: string
  approval: boolean
  approveLoading: boolean
  onRefresh: () => void
  onApprove: () => void
}

function StakeTab(props: IStakeTabProps): JSX.Element {
  const { poolId, icon, icon2, approval, approveLoading, onRefresh, onApprove } = props
  const [isLoading, setLoading] = useState(false)
  const [amount, setAmount] = useState(0)
  const [strAmount, setStrAmount] = useState('0')
  const [tokenBalance, setTokenBalance] = useState(0)
  const [lockedDuration, setLockedDuration] = useState<any>(2)
  const [apy, setAPY] = useState<any>(0)

  const { account, chainId } = useActiveWeb3React()
  const networkId = chainId ?? Number(process.env.REACT_APP_CHAIN_ID)
  const pools = config.pools[networkId]
  // @ts-ignore
  const pool = pools.find(p => p.id === poolId)

  const tokenBalanceCallback = useTokenBalance(pool?.lpAddress, account)
  const masterChefContract = useMasterChefContract(config.contracts[networkId].MasterChef)
  const hplPerYearCallback = useHPLPerYear(pool.id)
  const poolInfoCallback = usePoolInfo(pool.id)

  const ref = useRef()

  const fetchData = async () => {
    const _tokenBalance = await tokenBalanceCallback()
    setTokenBalance(_tokenBalance)
  }

  useEffect(() => {
    fetchData()

    if (ref.current) {
      // @ts-ignore
      ref.current.focus()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tokenBalanceCallback])

  const fetchAPY = async () => {
    try {
      const _hplPerYear = await hplPerYearCallback()
      const poolInfo = await poolInfoCallback()
      const { totalWeight } = poolInfo
      if (masterChefContract) {
        const userInfoCallback = await masterChefContract.methods.getUserInfo(pool.id, account).call()
        const { stakeWeight, stakeAmount } = userInfoCallback

        const _baseAPY = ((_hplPerYear * 1.02) / Number(fromWei(totalWeight))) * 100 * 1e6

        const _apy1 =
          (Number(strAmount) * 1e18 * Number(1 + lockedDuration * 0.02) + Number(stakeWeight) / 1e6) *
          _hplPerYear *
          1e18 *
          100

        const _apy2 =
          (Number(totalWeight) / 1e6 + Number(strAmount) * 1e18 * Number(1 + lockedDuration * 0.02)) *
          (Number(stakeAmount) + Number(strAmount) * 1e18)

        const _apy = _apy1 / _apy2

        if (isNaN(_apy)) {
          setAPY(0)
          return
        }

        if (_apy) {
          setAPY(Number(_apy).toFixed(3))
        } else {
          setAPY(_baseAPY)
        }
      }
    } catch (error: any) {
      console.error(error)
    }
  }

  useEffect(() => {
    fetchAPY()

    // const interval = setInterval(() => {
    //   fetchAPY()
    // }, 1000 * 20)
    //
    // return () => clearInterval(interval)
  }, [lockedDuration, amount])

  const closeModal = () => {
    setAmount(0)
    setStrAmount('0')
  }

  const onRefreshBlance = () => {
    fetchData()
    fetchAPY()
  }

  const onConfirm = async () => {
    try {
      setLoading(true)

      let receipt: any = {}

      // if (masterChefContract) {
      //   receipt = await masterChefContract.methods
      //     .deposit(poolId, toWei(amount).toString(10), Number(600))
      //     .send({ from: account })
      // }
      if (masterChefContract) {
        receipt = await masterChefContract.methods
          .deposit(poolId, toWei(amount).toString(10), Number(lockedDuration * 604800))
          .send({ from: account })
      }

      if (receipt) {
        toast.success(
          <ToastMessage
            color="success"
            hideProgressBar
            bodyText="Stake successful"
            link={`${config.explorerURL[networkId]}/tx/${receipt.transactionHash}`}
            linkText="View Transaction"
          />,
          {
            toastId: 'onConfirm',
            position: 'bottom-right',
            autoClose: 5000,
            hideProgressBar: true,
            transition: Zoom,
          },
        )
        await onRefresh()
        closeModal()
        onRefreshBlance()
      }
    } catch (error: any) {
      // we only care if the error is something _other_ than the user rejected the tx
      if (error?.code !== 4001) {
        toast.error(<ToastMessage color="error" bodyText="Could not stake. Please try again." />, {
          toastId: 'onConfirm',
          position: 'bottom-right',
          autoClose: 5000,
          hideProgressBar: true,
          transition: Zoom,
        })
      }
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  const onAmountChange = (e: any) => {
    const { value } = e.currentTarget
    setStrAmount(value)
    setAmount(parseInt(value, 10) || 0)
  }

  const onBlur = useCallback(() => {
    setStrAmount(amount.toString())
  }, [amount])

  const onFocus = (e: any) => {
    e.target.select()
  }

  const onKeyUp = (e: any) => {
    if (e.keyCode === 13) {
      onConfirm()
    }
  }

  const onMax = () => {
    setAmount(tokenBalance)
    setStrAmount(tokenBalance.toString())
  }

  return (
    <>
      <div className="mb-4">
        <LockDurationRange>
          <span>
            Lock for: <span style={{ color: '#D75212', textTransform: 'uppercase' }}>{lockedDuration} weeks</span>
          </span>
          <span>
            Weight: <span style={{ color: '#fff' }}>{Number(1 + lockedDuration * 0.02).toFixed(2)}</span>
          </span>
        </LockDurationRange>
        <RangeSlider
          value={lockedDuration}
          min={2}
          max={poolId === 0 ? 52 : 3}
          onChange={e => setLockedDuration(e.target.value)}
          size="lg"
          tooltip="off"
        />
        <LockDurationRange>
          <span>2</span>
          <span>{poolId === 0 ? 52 : 3}</span>
        </LockDurationRange>
      </div>
      <BalanceText>
        <span>Amount</span>
        <span>
          Balance: <span style={{ color: '#fff' }}>{formatNumber(tokenBalance.toFixed(3))}</span>
        </span>
      </BalanceText>
      <InputGroupStyled>
        <Input
          ref={ref}
          type="number"
          placeholder="0"
          min="0"
          value={strAmount}
          onChange={onAmountChange}
          onBlur={onBlur}
          onFocus={onFocus}
          onKeyUp={onKeyUp}
        />
        <div>
          {icon2 ? (
            <LpIcons>
              <Icon src={icon2} alt="" />
              <Icon src={icon} alt="" />
            </LpIcons>
          ) : (
            <Icon src={icon} alt="" />
          )}
          <Button color="secondary" onClick={onMax}>
            Max
          </Button>
        </div>
      </InputGroupStyled>
      <EstApy>
        Est APY: <span>{Number(apy).toFixed(2)}%</span>{' '}
        <OverlayTrigger
          placement="right"
          overlay={
            <Tooltip id="unstake-action">
              This is your estimated APY. Actual APY might be different after stake.
            </Tooltip>
          }
        >
          <span style={{ fontSize: '14px', marginLeft: '5px' }}>
            <BsQuestionCircle />
          </span>
        </OverlayTrigger>
      </EstApy>
      <StakeButtons>
        {approval ? (
          <Button onClick={onConfirm} disabled={isLoading} loading={isLoading}>
            Stake and Lock
          </Button>
        ) : (
          <Button onClick={onApprove} disabled={approveLoading} loading={approveLoading}>
            Approve To Stake
          </Button>
        )}
      </StakeButtons>
    </>
  )
}

export default StakeTab
