import { useState } from 'react'
// import JSEncrypt from 'jsencrypt'
import Web3 from 'web3'
import axios, { AxiosError } from 'axios'
import { Form, FloatingLabel } from 'react-bootstrap'
import PasswordStrengthBar from 'react-password-strength-bar'
import { Zoom, toast } from 'react-toastify'
import ToastMessage from '../../components/ToastMessage'
import Button from '../../components/Button'
import { useActiveWeb3React } from '../../hooks'
import { FormInput, FormSelect } from './Styled'
import config from '../../config/config.json'

interface IRegisterFormProps {
  onMapped: () => void
  children?: string | JSX.Element | JSX.Element[]
}

function RegisterForm(props: IRegisterFormProps): JSX.Element {
  const { onMapped, children } = props
  const { account, chainId, library } = useActiveWeb3React()
  const web3 = new Web3(library)
  const networkId = chainId ?? Number(process.env.REACT_APP_CHAIN_ID)
  const apiURL = config.api[networkId]

  const [isRegistering, setRegistering] = useState(false)
  const [username, setUsername] = useState('')
  const [location, setLocation] = useState('asia')
  const [password, setPassword] = useState('')
  const [passwordScore, setPasswordScore] = useState(1)
  const [isUsernameValid, setUsernameValid] = useState(false)
  const [isPasswordMatched, setPasswordMatched] = useState(false)

  const onUsernameChange = e => {
    const { value } = e.currentTarget
    const usernameRegex = new RegExp(/^[a-z0-9]{6,20}$/g)
    setUsername(value)

    const match = usernameRegex.test(value)
    setUsernameValid(match)
  }

  const onLocationChange = e => {
    const { value } = e.currentTarget
    setLocation(value)
  }

  const onPasswordChange = e => {
    const { value } = e.currentTarget
    setPassword(value)
  }

  const onConfirmPasswordChange = e => {
    const { value } = e.currentTarget
    setPasswordMatched(value === password)
  }

  const onChangeScore = score => {
    setPasswordScore(score)
  }

  const onRegister = async () => {
    try {
      setRegistering(true)

      if (account) {
        // const encrypt = new JSEncrypt()
        // encrypt.setPublicKey(config.public_key)
        // const hashedPassword = encrypt.encrypt(password)
        const hashedPassword = password
        const timestamp = (Date.now() / 1000 + 60).toFixed(0)
        const expiry = (Date.now() / 100 + 120).toFixed(0)

        if (hashedPassword) {
          const hashedMsg = web3.utils.sha3(`${username.toLowerCase()}-${location}-${expiry}-${account.toLowerCase()}`)

          if (hashedMsg) {
            const signature = await web3.eth.personal.sign(hashedMsg, account, hashedPassword)

            const response = await axios.post(`${apiURL}/users/register`, {
              username,
              password: hashedPassword,
              player: account.toLowerCase(),
              timestamp,
              location,
              signature,
              expiry,
            })

            if (response.status === 200 && response.data) {
              onMapped()
              toast.success(<ToastMessage color="success" bodyText="Register success." />, {
                toastId: 'onRegister',
                position: 'bottom-right',
                autoClose: 5000,
                hideProgressBar: true,
                transition: Zoom,
              })
            }
          } else {
            toast.error(<ToastMessage color="error" bodyText="Could not register." />, {
              toastId: 'onRegister',
              position: 'bottom-right',
              autoClose: 5000,
              hideProgressBar: true,
              transition: Zoom,
            })
            throw new Error('Encrypt message failed')
          }
        } else {
          toast.error(<ToastMessage color="error" bodyText="Could not register." />, {
            toastId: 'onRegister',
            position: 'bottom-right',
            autoClose: 5000,
            hideProgressBar: true,
            transition: Zoom,
          })
          throw new Error('Encrypt password failed')
        }
      }
    } catch (error: any) {
      if (axios.isAxiosError(error)) {
        const _e = error as AxiosError
        const msg = _e.response?.data.errors
        toast.error(<ToastMessage color="error" bodyText={msg ? msg.toUpperCase() : 'Could not register.'} />, {
          toastId: 'onRegister',
          position: 'bottom-right',
          autoClose: 5000,
          hideProgressBar: true,
          transition: Zoom,
        })
      }
      console.error(error)
    } finally {
      setRegistering(false)
    }
  }

  return (
    <>
      <Form>
        <Form.Text className="d-block mt-2 mb-3 text-center" muted>
          <a
            style={{ color: '#898792', textDecoration: 'underline', fontSize: '16px' }}
            href="https://docs.happyland.finance/complete-guide-on-how-to-get-started-in-happyland"
            target="_blank"
          >
            How to register
          </a>
        </Form.Text>
        <Form.Group className="mb-3" controlId="formEmail">
          <FloatingLabel controlId="floatingFormEmail" label="Username" className="mb-3">
            <FormInput type="text" placeholder="Username" value={username} onChange={e => onUsernameChange(e)} />
            {!isUsernameValid && (
              <Form.Text className="d-block mt-2 text-danger">
                The username needs to be between 6 - 20 characters without special characters, spaces, and uppercase
                letters.
              </Form.Text>
            )}
          </FloatingLabel>
        </Form.Group>
        <Form.Group className="mb-3" controlId="location">
          <FloatingLabel controlId="floatingLocation" label="Server Location" className="mb-3">
            <FormSelect aria-label="Server Location" onChange={e => onLocationChange(e)}>
              <option value="asia">Asia</option>
              <option value="eu">Europe</option>
            </FormSelect>
          </FloatingLabel>
        </Form.Group>
        <Form.Group className="mb-3" controlId="formPassword">
          <FloatingLabel controlId="floatingFormPassword" label="Password">
            <FormInput
              type="password"
              className="mb-3"
              placeholder="Password"
              value={password}
              onChange={e => onPasswordChange(e)}
            />
            <PasswordStrengthBar
              password={password}
              minLength={6}
              shortScoreWord="The password field must be at least 6 characters"
              onChangeScore={onChangeScore}
            />
          </FloatingLabel>
        </Form.Group>
        <Form.Group className="mb-3" controlId="formPasswordConfirm">
          <FloatingLabel controlId="floatingFormPasswordConfirm" label="Confirm Password" className="mb-3">
            <FormInput type="password" placeholder="Confirm Password" onChange={e => onConfirmPasswordChange(e)} />
            {!isPasswordMatched && (
              <Form.Text className="d-block mt-2" muted>
                Passwords do not match
              </Form.Text>
            )}
          </FloatingLabel>
        </Form.Group>
        <div className="d-flex justify-content-center align-items-center mt-4">
          <Button
            disabled={isRegistering || !isUsernameValid || passwordScore < 2 || !isPasswordMatched}
            loading={isRegistering}
            onClick={onRegister}
          >
            Register
          </Button>
          {children}
        </div>
      </Form>
    </>
  )
}

export default RegisterForm
