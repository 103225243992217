import { useEffect, useState } from 'react'
import axios from 'axios'
import { Container, Row } from 'react-bootstrap'
import SectionTitle from '../../components/SectionTitle'
import Main from '../../components/Main'
import Button from '../../components/Button'
import RegisterForm from './RegisterForm'
import ChangePasswordForm from './ChangePasswordForm'
import ConnectModal from '../../components/AccountButton/ConnectModal'
import DashboardTitle from '../../assets/images/dashboard.png'
import config from '../../config/config.json'
import { useActiveWeb3React } from '../../hooks'
import { AccountCol } from './Styled'

function Dashboard(): JSX.Element {
  const [showConnectModal, setShowConnectModal] = useState(false)
  const [isMasterAccount, setMasterAccount] = useState(false)
  const [isScholarAccount, setScholarAccount] = useState(false)
  const [isAccountMapped, setAccountMapped] = useState(false)
  const [usernameMapped, setUsernameMapped] = useState('')
  const [locationMapped, setLocationMapped] = useState('')
  const [masterAccountAddress, setMasterAccountAddress] = useState('')
  const [scholarAccountName, setScholarAccountName] = useState('')

  const { account, chainId, deactivate } = useActiveWeb3React()
  const networkId = chainId ?? Number(process.env.REACT_APP_CHAIN_ID)
  const apiURL = config.api[networkId]

  const checkMapping = async () => {
    try {
      if (account) {
        const response = await axios.get(`${apiURL}/users/playerInfo/${account}`)
        if (response.status === 200 && response.data) {
          const { address, username, location, isMaster, subAccount } = response.data
          setAccountMapped(address === account.toLowerCase())
          setUsernameMapped(username)
          setLocationMapped(location)
          setMasterAccount(isMaster)
          if (subAccount) {
            setScholarAccount(true)
            setMasterAccountAddress(subAccount?.masterAddress)
            setScholarAccountName(subAccount?.username)
          }
        }
      }
    } catch (error: any) {
      console.error(error)
    }
  }

  useEffect(() => {
    checkMapping()
  }, [account, networkId])

  const logout = () => {
    deactivate()
  }

  const onMapped = () => {
    setAccountMapped(true)
  }

  // @ts-ignore
  return (
    <Main>
      <Container>
        {account ? (
          <Row className="mt-5 justify-content-center">
            <AccountCol xs={11} lg={6}>
              <SectionTitle subTitle="Dashboard" bgImage={DashboardTitle}>
                Game Account
              </SectionTitle>
              {!isAccountMapped ? (
                <>
                  {isScholarAccount ? (
                    <>
                      <p>Hi {scholarAccountName},</p>
                      <p>You are scholar of Guild Manager Wallet {masterAccountAddress}</p>
                    </>
                  ) : (
                    <RegisterForm onMapped={onMapped} />
                  )}
                </>
              ) : (
                <>
                  {isMasterAccount ? (
                    <p className="text-center">You can not play with Master Account.</p>
                  ) : (
                    <ChangePasswordForm username={usernameMapped} location={locationMapped}>
                      <Button color="secondary" onClick={logout}>
                        Logout
                      </Button>
                    </ChangePasswordForm>
                  )}
                </>
              )}
            </AccountCol>
          </Row>
        ) : (
          <Row className="justify-content-center">
            <SectionTitle subTitle="Dashboard" bgImage={DashboardTitle} />
            <AccountCol xs={11} md={9} className="text-center">
              <h5 className="mb-3">Looks like your wallet is not connected.</h5>
              <p className="mb-5">Please connect your wallet to continue</p>
              <Button onClick={() => setShowConnectModal(true)}>Connect Wallet</Button>
              <ConnectModal show={showConnectModal} onHide={() => setShowConnectModal(false)} />
            </AccountCol>
          </Row>
        )}
      </Container>
    </Main>
  )
}

export default Dashboard
